import { render, staticRenderFns } from "./SwitchField.vue?vue&type=template&id=1aad228e&scoped=true"
import script from "./SwitchField.vue?vue&type=script&lang=js"
export * from "./SwitchField.vue?vue&type=script&lang=js"
import style0 from "./SwitchField.vue?vue&type=style&index=0&id=1aad228e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aad228e",
  null
  
)

export default component.exports