<template>
    <div v-if="settings">
        <div class="project_header">
            <div class="blockname_wrapper">
                <p>Настройки сайта</p>
            </div>
            
            <div class="new_project_btn" @click="publish" v-if="!loading">Опубликовать</div>
            <div class="new_project_btn" @click="publish" v-if="loading">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.00016 13.6668C3.32683 13.6668 0.333496 10.6735 0.333496 7.00016C0.333496 5.7535 0.680163 4.54016 1.3335 3.48683C1.52683 3.1735 1.94016 3.08016 2.2535 3.2735C2.56683 3.46683 2.66016 3.88016 2.46683 4.19349C1.94683 5.03349 1.66683 6.00683 1.66683 7.00016C1.66683 9.94016 4.06016 12.3335 7.00016 12.3335C9.94016 12.3335 12.3335 9.94016 12.3335 7.00016C12.3335 4.06016 9.94016 1.66683 7.00016 1.66683C6.6335 1.66683 6.3335 1.36683 6.3335 1.00016C6.3335 0.633496 6.6335 0.333496 7.00016 0.333496C10.6735 0.333496 13.6668 3.32683 13.6668 7.00016C13.6668 10.6735 10.6735 13.6668 7.00016 13.6668Z" fill="black"/>
                </svg>


                Загрузка</div>
        </div>
        <div class="content">
            <SingleImageField :label="'Изображение'" :cards="settings.body.logo" @on-change="settings.body.logo=$event"></SingleImageField>
            <SingleImageField :label="'Иконка'" :cards="settings.body.favicon" @on-change="settings.body.favicon=$event"></SingleImageField>
            <TitleAndDescForSocialsField :label="'Социальные сети'" :cards="settings.body.socialNetworks" @on-change="settings.body.socialNetworks=$event"></TitleAndDescForSocialsField>
            <p>Телефоны</p>
            <div class="phones" v-for="(phone, index) in settings.body.phones" :key="index">
                <ShortTextField placeholder="Номер телефона" label="Номер телефона" name="label" :value="phone.phone" @on-change="phone.phone=$event"></ShortTextField>
                <div class="delete" @click="settings.body.phones.splice(index,1);">
                        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.666 4.6665C10.4892 4.6665 10.3196 4.73674 10.1946 4.86177C10.0696 4.98679 9.99935 5.15636 9.99935 5.33317V12.7938C9.98022 13.131 9.82866 13.4469 9.57768 13.6728C9.32669 13.8986 8.99662 14.0162 8.65935 13.9998H3.33935C3.00208 14.0162 2.672 13.8986 2.42102 13.6728C2.17003 13.4469 2.01847 13.131 1.99935 12.7938V5.33317C1.99935 5.15636 1.92911 4.98679 1.80409 4.86177C1.67906 4.73674 1.50949 4.6665 1.33268 4.6665C1.15587 4.6665 0.986302 4.73674 0.861278 4.86177C0.736254 4.98679 0.666016 5.15636 0.666016 5.33317V12.7938C0.685045 13.4847 0.977071 14.1398 1.47814 14.6157C1.9792 15.0917 2.64846 15.3497 3.33935 15.3332H8.65935C9.35023 15.3497 10.0195 15.0917 10.5206 14.6157C11.0216 14.1398 11.3137 13.4847 11.3327 12.7938V5.33317C11.3327 5.15636 11.2624 4.98679 11.1374 4.86177C11.0124 4.73674 10.8428 4.6665 10.666 4.6665Z" fill="black"/>
                            <path d="M11.3333 2.6665H8.66667V1.33317C8.66667 1.15636 8.59643 0.98679 8.4714 0.861766C8.34638 0.736742 8.17681 0.666504 8 0.666504H4C3.82319 0.666504 3.65362 0.736742 3.5286 0.861766C3.40357 0.98679 3.33333 1.15636 3.33333 1.33317V2.6665H0.666667C0.489856 2.6665 0.320286 2.73674 0.195262 2.86177C0.0702379 2.98679 0 3.15636 0 3.33317C0 3.50998 0.0702379 3.67955 0.195262 3.80457C0.320286 3.9296 0.489856 3.99984 0.666667 3.99984H11.3333C11.5101 3.99984 11.6797 3.9296 11.8047 3.80457C11.9298 3.67955 12 3.50998 12 3.33317C12 3.15636 11.9298 2.98679 11.8047 2.86177C11.6797 2.73674 11.5101 2.6665 11.3333 2.6665V2.6665ZM4.66667 2.6665V1.99984H7.33333V2.6665H4.66667Z" fill="black"/>
                            <path d="M5.33333 11.3333V6.66667C5.33333 6.48986 5.2631 6.32029 5.13807 6.19526C5.01305 6.07024 4.84348 6 4.66667 6C4.48986 6 4.32029 6.07024 4.19526 6.19526C4.07024 6.32029 4 6.48986 4 6.66667V11.3333C4 11.5101 4.07024 11.6797 4.19526 11.8047C4.32029 11.9298 4.48986 12 4.66667 12C4.84348 12 5.01305 11.9298 5.13807 11.8047C5.2631 11.6797 5.33333 11.5101 5.33333 11.3333Z" fill="black"/>
                            <path d="M7.99935 11.3333V6.66667C7.99935 6.48986 7.92911 6.32029 7.80409 6.19526C7.67906 6.07024 7.50949 6 7.33268 6C7.15587 6 6.9863 6.07024 6.86128 6.19526C6.73625 6.32029 6.66602 6.48986 6.66602 6.66667V11.3333C6.66602 11.5101 6.73625 11.6797 6.86128 11.8047C6.9863 11.9298 7.15587 12 7.33268 12C7.50949 12 7.67906 11.9298 7.80409 11.8047C7.92911 11.6797 7.99935 11.5101 7.99935 11.3333Z" fill="black"/>
                        </svg>
                    </div>
            </div>
            
            <div class="addone" @click="settings.body.phones.push({
                    'phone': ''
                });">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5938 8.375H9.625V5.40625C9.625 5.32031 9.55469 5.25 9.46875 5.25H8.53125C8.44531 5.25 8.375 5.32031 8.375 5.40625V8.375H5.40625C5.32031 8.375 5.25 8.44531 5.25 8.53125V9.46875C5.25 9.55469 5.32031 9.625 5.40625 9.625H8.375V12.5938C8.375 12.6797 8.44531 12.75 8.53125 12.75H9.46875C9.55469 12.75 9.625 12.6797 9.625 12.5938V9.625H12.5938C12.6797 9.625 12.75 9.55469 12.75 9.46875V8.53125C12.75 8.44531 12.6797 8.375 12.5938 8.375Z" fill="black"/>
                    <path d="M9 0.25C4.16797 0.25 0.25 4.16797 0.25 9C0.25 13.832 4.16797 17.75 9 17.75C13.832 17.75 17.75 13.832 17.75 9C17.75 4.16797 13.832 0.25 9 0.25ZM9 16.2656C4.98828 16.2656 1.73438 13.0117 1.73438 9C1.73438 4.98828 4.98828 1.73438 9 1.73438C13.0117 1.73438 16.2656 4.98828 16.2656 9C16.2656 13.0117 13.0117 16.2656 9 16.2656Z" fill="black"/>
                </svg>
                <p>Добавить телефон</p>
            </div>
            <p>Метатеги</p>
            <div class="metas">
                <ShortTextField placeholder="Название" label="Название" name="title" :value="settings.body.metatags.title" @on-change="settings.body.metatags.title=$event"></ShortTextField>
                <ShortTextField placeholder="Описание" label="Описание" name="desc" :value="settings.body.metatags.description" @on-change="settings.body.metatags.description=$event"></ShortTextField>
                <ShortTextField placeholder="Ключевые слова" label="Ключевые слова" name="keywords" :value="settings.body.metatags.keywords" @on-change="settings.body.metatags.keywords=$event"></ShortTextField>
                <TitleAndDescField :label="'Стандартные'" :cards="settings.body.metatags.defaults" @on-change="settings.body.metatags.defaults=$event"></TitleAndDescField>
                <TitleAndDescField :label="'OG'" :cards="settings.body.metatags.og" @on-change="settings.body.metatags.og=$event"></TitleAndDescField>
            </div>
        </div>
    </div>
</template>
<style scoped>  
.phones {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.delete {
    border-radius: 5px;
    border: 1px solid #DEDEDE;
    padding: 12px;
    justify-self: center;
    cursor: pointer;
    margin-bottom: 20px;
    margin-left: 20px;
}
.addone {
    display: flex;
    font-size: 20px;
    margin-left: auto;
    cursor: pointer;
}
.addone > svg {
    margin-right: 8px;
}
    .project_header {
        height: 70px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background-color: white;
        margin-bottom: 20px;
        border-radius: 10px;
        align-items: center;
    }
    .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
        min-height: calc(100vh - 97px - 108px);
        border-radius: 10px;
        padding: 30px 20px 28px 20px;
    }
    .content > p {
        font-size:  32px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .blockname_wrapper {
        display: flex;
        align-items: center;
    }

    .blockname_wrapper > div {
        font-size: 16px;
        color: #8A8A8A;
        padding: 10px 8px;
        border: 1px solid #8A8A8A;
        border-radius: 5px;
        margin-right: 20px;
    }
    .project_header   p {
        font-size: 32px;
    }
    .blockname_wrapper > p {
        margin-right: 20px;
    }
    .blockname_wrapper > svg {
        cursor: pointer;
    }
    .new_project_btn {
        padding: 12px 20px;
        border: 1px solid black;
        border-radius: 80px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .new_project_btn svg {
        margin-right: 10px;
    }
    .new_project_btn:hover {
        background-color: #F2F2F2 !important;
        color: #4C4C4C !important;
        border: 1px solid #4C4C4C;
    }
    .new_project_btn:active {
        background-color: #E5E5E5 !important;
        color: #1A1A1A !important;
        border: 1px solid #1A1A1A;
    }
    .content {
        display: flex;
        flex-wrap: wrap; 
    }
</style>
<script>

import SingleImageField from '../fields/SingleImageField.vue'
import ShortTextField from '../fields/ShortTextField.vue'
import TitleAndDescField from '../fields/TitleAndDescField.vue'
import TitleAndDescForSocialsField from '../fields/TitleAndDescForSocialsField.vue';
export default {
  name: 'SiteSettings',
  data: () => ({
    settings: undefined,
    loading: false
  }),
  methods: {
    getSettings: function(){
        fetch(`${process.env.VUE_APP_MYAPI}/settings/1`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+this.$cookies.get('token')
            }
        })
        .then(response=>{return response.json()})
        .then(data=>{this.settings = data;})
    },
    publish: function(){
        if(!this.$cookies.get('token')){
            this.$router.push({name:'LoginForm'})
        }
        if(this.loading){
            return false
        }
        this.loading = true
        fetch(`${process.env.VUE_APP_MYAPI}/settings/1`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer '+this.$cookies.get('token'),
            },
            body: JSON.stringify({
                "body":this.settings.body
            })
        })
        .then(response=>{
            if(!response.ok) {
                console.log(response)
                this.$toast.clear();
                this.$toast.error("Изменения не опубликованы")
            }
            else {
                this.$toast.clear();
                this.$toast.success("Изменения успешно опубликованы")
            }
            this.loading = false
            return response.json()
        })
    }
  },
  mounted () {
    this.getSettings()
    this.$parent.$emit('on-bread', 'SiteSettings', this.$route.params.project_id, this.$route.params.page_id, this.$route.params.block_id)
  },
  components: {
    SingleImageField,
    ShortTextField,
    TitleAndDescField,
    TitleAndDescForSocialsField,
}
}
</script>