<template>
    <div>
        <div class="project_header">
            <p>Новая страница</p>
            <div class="new_project_btn" @click="newPage">Создать страницу</div>
        </div>
        <div class="content">
            <ShortTextField placeholder="Название страницы" label="Название страницы" name="title" :value="title" @on-change="title=$event"></ShortTextField>
            <ShortTextField placeholder="Slug" label="Slug" name="slug" :value="slug" @on-change="slug=$event"></ShortTextField>
        </div>
    </div>
</template>
<style scoped>
    .project_header {
        height: 70px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background-color: white;
        margin-bottom: 20px;
        border-radius: 10px;
        align-items: center;
    }
    .project_header  > p {
        font-size: 32px;
    }
    .new_project_btn {
        padding: 12px 20px;
        border: 1px solid black;
        border-radius: 80px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .new_project_btn svg {
        margin-right: 10px;
    }
    .new_project_btn:hover {
        background-color: #F2F2F2 !important;
        color: #4C4C4C !important;
        border: 1px solid #4C4C4C;
    }
    .new_project_btn:active {
        background-color: #E5E5E5 !important;
        color: #1A1A1A !important;
        border: 1px solid #1A1A1A;
    }
    .content {
        display: flex;
        flex-direction: column;
        background-color: white;
        min-height: calc(100vh - 97px - 108px);
        border-radius: 10px;
        padding: 30px 20px 28px 20px;
    }

</style>
<script>
import ShortTextField from '../fields/ShortTextField.vue'
export default {
  name: 'NewPage',
  data: () => ({
    title: "",
    slug: "",
    order: 0,
    options: ['custom','progress'],
  }),
  methods: {
    newPage: function(){
        if(!this.$cookies.get('token')){
            this.$router.push({name:'LoginForm'})
        }
        if(this.title.trim()=="") {
            this.$toast.clear();
            this.$toast.error("Заполните Название страницы")
            return
        }
        let bodyByTitle = {
                "body": {
                    "name": this.title,
                    "slug": this.slug,
                    'parent_page': this.$route.params.sales_id?this.$route.params.sales_id:undefined
                },
                "type": this.$route.params.sales_id?'sales_tab':'sales',
                "blocks": [],
                "active": true,
                "order": this.order,
                
            }
        fetch(`${process.env.VUE_APP_MYAPI}/pages`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer '+this.$cookies.get('token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bodyByTitle)
        })
        .then(response=>{
            if(!response.ok){
                console.log(response)
                this.$toast.clear();
                this.$toast.error("Страница не создана")
            } 
            else {
                this.$toast.clear();
                this.$toast.success("Страница успешно создана")
            }
            return response.json()
        })
        .then(data=>{
            let new_page = data
            this.$router.push({name:'PageBlocksSales', params: {sales_id: new_page.id}})
            this.$parent.$emit('on-change', 'page', new_page)
        })
    },
    getOrder: async function(){
        let data
        if(!this.$route.params.sales_id){
            let response = await fetch(`${process.env.VUE_APP_MYAPI}/pages/?type=sales`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer '+this.$cookies.get('token'),
                }
            })
            let result = await response.json()
            data = result
        }
        else {
            let response = await fetch(`${process.env.VUE_APP_MYAPI}/pages/?type=sales_tab`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer '+this.$cookies.get('token'),
                }
            })
            let result = await response.json()
            result = result.filter(tab=>tab.body.parent_page==this.$route.params.sales_id)
            data = result
        }
        this.order = data.length+1
    }
  },
  components: {
    ShortTextField
},
  mounted(){
    this.getOrder()
    this.$parent.$emit('on-bread', 'NewPageSales', undefined, undefined, undefined)
  }
}
</script>