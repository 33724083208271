<template>
    <div class="inputblock">
        <p>{{label}}</p>
        <div class="map_container">
            <div id="map"></div>
            <div class="content_table" v-if="cardsArr">
                <draggable v-model="cardsArr" @start="drag=true" @end="drag=false" handle=".drag_svg" 
                @change="
                let tmp = [];
                for(let i=0;i<cardsArr.length;i++){
                    tmp.push(markers[cardsArr[i].id]);
                }
                markers = tmp;
                $emit('on-change', cardsArr)">
                    <div class="content_row" v-for="(card, index) in cardsArr" :key="index">
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="drag_svg">
                            <path d="M1.66667 4.00008C2.58714 4.00008 3.33333 3.25389 3.33333 2.33342C3.33333 1.41294 2.58714 0.666748 1.66667 0.666748C0.746192 0.666748 0 1.41294 0 2.33342C0 3.25389 0.746192 4.00008 1.66667 4.00008Z" fill="black"/>
                            <path d="M1.66667 10.6666C2.58714 10.6666 3.33333 9.92039 3.33333 8.99992C3.33333 8.07944 2.58714 7.33325 1.66667 7.33325C0.746192 7.33325 0 8.07944 0 8.99992C0 9.92039 0.746192 10.6666 1.66667 10.6666Z" fill="black"/>
                            <path d="M1.66667 17.3333C2.58714 17.3333 3.33333 16.5871 3.33333 15.6667C3.33333 14.7462 2.58714 14 1.66667 14C0.746192 14 0 14.7462 0 15.6667C0 16.5871 0.746192 17.3333 1.66667 17.3333Z" fill="black"/>
                            <path d="M8.33268 4.00008C9.25316 4.00008 9.99935 3.25389 9.99935 2.33342C9.99935 1.41294 9.25316 0.666748 8.33268 0.666748C7.41221 0.666748 6.66602 1.41294 6.66602 2.33342C6.66602 3.25389 7.41221 4.00008 8.33268 4.00008Z" fill="black"/>
                            <path d="M8.33268 10.6666C9.25316 10.6666 9.99935 9.92039 9.99935 8.99992C9.99935 8.07944 9.25316 7.33325 8.33268 7.33325C7.41221 7.33325 6.66602 8.07944 6.66602 8.99992C6.66602 9.92039 7.41221 10.6666 8.33268 10.6666Z" fill="black"/>
                            <path d="M8.33268 17.3333C9.25316 17.3333 9.99935 16.5871 9.99935 15.6667C9.99935 14.7462 9.25316 14 8.33268 14C7.41221 14 6.66602 14.7462 6.66602 15.6667C6.66602 16.5871 7.41221 17.3333 8.33268 17.3333Z" fill="black"/>
                        </svg>
                        <div class="title">
                            <label for="title">
                                <p>Название</p>
                            </label>
                            <input type="text" class="input" placeholder="Название" :value="cardsArr[index]['title']" @input="cardsArr[index]['title']=$event.target.value; $emit('on-change', cardsArr)"/>
                        </div>
                        <div class="desc">
                            <label for="desc">
                                <p>Описание</p>
                            </label>
                            <input type="text" class="input" placeholder="Текст" :value="cardsArr[index]['description']" @input="cardsArr[index]['description']=$event.target.value; $emit('on-change', cardsArr)"/>
                        </div>
                        <div class="delete" @click="cardsArr.splice(index,1); removeMarker(index); $emit('on-change', cardsArr);">
                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.666 4.6665C10.4892 4.6665 10.3196 4.73674 10.1946 4.86177C10.0696 4.98679 9.99935 5.15636 9.99935 5.33317V12.7938C9.98022 13.131 9.82866 13.4469 9.57768 13.6728C9.32669 13.8986 8.99662 14.0162 8.65935 13.9998H3.33935C3.00208 14.0162 2.672 13.8986 2.42102 13.6728C2.17003 13.4469 2.01847 13.131 1.99935 12.7938V5.33317C1.99935 5.15636 1.92911 4.98679 1.80409 4.86177C1.67906 4.73674 1.50949 4.6665 1.33268 4.6665C1.15587 4.6665 0.986302 4.73674 0.861278 4.86177C0.736254 4.98679 0.666016 5.15636 0.666016 5.33317V12.7938C0.685045 13.4847 0.977071 14.1398 1.47814 14.6157C1.9792 15.0917 2.64846 15.3497 3.33935 15.3332H8.65935C9.35023 15.3497 10.0195 15.0917 10.5206 14.6157C11.0216 14.1398 11.3137 13.4847 11.3327 12.7938V5.33317C11.3327 5.15636 11.2624 4.98679 11.1374 4.86177C11.0124 4.73674 10.8428 4.6665 10.666 4.6665Z" fill="black"/>
                                <path d="M11.3333 2.6665H8.66667V1.33317C8.66667 1.15636 8.59643 0.98679 8.4714 0.861766C8.34638 0.736742 8.17681 0.666504 8 0.666504H4C3.82319 0.666504 3.65362 0.736742 3.5286 0.861766C3.40357 0.98679 3.33333 1.15636 3.33333 1.33317V2.6665H0.666667C0.489856 2.6665 0.320286 2.73674 0.195262 2.86177C0.0702379 2.98679 0 3.15636 0 3.33317C0 3.50998 0.0702379 3.67955 0.195262 3.80457C0.320286 3.9296 0.489856 3.99984 0.666667 3.99984H11.3333C11.5101 3.99984 11.6797 3.9296 11.8047 3.80457C11.9298 3.67955 12 3.50998 12 3.33317C12 3.15636 11.9298 2.98679 11.8047 2.86177C11.6797 2.73674 11.5101 2.6665 11.3333 2.6665V2.6665ZM4.66667 2.6665V1.99984H7.33333V2.6665H4.66667Z" fill="black"/>
                                <path d="M5.33333 11.3333V6.66667C5.33333 6.48986 5.2631 6.32029 5.13807 6.19526C5.01305 6.07024 4.84348 6 4.66667 6C4.48986 6 4.32029 6.07024 4.19526 6.19526C4.07024 6.32029 4 6.48986 4 6.66667V11.3333C4 11.5101 4.07024 11.6797 4.19526 11.8047C4.32029 11.9298 4.48986 12 4.66667 12C4.84348 12 5.01305 11.9298 5.13807 11.8047C5.2631 11.6797 5.33333 11.5101 5.33333 11.3333Z" fill="black"/>
                                <path d="M7.99935 11.3333V6.66667C7.99935 6.48986 7.92911 6.32029 7.80409 6.19526C7.67906 6.07024 7.50949 6 7.33268 6C7.15587 6 6.9863 6.07024 6.86128 6.19526C6.73625 6.32029 6.66602 6.48986 6.66602 6.66667V11.3333C6.66602 11.5101 6.73625 11.6797 6.86128 11.8047C6.9863 11.9298 7.15587 12 7.33268 12C7.50949 12 7.67906 11.9298 7.80409 11.8047C7.92911 11.6797 7.99935 11.5101 7.99935 11.3333Z" fill="black"/>
                            </svg>
                        </div>
                    </div>
                </draggable>
            </div>
        </div>
        
    </div>
</template>
<style scoped>
    .drag_svg {
        cursor: grab;
    }
#map {
    flex: 1;
    height:533px;
    box-shadow: 0 0 8px 8px white inset !important;
    border-radius: 4px;
}
.content_table {
    flex: 1;
}
.map_container {
    display: flex;
    gap: 25px;
}
.addone {
    display: flex;
    font-size: 20px;
    margin-left: auto;
    cursor: pointer;
}
.addone > svg {
    margin-right: 8px;
}
.delete {
    border-radius: 5px;
    border: 1px solid #DEDEDE;
    padding: 12px;
    justify-self: center;
    cursor: pointer;
}
.delete  > svg {
    width: 16px;
    height: 16px;
}
.content_row > svg {
    margin-bottom: 13px;
    margin-right: 15px;
}
.photo  {
    display: flex;
    align-items: center;
}
label > p {
    font-size: 12px;
    margin-bottom: 4px;
}
.photo > label > svg {
    margin-right: 10px;
}
.photo, .title, .desc {
    flex: 1;
    margin-right: 10px;
    width: 100%;
}
input[type='text']{
    border: 1px solid #DEDEDE;
    padding: 12px 14px;
    width: 100%;
    font-size: 16px;
    color:black;
    border-radius: 4px;
}
#upload {
    display: none;
}
.content_table {
    display: flex;
    flex-direction: column;
    margin-bottom: 22px !important;
}
.content_row {
    display: flex;
    align-items: flex-end;
    justify-content:space-around;
    margin-bottom: 16px;
}
    .input::placeholder {
        color: #8A8A8A;
        font-size: 16px;
    }
    .inputblock {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .inputblock > p {
        margin-right: 20px;
        font-size: 20px;
        margin-bottom: 36px;
    }

</style>
<script>
import draggable from 'vuedraggable'
const mapboxgl = require('mapbox-gl');

export default { 
    data: () => ({
    cardsArr: [],
    markers: [],
    map: undefined
  }),
  model: {
    prop: "value",
    event: "on-change"
  },
    methods: {
        initCards: function(){
            if(this.cards.length>0) this.cardsArr = this.cards

        },
        getMarkers: function(){
            this.markers = []
            for(let i=0;i<this.cardsArr.length;i++){
                let marker = new mapboxgl.Marker()
                .setLngLat([this.cardsArr[i].latlng[1], this.cardsArr[i].latlng[0]])
                .addTo(this.map);
                this.markers.push(marker)
            }
        },
        addMarker: function(){
            this.markers.push(new mapboxgl.Marker()
                .setLngLat([this.cardsArr[this.cardsArr.length-1].latlng[1], this.cardsArr[this.cardsArr.length-1].latlng[0]])
                .addTo(this.map))
        },
        removeMarker: function(id){
            this.markers[id].remove()
            this.markers.splice(id,1)
        }
    },
    props: {
        label: {
            type: String || undefined,
        },
        cards: {
            type: (Array || undefined),
        },
    },
    mounted() {
        this.initCards()
        let app = this
        mapboxgl.accessToken = 'pk.eyJ1IjoiYS1lc3RhdGUiLCJhIjoiY2w3aGljMnFpMGVyNzNwb2F2aXp3OTBhaSJ9.O1G_TLcDCfNYqjDuJsUldg';
        this.map = new mapboxgl.Map({
            container: 'map', // container ID
            style: 'mapbox://styles/mapbox/streets-v11', // style URL
            center: [30.3141300,59.9386300], // starting position [lng, lat]
            zoom: 12, // starting zoom
            projection: 'globe' // display the map as a 3D globe
        });
        this.map.on('style.load', () => {
            this.map.setFog({});
                app.getMarkers()
                
                app.map.on('click', (e)=>{
                let latlng = e.lngLat
                app.cardsArr.push({
                    title: "",
                    description: "",
                    latlng: [latlng.lat, latlng.lng]
                })
                app.addMarker()
                
                app.$emit('on-change', app.cardsArr)
            })
        });
            //------------------ INIT MAP --------------------------
            
        
    },
      components: {
    draggable,
}
}
</script>