<template>
    <div>
        <div class="project_header">
            <p>Новый проект</p>
            <div class="new_project_btn" @click="newProject">Создать проект</div>
        </div>
        <div class="content">
            <ShortTextField placeholder="Название проекта" label="Название проекта" name="title" :value="title" @on-change="title=$event"></ShortTextField>
        </div>
    </div>
</template>
<style scoped>
    .project_header {
        height: 70px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background-color: white;
        margin-bottom: 20px;
        border-radius: 10px;
        align-items: center;
    }
    .project_header  > p {
        font-size: 32px;
    }
    .new_project_btn {
        padding: 12px 20px;
        border: 1px solid black;
        border-radius: 80px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .new_project_btn svg {
        margin-right: 10px;
    }
    .new_project_btn:hover {
        background-color: #F2F2F2 !important;
        color: #4C4C4C !important;
        border: 1px solid #4C4C4C;
    }
    .new_project_btn:active {
        background-color: #E5E5E5 !important;
        color: #1A1A1A !important;
        border: 1px solid #1A1A1A;
    }
    .content {
        display: flex;
        flex-direction: column;
        background-color: white;
        min-height: calc(100vh - 97px - 108px);
        border-radius: 10px;
        padding: 30px 20px 28px 20px;
    }

</style>
<script>
import ShortTextField from '../fields/ShortTextField.vue'
export default {
  name: 'ProjectList',
  data: () => ({
    title: ""
  }),
  methods: {
    newProject: function(){
        if(this.title.trim()==""){
            this.$toast.clear();
            this.$toast.error("Заполните Название проекта")
            return
        }
        fetch(`${process.env.VUE_APP_MYAPI}/projects`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer '+this.$cookies.get('token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "body": {
                    "name": this.title,
                    "description": "",
                    "latlng": [],
                    "inSale": "123",
                    "slug": "",
                    "status": "",
                    "color": "#000000FF",
                    "background": {
                        "url": "",
                        "alt": ""
                    },
                    "address": "",
                    "deadline": "",
                    "crm_id": "",
                    "image": {
                        "url": "",
                        "alt": "",
                        'width': ""
                    },
                    "socialNetworks": [
                        {
                            "name": "",
                            "url": ""
                        },
                        {
                            "name": "",
                            "url": ""
                        },
                    ],
                    "metatags": {
                            "title": "",
                            "description": "",
                            "keywords": "",
                            "defaults": [
                                {
                                    "property": "",
                                    "content": ""
                                },
                                {
                                    "property": "",
                                    "content": ""
                                }
                                ],
                                "og": [
                                {
                                    "property": "",
                                    "content": ""
                                },
                                {
                                    "property": "",
                                    "content": ""
                                }
                                ]
                    }
                },
                "pages": []
            })
        })
        .then(response=>{
            if(response.ok){
                this.$toast.clear();
                this.$toast.success("Проект успешно создан")
                this.$router.push({name:'ProjectList'})
                this.$parent.$emit('on-change', 'project', this.newProject)
            }
            else {
                this.$toast.clear();
                this.$toast.error("Проект не создан")
            }
            return response.json()
        })
    }
  },
  components: {
    ShortTextField
  },
  mounted(){
    this.$parent.$emit('on-bread', 'NewProject', this.$route.params.project_id, this.$route.params.page_id, this.$route.params.block_id)
  }
}
</script>