<template>
    <div>
        <div class="project_header">
            <p>Страницы</p>
            <div class="btns_wrapper">
                <div class="new_project_btn" @click="publish" v-if="!loading">Опубликовать</div>
            <div class="new_project_btn" @click="publish" v-if="loading">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.00016 13.6668C3.32683 13.6668 0.333496 10.6735 0.333496 7.00016C0.333496 5.7535 0.680163 4.54016 1.3335 3.48683C1.52683 3.1735 1.94016 3.08016 2.2535 3.2735C2.56683 3.46683 2.66016 3.88016 2.46683 4.19349C1.94683 5.03349 1.66683 6.00683 1.66683 7.00016C1.66683 9.94016 4.06016 12.3335 7.00016 12.3335C9.94016 12.3335 12.3335 9.94016 12.3335 7.00016C12.3335 4.06016 9.94016 1.66683 7.00016 1.66683C6.6335 1.66683 6.3335 1.36683 6.3335 1.00016C6.3335 0.633496 6.6335 0.333496 7.00016 0.333496C10.6735 0.333496 13.6668 3.32683 13.6668 7.00016C13.6668 10.6735 10.6735 13.6668 7.00016 13.6668Z" fill="black"/>
                </svg>


                Загрузка</div>
                <router-link :to="{name:'NewPageStatic'}"><div class="new_project_btn">Новая страница</div></router-link>
            </div>
            
        </div>
        <div class="content">
            <draggable v-model="pages" @start="drag=true" @end="drag=false" handle=".drag_svg">
                <router-link :to="{name:'PageBlocksStatic', params: {static_id: page.id.toString()}}" v-for="(page, index) in pages" :key="page.id">
                    <div class="page_wrapper" v-if="page.type!='project_features' && page.type!='local_features' && page.type!='feature'">
                        <div class="grab_btn">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="drag_svg">
                                <path d="M6.66667 4.99984C7.58714 4.99984 8.33333 4.25365 8.33333 3.33317C8.33333 2.4127 7.58714 1.6665 6.66667 1.6665C5.74619 1.6665 5 2.4127 5 3.33317C5 4.25365 5.74619 4.99984 6.66667 4.99984Z" fill="black"/>
                                <path d="M6.66667 11.6668C7.58714 11.6668 8.33333 10.9206 8.33333 10.0002C8.33333 9.07969 7.58714 8.3335 6.66667 8.3335C5.74619 8.3335 5 9.07969 5 10.0002C5 10.9206 5.74619 11.6668 6.66667 11.6668Z" fill="black"/>
                                <path d="M6.66667 18.3333C7.58714 18.3333 8.33333 17.5871 8.33333 16.6667C8.33333 15.7462 7.58714 15 6.66667 15C5.74619 15 5 15.7462 5 16.6667C5 17.5871 5.74619 18.3333 6.66667 18.3333Z" fill="black"/>
                                <path d="M13.3337 4.99984C14.2541 4.99984 15.0003 4.25365 15.0003 3.33317C15.0003 2.4127 14.2541 1.6665 13.3337 1.6665C12.4132 1.6665 11.667 2.4127 11.667 3.33317C11.667 4.25365 12.4132 4.99984 13.3337 4.99984Z" fill="black"/>
                                <path d="M13.3337 11.6668C14.2541 11.6668 15.0003 10.9206 15.0003 10.0002C15.0003 9.07969 14.2541 8.3335 13.3337 8.3335C12.4132 8.3335 11.667 9.07969 11.667 10.0002C11.667 10.9206 12.4132 11.6668 13.3337 11.6668Z" fill="black"/>
                                <path d="M13.3337 18.3333C14.2541 18.3333 15.0003 17.5871 15.0003 16.6667C15.0003 15.7462 14.2541 15 13.3337 15C12.4132 15 11.667 15.7462 11.667 16.6667C11.667 17.5871 12.4132 18.3333 13.3337 18.3333Z" fill="black"/>
                            </svg>
                        </div>
                        <div class="pageid">
                            ID {{page.id}}
                        </div>
                        <div class="pagename">
                            <p>{{page.body.name}}</p>
                        </div>
                        <div class="pageactions">
                            <div @click="(e)=>{e.stopPropagation();e.preventDefault();copyPage(page)}">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.2861 0.0800781H3.84913C2.95093 0.0800781 2.22023 0.812978 2.22023 1.71388V2.21973H1.70898C0.810778 2.21973 0.0800781 2.95263 0.0800781 3.85403V12.2862C0.0800781 13.1871 0.810778 13.92 1.70898 13.92H10.1462C11.0473 13.92 11.7803 13.1871 11.7803 12.2862V11.7803H12.2861C13.1872 11.7803 13.9204 11.0474 13.9204 10.146V1.71383C13.9204 0.812978 13.1872 0.0800781 12.2861 0.0800781ZM10.7803 12.2861C10.7803 12.6357 10.4959 12.9199 10.1462 12.9199H1.70898C1.36228 12.9199 1.08008 12.6357 1.08008 12.2861V3.85398C1.08008 3.50438 1.36233 3.21968 1.70898 3.21968H2.72023H10.1462C10.4958 3.21968 10.7803 3.50433 10.7803 3.85398V11.2802V12.2861ZM12.9204 10.146C12.9204 10.4956 12.636 10.7803 12.2861 10.7803H11.7802V3.85398C11.7802 2.95263 11.0473 2.21968 10.1462 2.21968H3.22018V1.71383C3.22018 1.36423 3.50243 1.08003 3.84908 1.08003H12.2861C12.6359 1.08003 12.9204 1.36423 12.9204 1.71383V10.146Z" fill="black"/>
                                </svg>
                            </div>
                            <div @click="(e)=>{e.stopPropagation();e.preventDefault();modal=page}">
                                <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.667 4.6665C10.4902 4.6665 10.3206 4.73674 10.1956 4.86177C10.0706 4.98679 10.0003 5.15636 10.0003 5.33317V12.7938C9.9812 13.131 9.82964 13.4469 9.57866 13.6728C9.32767 13.8986 8.99759 14.0162 8.66033 13.9998H3.34033C3.00306 14.0162 2.67298 13.8986 2.422 13.6728C2.17101 13.4469 2.01945 13.131 2.00033 12.7938V5.33317C2.00033 5.15636 1.93009 4.98679 1.80506 4.86177C1.68004 4.73674 1.51047 4.6665 1.33366 4.6665C1.15685 4.6665 0.987279 4.73674 0.862254 4.86177C0.73723 4.98679 0.666992 5.15636 0.666992 5.33317V12.7938C0.686022 13.4847 0.978047 14.1398 1.47911 14.6157C1.98018 15.0917 2.64944 15.3497 3.34033 15.3332H8.66033C9.35121 15.3497 10.0205 15.0917 10.5215 14.6157C11.0226 14.1398 11.3146 13.4847 11.3337 12.7938V5.33317C11.3337 5.15636 11.2634 4.98679 11.1384 4.86177C11.0134 4.73674 10.8438 4.6665 10.667 4.6665Z" fill="black"/>
                                    <path d="M11.3333 2.6665H8.66667V1.33317C8.66667 1.15636 8.59643 0.98679 8.47141 0.861766C8.34638 0.736742 8.17681 0.666504 8 0.666504H4C3.82319 0.666504 3.65362 0.736742 3.5286 0.861766C3.40357 0.98679 3.33333 1.15636 3.33333 1.33317V2.6665H0.666667C0.489856 2.6665 0.320286 2.73674 0.195262 2.86177C0.0702379 2.98679 0 3.15636 0 3.33317C0 3.50998 0.0702379 3.67955 0.195262 3.80457C0.320286 3.9296 0.489856 3.99984 0.666667 3.99984H11.3333C11.5101 3.99984 11.6797 3.9296 11.8047 3.80457C11.9298 3.67955 12 3.50998 12 3.33317C12 3.15636 11.9298 2.98679 11.8047 2.86177C11.6797 2.73674 11.5101 2.6665 11.3333 2.6665ZM4.66667 2.6665V1.99984H7.33333V2.6665H4.66667Z" fill="black"/>
                                    <path d="M5.33333 11.3333V6.66667C5.33333 6.48986 5.2631 6.32029 5.13807 6.19526C5.01305 6.07024 4.84348 6 4.66667 6C4.48986 6 4.32029 6.07024 4.19526 6.19526C4.07024 6.32029 4 6.48986 4 6.66667V11.3333C4 11.5101 4.07024 11.6797 4.19526 11.8047C4.32029 11.9298 4.48986 12 4.66667 12C4.84348 12 5.01305 11.9298 5.13807 11.8047C5.2631 11.6797 5.33333 11.5101 5.33333 11.3333Z" fill="black"/>
                                    <path d="M8.00033 11.3333V6.66667C8.00033 6.48986 7.93009 6.32029 7.80506 6.19526C7.68004 6.07024 7.51047 6 7.33366 6C7.15685 6 6.98728 6.07024 6.86225 6.19526C6.73723 6.32029 6.66699 6.48986 6.66699 6.66667V11.3333C6.66699 11.5101 6.73723 11.6797 6.86225 11.8047C6.98728 11.9298 7.15685 12 7.33366 12C7.51047 12 7.68004 11.9298 7.80506 11.8047C7.93009 11.6797 8.00033 11.5101 8.00033 11.3333Z" fill="black"/>
                                </svg>
                            </div>
                            <div @click="(e)=>{e.stopPropagation();e.preventDefault();toggleActivity(index, page.active);}" :class="'toggler '+(page.active?'':'active')">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.99251 0.333054C6.90485 0.333916 6.81821 0.352118 6.73756 0.386649C6.65691 0.42118 6.58384 0.471357 6.52252 0.534287C6.46119 0.597217 6.41283 0.671711 6.38019 0.753437C6.34754 0.835163 6.33127 0.922522 6.33229 1.01057V6.36929C6.33234 6.54704 6.40267 6.71752 6.52783 6.84319C6.65298 6.96886 6.8227 7.03942 6.99967 7.03942C7.17664 7.03942 7.34637 6.96886 7.47152 6.84319C7.59667 6.71752 7.66701 6.54704 7.66706 6.36929V1.01057C7.66809 0.92131 7.65136 0.832725 7.61782 0.750053C7.58429 0.667382 7.53465 0.592257 7.4718 0.529135C7.40896 0.466013 7.33418 0.416176 7.25187 0.382497C7.16956 0.348818 7.08138 0.33201 6.99251 0.333054ZM10.8822 1.67633C10.8605 1.6757 10.8388 1.67613 10.8171 1.67763C10.6853 1.68887 10.5598 1.73926 10.4565 1.82235C10.3533 1.90544 10.277 2.01747 10.2374 2.14423C10.1977 2.271 10.1965 2.40678 10.2339 2.53424C10.2713 2.6617 10.3455 2.77511 10.4473 2.86004C11.6022 3.84514 12.3329 5.31135 12.3329 6.95784C12.3329 9.93079 9.95614 12.327 7.00293 12.327C4.04971 12.327 1.66777 9.93079 1.66777 6.95784C1.66778 5.32088 2.38915 3.86462 3.53254 2.87965C3.59915 2.82254 3.65391 2.75279 3.69369 2.67442C3.73348 2.59605 3.7575 2.51059 3.7644 2.4229C3.77129 2.3352 3.76092 2.24705 3.73388 2.16338C3.70684 2.07971 3.66365 2.00221 3.60679 1.93531C3.54993 1.8684 3.4805 1.8134 3.40248 1.77345C3.32446 1.73349 3.23936 1.70932 3.15205 1.70239C3.06474 1.69547 2.97693 1.70591 2.89363 1.73308C2.81033 1.76024 2.73318 1.8036 2.66657 1.86072C1.23927 3.09028 0.333014 4.92299 0.333008 6.95784C0.333002 10.6534 3.32786 13.6664 7.00293 13.6664C10.678 13.6664 13.6663 10.6534 13.6663 6.95784C13.6663 4.91112 12.7511 3.07087 11.3093 1.84111C11.1908 1.7373 11.0395 1.67894 10.8822 1.67633H10.8822Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                    </div>
            </router-link>
            </draggable>
        </div>
        <ModalDelete v-if="modal" :object="modal" :type="'page'" @on-change="getPages();modal=$event; $parent.$emit('on-change', 'page', undefined)"></ModalDelete>
    </div>
</template>
<style scoped>
    .drag_svg {
        cursor: grab;
    }
a{
    width: inherit !important;
    text-align: center !important;
}
    .project_header {
        height: 70px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background-color: white;
        margin-bottom: 20px;
        border-radius: 10px;
        align-items: center;
    }
    .project_header  > p {
        font-size: 32px;
    }
    .new_project_btn {
        padding: 12px 20px;
        border: 1px solid black;
        border-radius: 80px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .new_project_btn svg {
        margin-right: 10px;
    }
    .new_project_btn:hover {
        background-color: #F2F2F2 !important;
        color: #4C4C4C !important;
        border: 1px solid #4C4C4C;
    }
    .new_project_btn:active {
        background-color: #E5E5E5 !important;
        color: #1A1A1A !important;
        border: 1px solid #1A1A1A;
    }
    .project_settings {
        padding: 12px 20px;
        border: 1px solid black;
        border-radius: 80px;
        cursor: pointer;
        display: block;
        text-align: center;
        width: fit-content;
    }
    .btns_wrapper {
        display: flex;
        align-items: center;
    }
    .btns_wrapper >*:not(:last-child){
        margin-right: 14px;
    }
    .content {
        display: flex;
        flex-direction: column;
    }
    .page_card {
        background-color: white;
        border-radius: 10px;
        font-size: 20px;
        margin-bottom: 10px;  
    }
    .page_card a {
        height: 100%;
        padding: 20px;
    }
    .page_wrapper {
        display: flex;
        background-color: white;
        padding: 12px 20px;
       align-items: center;
        border-radius: 10px;
        margin-bottom: 10px;
        cursor: grab;
        height: 60px;
    }
     .page_wrapper:hover .pagename {
        text-decoration: underline;
        cursor: pointer;
     }
     .grab_btn {
        margin-right: 20px;
     }
    .pageid {
        font-size: 16px;
        color: #8A8A8A;
        border: 1px solid #8A8A8A;
        padding: 10px 8px;
        border-radius: 5px;
        margin-right: 20px;
        height: 36px;
    }
    .pagename {
         font-size: 20px;
         line-height: 20px;
         margin-right: 12px;
    }
    .pageactions {
        margin-left: auto;
        width: 112px;
        height: 36px;
        display: flex;
        border: 1px solid #1A1A1A;
        border-radius: 5px;
        align-items: center;
    }
    .pageactions > div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 34px;
        cursor: pointer;
        overflow: hidden;
    }
    .pageactions > div:first-child {
        border-radius: 5px 0 0 5px;
    }
    .pageactions > div:last-child {
        border-radius: 0 5px 5px 0;
    }
    .pageactions > div:hover {
        background-color: #F2F2F2;
    }
    .pageactions > div:active {
        background-color: #E5E5E5;
    }
    
    .pageactions > div:not(:first-child){
        border-left: 1px solid #1A1A1A;
    }
    .toggler.active {
        background-color: #EC5F59;
        border-radius: 0px 4px 4px 0;
    }
    .toggler.active:hover {
        background-color: #EC5F59;
    }
    .toggler.active > svg{
        filter:invert(1)
    }
    .edit_btn {
        cursor: pointer;
    }
</style>
<script>
import draggable from 'vuedraggable'
import ModalDelete from '../helpers/ModalDelete.vue'
export default {
  name: 'ProjectList',
  data: () => ({
    pages: [],
    modal: undefined,
    myhost: process.env.VUE_APP_MYHOST,
    loading: false
  }),
  methods: {
    getPages: function(){
        fetch(`${process.env.VUE_APP_MYAPI}/pages/?type=static`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+this.$cookies.get('token')
            }
        })
        .then(response=>{return response.json()})
        .then(data=>{
            this.pages = data;
            this.pages.sort(function(a,b) {
                return a.order- b.order
            });
        })
    },
        toggleActivity: function(page_id, activity){
        this.pages[page_id].active = !activity
    },
    copyPage: function(page_obj){
        if(!this.$cookies.get('token')){
            this.$router.push({name:'LoginForm'})
        }
        fetch(`${process.env.VUE_APP_MYAPI}/pages`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer '+this.$cookies.get('token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(page_obj)
            // TODO copy blocks
        })
        .then(response=>{
            if(!response.ok){
                    console.log(response.json())
                    this.$toast.clear();
                    this.$toast.error("Страница не скопирована")
                } 
                else {
                    this.$toast.clear();
                    this.$toast.success("Страница успешно скопирована")
                    
                }
            return response.json()
        })
        .then(data=>{
            let new_page = data
            this.$parent.$emit('on-change', 'page',new_page)
            this.getPages()
        })
    },
    publish: function(){
        if(!this.$cookies.get('token')){
            this.$router.push({name:'LoginForm'})
        }
        if(this.loading){
            return false
        }
        this.loading = true
        let toggleupdate = false
        for(let i=0;i<this.pages.length;i++){
            this.pages[i].order = i+1
            fetch(`${process.env.VUE_APP_MYAPI}/pages/${this.pages[i].id}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': 'Bearer '+this.$cookies.get('token'),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(this.pages[i])
                })
                .then(response=>{
                    if(!response.ok){
                        console.log(response.json())
                    } 
                    return response.json()
                })
                if(i==this.pages.length-1) toggleupdate = true
        }
        if(toggleupdate){
            this.loading = false
            this.$toast.clear();
            this.$toast.success("Изменения успешно опубликованы")
            this.$parent.$emit('on-change', 'page',undefined)
        }
    }
  },
  mounted () {
    this.getPages()
    this.$parent.$emit('on-bread', 'StaticPages', undefined, undefined, undefined)
  },
  components: {
    draggable, ModalDelete
  }
}
</script>