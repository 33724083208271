<template>
    <div class="inputblock">
        <p>{{label}}</p>
        <div class="map_container">
            <div id="map"></div>
        </div>
        
    </div>
</template>
<style scoped>
#map {
    flex: 1;
    height:533px;
    border-radius: 4px;
}
.content_table {
    flex: 1;
}
.map_container {
    display: flex;
    gap: 25px;
}
.addone {
    display: flex;
    font-size: 20px;
    margin-left: auto;
    cursor: pointer;
}
.addone > svg {
    margin-right: 8px;
}
.delete {
    border-radius: 5px;
    border: 1px solid #DEDEDE;
    padding: 12px;
    justify-self: center;
    cursor: pointer;
}
.delete  > svg {
    width: 16px;
    height: 16px;
}
.content_row > svg {
    margin-bottom: 13px;
    margin-right: 15px;
}
.photo  {
    display: flex;
    align-items: center;
}
label > p {
    font-size: 12px;
    margin-bottom: 4px;
}
.photo > label > svg {
    margin-right: 10px;
}
.photo, .title, .desc {
    flex: 1;
    margin-right: 10px;
    width: 100%;
}
input[type='text']{
    border: 1px solid #DEDEDE;
    padding: 12px 14px;
    width: 100%;
    font-size: 16px;
    color:black;
    border-radius: 4px;
}
#upload {
    display: none;
}
.content_table {
    display: flex;
    flex-direction: column;
    margin-bottom: 22px !important;
}
.content_row {
    display: flex;
    align-items: flex-end;
    justify-content:space-around;
    margin-bottom: 16px;
}
    .input::placeholder {
        color: #8A8A8A;
        font-size: 16px;
    }
    .inputblock {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .inputblock > p {
        margin-right: 20px;
        font-size: 20px;
        margin-bottom: 36px;
    }
</style>
<script>
const mapboxgl = require('mapbox-gl');


export default { 
    data: () => ({
    cardsArr: [],
    marker: undefined,
    map: undefined
  }),
  model: {
    prop: "value",
    event: "on-change"
  },
    methods: {
        initCards: function(){
            if(this.cards && this.cards.length>0) this.cardsArr = this.cards

        },
        getMarker: function(){
            if(this.cardsArr.length>0){
                this.markers = []
                this.marker = new mapboxgl.Marker()
                .setLngLat([this.cardsArr[1], this.cardsArr[0]])
                .addTo(this.map)
            }
            
        },
        addMarker: function(){
            if(this.marker) this.marker.remove()
            this.marker = new mapboxgl.Marker()
                .setLngLat([this.cardsArr[1], this.cardsArr[0]])
                .addTo(this.map)
        },
    },
    props: {
        label: {
            type: String || undefined,
        },
        cards: {
            type: (Array || undefined),
        },
    },
    mounted() {
        this.initCards()
        let app = this
        mapboxgl.accessToken = 'pk.eyJ1IjoiYS1lc3RhdGUiLCJhIjoiY2w3aGljMnFpMGVyNzNwb2F2aXp3OTBhaSJ9.O1G_TLcDCfNYqjDuJsUldg';
        this.map = new mapboxgl.Map({
            container: 'map', // container ID
            style: 'mapbox://styles/mapbox/streets-v11', // style URL
            center: [30.3141300,59.9386300], // starting position [lng, lat]
            zoom: 12, // starting zoom
            projection: 'globe' // display the map as a 3D globe
        });
        this.map.on('style.load', () => {
            this.map.setFog({});
                app.getMarker()
                app.map.on('click', (e)=>{
                let latlng = e.lngLat
                app.cardsArr = [latlng.lat, latlng.lng]
                app.addMarker()
                app.$emit('on-change', app.cardsArr)
            })
            
        });
        
    },
}
</script>