<template>
  <div id="app">
      <router-view name="homeView"></router-view>
  </div>
</template>

<style lang="scss">
    .v-application--wrap {
        min-height: unset !important;
    }
    .v-application {
      font-family: unset !important;
      color: black !important;
    }
   .mapboxgl-marker{
    width: 25px !important; 
    height: 25px !important;
    border-radius: 50% !important;
    border:1px solid gray !important;
    background-color:yellow !important;
}
.Vue-Toastification__toast--error {
  background-color: #EC5F59 !important;
  font-family: 'Manrope';
  height: 56px !important;
  border-radius: 4px !important;
  cursor: default;
}
.Vue-Toastification__toast--success {
  background-color: #67AD5B  !important;
  font-family: 'Manrope';
  height: 56px !important;
  border-radius: 4px !important;
  cursor: default;
}

</style>
<script>
  export default {
    head: {
      link: [
      { rel: 'stylesheet', href: 'https://api.mapbox.com/mapbox-gl-js/v2.10.0/mapbox-gl.css' },
    ]
    },
      beforeCreate () {
        if (!this.$cookies.get('token')) {
            this.$router.push({ name: 'LoginForm' })
        }
    }
  }
</script>
