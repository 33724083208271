<template>
<div>
    <div class="right_btn">
        <router-link :to="{name: 'ProjectList'}">Проекты</router-link>
    </div>
    <!--
    <div class="right_btn">
        <router-link to="/applies">Заявки</router-link>
    </div>
    -->
    <div class="right_btn">
        <router-link :to="{name: 'SiteSettings'}">Настройки</router-link>
    </div>
    <div class="right_btn">
        <router-link :to="{name: 'StaticPages'}">Страницы</router-link>
    </div>
    <div class="right_btn">
        <router-link :to="{name: 'SalesPages'}">Условия продаж</router-link>
    </div>
</div>

</template>
<style>
 .right_btn {
        font-size: 32px;
        line-height: 32px;
        padding: 19px 20px;
        width: 100%;
        display: block;
        background-color: white;
        border-radius: 10px;
        margin-bottom: 10px;
        cursor: pointer;
    }
    a {
        text-decoration: none;
        color: black;
        width: 100%;
        height: 100%;
        display: block;
    }
</style>
<script>


export default {
  name: 'MainMenu',
  data: ()=>({
    myhost: process.env.VUE_APP_MYHOST
  }),
  mounted(){
    this.$parent.$emit('on-bread', 'Main', undefined, undefined, undefined)
  }
}
</script>