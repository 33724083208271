<template>
    <div class="inputblock">
        <p>{{label}}</p>
        <div class="content_table" v-if="cardsArr">
            <draggable v-model="cardsArr" @start="drag=true" @end="drag=false" @change="$emit('on-change', cardsArr)" handle=".drag_svg">
            <div v-for="(card, index) in cardsArr" :key="index">
                <div class="content_row">
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="drag_svg">
                        <path d="M1.66667 4.00008C2.58714 4.00008 3.33333 3.25389 3.33333 2.33342C3.33333 1.41294 2.58714 0.666748 1.66667 0.666748C0.746192 0.666748 0 1.41294 0 2.33342C0 3.25389 0.746192 4.00008 1.66667 4.00008Z" fill="black"/>
                        <path d="M1.66667 10.6666C2.58714 10.6666 3.33333 9.92039 3.33333 8.99992C3.33333 8.07944 2.58714 7.33325 1.66667 7.33325C0.746192 7.33325 0 8.07944 0 8.99992C0 9.92039 0.746192 10.6666 1.66667 10.6666Z" fill="black"/>
                        <path d="M1.66667 17.3333C2.58714 17.3333 3.33333 16.5871 3.33333 15.6667C3.33333 14.7462 2.58714 14 1.66667 14C0.746192 14 0 14.7462 0 15.6667C0 16.5871 0.746192 17.3333 1.66667 17.3333Z" fill="black"/>
                        <path d="M8.33268 4.00008C9.25316 4.00008 9.99935 3.25389 9.99935 2.33342C9.99935 1.41294 9.25316 0.666748 8.33268 0.666748C7.41221 0.666748 6.66602 1.41294 6.66602 2.33342C6.66602 3.25389 7.41221 4.00008 8.33268 4.00008Z" fill="black"/>
                        <path d="M8.33268 10.6666C9.25316 10.6666 9.99935 9.92039 9.99935 8.99992C9.99935 8.07944 9.25316 7.33325 8.33268 7.33325C7.41221 7.33325 6.66602 8.07944 6.66602 8.99992C6.66602 9.92039 7.41221 10.6666 8.33268 10.6666Z" fill="black"/>
                        <path d="M8.33268 17.3333C9.25316 17.3333 9.99935 16.5871 9.99935 15.6667C9.99935 14.7462 9.25316 14 8.33268 14C7.41221 14 6.66602 14.7462 6.66602 15.6667C6.66602 16.5871 7.41221 17.3333 8.33268 17.3333Z" fill="black"/>
                    </svg>
                    <div class="photo">
                        <label>
                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.2255 10.3496C13.2255 12.1282 11.7786 13.5751 10 13.5751C8.22137 13.5751 6.77503 12.1282 6.77503 10.3496C6.77503 8.57094 8.22137 7.12405 10 7.12405C11.7786 7.12405 13.2255 8.57149 13.2255 10.3496ZM20 5.76948V14.9308C20 16.1521 19.0098 17.1423 17.7885 17.1423H2.21153C0.990214 17.1423 0 16.1521 0 14.9308V5.76948C0 4.54816 0.990214 3.55795 2.21153 3.55795H4.93172V2.79276C4.93172 1.72403 5.79753 0.857666 6.86681 0.857666H13.1332C14.2025 0.857666 15.0683 1.72403 15.0683 2.79276V3.5574H17.7885C19.0098 3.55795 20 4.54816 20 5.76948ZM14.8842 10.3496C14.8842 7.65647 12.6931 5.4654 10 5.4654C7.30746 5.4654 5.11638 7.65647 5.11638 10.3496C5.11638 13.0427 7.30746 15.2337 10 15.2337C12.6931 15.2337 14.8842 13.0427 14.8842 10.3496Z" fill="black"/>
                            </svg>
                            <input type="file" name="photo" class="file" @change="uploadPhoto($event); $emit('on-change', cardsArr)" :data-index="index">
                        </label>
                        
                        <div class="fileWrapper">
                            <input type="text" placeholder="Загрузить файл" :value="cardsArr[index]['image']['url']" disabled>
                            <div class="del" @click="cardsArr[index]['image'] = {};$emit('on-change', cardsArr)">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.6667 4.66675C12.4899 4.66675 12.3204 4.73699 12.1953 4.86201C12.0703 4.98703 12.0001 5.1566 12.0001 5.33341V12.7941C11.981 13.1312 11.8294 13.4471 11.5784 13.673C11.3274 13.8989 10.9974 14.0165 10.6601 14.0001H5.34008C5.00281 14.0165 4.67274 13.8989 4.42175 13.673C4.17077 13.4471 4.01921 13.1312 4.00008 12.7941V5.33341C4.00008 5.1566 3.92984 4.98703 3.80482 4.86201C3.67979 4.73699 3.51023 4.66675 3.33341 4.66675C3.1566 4.66675 2.98703 4.73699 2.86201 4.86201C2.73699 4.98703 2.66675 5.1566 2.66675 5.33341V12.7941C2.68578 13.4849 2.9778 14.14 3.47887 14.616C3.97993 15.0919 4.6492 15.3499 5.34008 15.3334H10.6601C11.351 15.3499 12.0202 15.0919 12.5213 14.616C13.0224 14.14 13.3144 13.4849 13.3334 12.7941V5.33341C13.3334 5.1566 13.2632 4.98703 13.1382 4.86201C13.0131 4.73699 12.8436 4.66675 12.6667 4.66675Z" fill="black"/>
                                    <path d="M13.3333 2.66675H10.6667V1.33341C10.6667 1.1566 10.5964 0.987034 10.4714 0.86201C10.3464 0.736986 10.1768 0.666748 10 0.666748H6C5.82319 0.666748 5.65362 0.736986 5.5286 0.86201C5.40357 0.987034 5.33333 1.1566 5.33333 1.33341V2.66675H2.66667C2.48986 2.66675 2.32029 2.73699 2.19526 2.86201C2.07024 2.98703 2 3.1566 2 3.33341C2 3.51023 2.07024 3.67979 2.19526 3.80482C2.32029 3.92984 2.48986 4.00008 2.66667 4.00008H13.3333C13.5101 4.00008 13.6797 3.92984 13.8047 3.80482C13.9298 3.67979 14 3.51023 14 3.33341C14 3.1566 13.9298 2.98703 13.8047 2.86201C13.6797 2.73699 13.5101 2.66675 13.3333 2.66675ZM6.66667 2.66675V2.00008H9.33333V2.66675H6.66667Z" fill="black"/>
                                    <path d="M7.33333 11.3333V6.66667C7.33333 6.48986 7.2631 6.32029 7.13807 6.19526C7.01305 6.07024 6.84348 6 6.66667 6C6.48986 6 6.32029 6.07024 6.19526 6.19526C6.07024 6.32029 6 6.48986 6 6.66667V11.3333C6 11.5101 6.07024 11.6797 6.19526 11.8047C6.32029 11.9298 6.48986 12 6.66667 12C6.84348 12 7.01305 11.9298 7.13807 11.8047C7.2631 11.6797 7.33333 11.5101 7.33333 11.3333Z" fill="black"/>
                                    <path d="M10.0001 11.3333V6.66667C10.0001 6.48986 9.92984 6.32029 9.80482 6.19526C9.67979 6.07024 9.51023 6 9.33341 6C9.1566 6 8.98703 6.07024 8.86201 6.19526C8.73699 6.32029 8.66675 6.48986 8.66675 6.66667V11.3333C8.66675 11.5101 8.73699 11.6797 8.86201 11.8047C8.98703 11.9298 9.1566 12 9.33341 12C9.51023 12 9.67979 11.9298 9.80482 11.8047C9.92984 11.6797 10.0001 11.5101 10.0001 11.3333Z" fill="black"/>
                                </svg>
                            </div> 
                        </div>
                    </div>
                    <div class="title">
                        <label for="title">
                            <p>Заголовок</p>
                        </label>
                        <input type="text" class="input inputcards title" placeholder="Заголовок" :value="cardsArr[index]['title']" @input="cardsArr[index]['title']=$event.target.value; $emit('on-change', cardsArr)"/>
                        <div class="typo" @click="typo(index, 'title', $event.target.closest('.title').querySelector('input').value)">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.99999 12H8.53843V11.1724C8.53843 11.0582 8.43512 10.9655 8.30765 10.9655H7.61537C7.23302 10.9655 6.92305 10.6876 6.92305 10.3448V1.44827C6.92305 1.33402 7.02637 1.24137 7.15383 1.24137H9.92306C10.8125 1.24137 11.1533 2.06276 11.2629 2.34455C11.295 2.42749 11.3823 2.48302 11.4803 2.48276H11.7692C11.8967 2.48276 12 2.39013 12 2.27585V0.206906C12 0.0926484 11.8967 0 11.7692 0H0.23078C0.103312 0 0 0.092625 0 0.206906V2.27587C0 2.39013 0.103312 2.48278 0.23078 2.48278H0.519699C0.617652 2.48304 0.704966 2.42752 0.737094 2.34457C0.846707 2.06278 1.18754 1.24139 2.07694 1.24139H4.84617C4.97361 1.24139 5.07695 1.33402 5.07695 1.4483V10.3448C5.07695 10.6876 4.76698 10.9655 4.38463 10.9655H3.69232C3.56488 10.9655 3.46154 11.0582 3.46154 11.1724V12H5.99999Z" fill="#1A1A1A"/>
                            </svg>
                        </div>
                    </div>
                    <div class="delete" @click="cardsArr.splice(index,1); $emit('on-change', cardsArr)">
                        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.666 4.6665C10.4892 4.6665 10.3196 4.73674 10.1946 4.86177C10.0696 4.98679 9.99935 5.15636 9.99935 5.33317V12.7938C9.98022 13.131 9.82866 13.4469 9.57768 13.6728C9.32669 13.8986 8.99662 14.0162 8.65935 13.9998H3.33935C3.00208 14.0162 2.672 13.8986 2.42102 13.6728C2.17003 13.4469 2.01847 13.131 1.99935 12.7938V5.33317C1.99935 5.15636 1.92911 4.98679 1.80409 4.86177C1.67906 4.73674 1.50949 4.6665 1.33268 4.6665C1.15587 4.6665 0.986302 4.73674 0.861278 4.86177C0.736254 4.98679 0.666016 5.15636 0.666016 5.33317V12.7938C0.685045 13.4847 0.977071 14.1398 1.47814 14.6157C1.9792 15.0917 2.64846 15.3497 3.33935 15.3332H8.65935C9.35023 15.3497 10.0195 15.0917 10.5206 14.6157C11.0216 14.1398 11.3137 13.4847 11.3327 12.7938V5.33317C11.3327 5.15636 11.2624 4.98679 11.1374 4.86177C11.0124 4.73674 10.8428 4.6665 10.666 4.6665Z" fill="black"/>
                            <path d="M11.3333 2.6665H8.66667V1.33317C8.66667 1.15636 8.59643 0.98679 8.4714 0.861766C8.34638 0.736742 8.17681 0.666504 8 0.666504H4C3.82319 0.666504 3.65362 0.736742 3.5286 0.861766C3.40357 0.98679 3.33333 1.15636 3.33333 1.33317V2.6665H0.666667C0.489856 2.6665 0.320286 2.73674 0.195262 2.86177C0.0702379 2.98679 0 3.15636 0 3.33317C0 3.50998 0.0702379 3.67955 0.195262 3.80457C0.320286 3.9296 0.489856 3.99984 0.666667 3.99984H11.3333C11.5101 3.99984 11.6797 3.9296 11.8047 3.80457C11.9298 3.67955 12 3.50998 12 3.33317C12 3.15636 11.9298 2.98679 11.8047 2.86177C11.6797 2.73674 11.5101 2.6665 11.3333 2.6665V2.6665ZM4.66667 2.6665V1.99984H7.33333V2.6665H4.66667Z" fill="black"/>
                            <path d="M5.33333 11.3333V6.66667C5.33333 6.48986 5.2631 6.32029 5.13807 6.19526C5.01305 6.07024 4.84348 6 4.66667 6C4.48986 6 4.32029 6.07024 4.19526 6.19526C4.07024 6.32029 4 6.48986 4 6.66667V11.3333C4 11.5101 4.07024 11.6797 4.19526 11.8047C4.32029 11.9298 4.48986 12 4.66667 12C4.84348 12 5.01305 11.9298 5.13807 11.8047C5.2631 11.6797 5.33333 11.5101 5.33333 11.3333Z" fill="black"/>
                            <path d="M7.99935 11.3333V6.66667C7.99935 6.48986 7.92911 6.32029 7.80409 6.19526C7.67906 6.07024 7.50949 6 7.33268 6C7.15587 6 6.9863 6.07024 6.86128 6.19526C6.73625 6.32029 6.66602 6.48986 6.66602 6.66667V11.3333C6.66602 11.5101 6.73625 11.6797 6.86128 11.8047C6.9863 11.9298 7.15587 12 7.33268 12C7.50949 12 7.67906 11.9298 7.80409 11.8047C7.92911 11.6797 7.99935 11.5101 7.99935 11.3333Z" fill="black"/>
                        </svg>
                    </div>
                </div>
                <div class="content_row alt">
                    <input type="text" class="inputcards" placeholder="Alt текст" :value="cardsArr[index]['image']['alt']" @input="cardsArr[index]['image']['alt'] = $event.target.value; $emit('on-change', cardsArr)"> 
                    <div class="typo" @click="typo(index, 'alt', $event.target.closest('.alt').querySelector('input').value)">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.99999 12H8.53843V11.1724C8.53843 11.0582 8.43512 10.9655 8.30765 10.9655H7.61537C7.23302 10.9655 6.92305 10.6876 6.92305 10.3448V1.44827C6.92305 1.33402 7.02637 1.24137 7.15383 1.24137H9.92306C10.8125 1.24137 11.1533 2.06276 11.2629 2.34455C11.295 2.42749 11.3823 2.48302 11.4803 2.48276H11.7692C11.8967 2.48276 12 2.39013 12 2.27585V0.206906C12 0.0926484 11.8967 0 11.7692 0H0.23078C0.103312 0 0 0.092625 0 0.206906V2.27587C0 2.39013 0.103312 2.48278 0.23078 2.48278H0.519699C0.617652 2.48304 0.704966 2.42752 0.737094 2.34457C0.846707 2.06278 1.18754 1.24139 2.07694 1.24139H4.84617C4.97361 1.24139 5.07695 1.33402 5.07695 1.4483V10.3448C5.07695 10.6876 4.76698 10.9655 4.38463 10.9655H3.69232C3.56488 10.9655 3.46154 11.0582 3.46154 11.1724V12H5.99999Z" fill="#1A1A1A"/>
                        </svg>
                    </div>
                </div>
            </div>
                
            </draggable>
            
        </div>
        <div class="addone" @click="cardsArr.push({
            'image': {
                'url': '',
                'alt': ''
            },
            'title': '',
            'description': '',
        }); $emit('on-change', cardsArr)">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5938 8.375H9.625V5.40625C9.625 5.32031 9.55469 5.25 9.46875 5.25H8.53125C8.44531 5.25 8.375 5.32031 8.375 5.40625V8.375H5.40625C5.32031 8.375 5.25 8.44531 5.25 8.53125V9.46875C5.25 9.55469 5.32031 9.625 5.40625 9.625H8.375V12.5938C8.375 12.6797 8.44531 12.75 8.53125 12.75H9.46875C9.55469 12.75 9.625 12.6797 9.625 12.5938V9.625H12.5938C12.6797 9.625 12.75 9.55469 12.75 9.46875V8.53125C12.75 8.44531 12.6797 8.375 12.5938 8.375Z" fill="black"/>
                    <path d="M9 0.25C4.16797 0.25 0.25 4.16797 0.25 9C0.25 13.832 4.16797 17.75 9 17.75C13.832 17.75 17.75 13.832 17.75 9C17.75 4.16797 13.832 0.25 9 0.25ZM9 16.2656C4.98828 16.2656 1.73438 13.0117 1.73438 9C1.73438 4.98828 4.98828 1.73438 9 1.73438C13.0117 1.73438 16.2656 4.98828 16.2656 9C16.2656 13.0117 13.0117 16.2656 9 16.2656Z" fill="black"/>
                </svg>
                <p>Добавить изображение</p>
            </div>
    </div>
</template>
<style scoped>
    .fileWrapper input {
        border-radius: 4px 0 0 4px !important;
        border-right: none !important;
    }
    .fileWrapper .del {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #FFFFFF;
        border: 1px solid #DEDEDE;
        border-radius: 0px 4px 4px 0px;
    }
    .fileWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .drag_svg {
        cursor: grab;
    }
        .typo {
        position:absolute;
        right: 8px;
        z-index: 1;
        cursor: pointer;
        background-color: white;
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #DEDEDE;
        border-radius: 4px;
        width: 24px;
        height: 24px;
        top: 28px;
    }
    .content_row.alt .typo {
        top:10px;
    }
    .typo:hover {
        background-color: #F2F2F2;
        border: 1px solid #1A1A1A;
        border-radius: 4px;
    }
    .typo:active {
        background: #E5E5E5;
        border: 1px solid #1A1A1A;
        border-radius: 4px;
    }
.addone {
    display: flex;
    font-size: 20px;
    margin-left: auto;
    cursor: pointer;
}
.addone > svg {
    margin-right: 8px;
}
.delete {
    border-radius: 5px;
    border: 1px solid #DEDEDE;
    padding: 12px;
    justify-self: center;
    cursor: pointer;
}
.delete  > svg {
    width: 16px;
    height: 16px;
}
.content_row > svg {
    margin-bottom: 13px;
    margin-right: 15px;
}
.photo  {
    display: flex;
    align-items: center;
}
label > p {
    font-size: 12px;
    margin-bottom: 4px;
}
.photo > label > svg {
    margin-right: 10px;
}
.photo, .title, .desc {
    flex: 1;
    margin-right: 10px;
    width: 100%;
    position: relative;
}
input[type='text']{
    border: 1px solid #DEDEDE;
    padding: 12px 14px;
    width: 100%;
    font-size: 16px;
    color:black;
    border-radius: 4px;
}
.file {
    display: none;
}
.content_table {
    display: flex;
    flex-direction: column;
    margin-bottom: 22px !important;
}
.content_row {
    display: flex;
    align-items: flex-end;
    justify-content:space-around;
    margin-bottom: 16px;
    position: relative;
}
    .input {
        border: 1px solid #DEDEDE;
        padding: 12px 14px;
        width: 50%;
        border-radius: 4px;
    }
    .input::placeholder {
        color: #8A8A8A;
        font-size: 16px;
    }
    .inputblock {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .inputblock > p {
        margin-right: 20px;
        font-size: 20px;
        margin-bottom: 36px;
    }
</style>
<script>
import draggable from 'vuedraggable'
export default { 
    data: () => ({
    cardsArr: [
        {
            "image": {
                "url": "",
                "alt": ""
            },
            "title": "",
        },
        {
            "image": {
                "url": "",
                "alt": ""
            },
            "title": "",
        },
        {
            "image": {
                "url": "",
                "alt": ""
            },
            "title": "",
        },
        {
            "image": {
                "url": "",
                "alt": ""
            },
            "title": "",
        },
    ]
  }),
  model: {
    prop: "value",
    event: "on-change"
  },
    methods: {
        initCards: function(){
            if(this.cards.length>0) this.cardsArr = this.cards
        },
        uploadPhoto: function(e){
            let data = new FormData()
            data.append('files', e.target.files[0])
            fetch(`${process.env.VUE_APP_MYAPI}/upload`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer '+this.$cookies.get('token')
                },
                body: data
            })
            .then(response=>{
                if(!response.ok){
                    console.log(response)
                }
                return response.json()
            })
            .then(data=>{
                this.cardsArr[e.target.dataset.index]['image'].width = data[0].width
                this.cardsArr[e.target.dataset.index]['image'].height = data[0].height
                this.cardsArr[e.target.dataset.index]['image'].url = data[0].url
                this.$el.querySelector('.file').value = null
            })
        },
        typo: function(num, type, text){
            if (!String.r) {
                String.prototype.r = String.prototype.replace
            }
            var r = function (text, regex) {
            return new RegExp(text, regex)
            };
            text = text.r(/([\d]) ?(€|£|л\.|[скм]?м[/^¹²³.\s]|г[аг]?[\s.]|ц\.|т[\s.]|р\.|руб\.|уе|тыс\.|млн|млрд)/g, "$1 $2").r(/([\d]) г\.[  ]?г\./g, "$1 гг.").r(/([IVXLCDM]) ?(вв?\.)/g, "$1 $2").r(/([IVXLCDM]) в\.[  ]?в\./g, "$1 вв.");
            //text = text.r(/([^\d]|^)([\d]+(?:[.,][\d]+)?) ?\$/g, "$1$$$2").r(/(,|;|:|!|\?|\))([^\d\s=?!:,.'’‘‚"«»“”[])/gi, "$1 $2").r(/(\[color=[^\n]*\])( | )( | )( | )( | )/g, "$2" + "$3" + "$4" + "$5" + "$1").r(/ {2,}/g, " ").r(/\.([^\s\dA-Za-z\n=?:;,.'’‘"»„“”[]+)/gi, ". $1").r(/([А-яёЁ])\.([^\sA-Za-z\n=?:;,.'’‘"»„“”[]+)/g, "$1. $2").r(/ (\.|,|!|\?|;|:)/g, "$1").r(/(—|–|-)\.{2,4} /g, "$1 ...").r(/(\n|^|&)( *)\.{2,4} /g, "$1" + "$2...").r(/(['‘‚"«„“(])\.{2,4} /g, "$1...").r(/(\s|^)\.{2,4}[  ]/g, "$1...").r(/\( /g, "(").r(/ \)/g, ")").r(/([^\s])\(/g, "$1 (").r(/([^\d])(,|\.)([\d])/g, "$1" + "$2 $3").r(/(!|\?|\))([^\s=?!:,.'’‘‚"«»“”[]+)/gi, "$1 $2").r(/ %/g, "%").r(/P\. ?P\. ?S\./g, "P. P. S.").r(/P\. ?S\./g, "P. S.").r(/и др\./g, "и др.").r(/([\s]|^)(гл?|ул|д|илл)\. ([A-Za-zА-яёЁ0-9])/g, "$1" + "$2. $3").r(/(\s|^)([тнсюзв])\. ?([еочнпдшдэ])\./g, "$1" + "$2. $3.").r(/(\s|^)т\.? ?([нпдч])\./g, "$1т. $2.").r(/ н\. э\./g, " н. э").r(/([№§]) ?([\dIVXLCDM])/g, "$1 $2").r(/(\d)([lpd]pi)([\s,.!?]|$)/g, "$1 $2" + "$3").r(/ГОСТ /gi, "ГОСТ ").r(/ГОСТ Р /gi, "ГОСТ Р ").r(/([\s]|^)до[  ]нэ\./g, "$1до н. э.");
            text = text.r(/ {2,}/g, " ");

            // Простановка неразрывного пробела после предлогов, союзов
            for (var p = 0; p < 2; ++p) {
                text = text.r(/([  \n\t\v]|^)([иаксуов]|же|до|из|н[аое]|по|о[тб]|за|как|что|над|под|пр[ои]|или|для|без|если|едва|л?ибо|зато|пока|дабы|ежели|когда|перед|чтобы|через|пусть|будто|однако|словно) ([А-яёЁ])/gi, "$1" + "$2&nbsp;$3");
            }
            text = text.r(/\(c\)/g, "©").r(/\(r\)/g, "®").r(/\(tm\)/g, "™");
            // Расстановка дефисов
            var mst = "(где|зачем|как|какая|каки[емх]|како[ейм]|какого|какому|кем|когда|кого|кому?|кто|куда|откуда|почему|чего|чему?|что|ч[её]м)";
            text = text.r(r("([^А-яёЁ]|^)" + mst + "(?:[  ]?|-[  ]|[  ]-)(то|либо|нибудь)([^А-яёЁ]|$)", "gi"), "$1" + "$2-$3" + "$4").r(r("([^А-яёЁ]|^)" + mst + "(?:[  ]?|-[  ]|[  ]-)(то|либо|нибудь)([^А-яёЁ]|$)", "gi"), "$1" + "$2-$3" + "$4").r(r("([^А-яёЁ]|^)(кое|кой)(?:[  ]?[-—]?[  ]?)" + mst + "([^А-яёЁ]|$)", "gi"), "$1" + "$2-$3" + "$4").r(r("([^А-яёЁ]|^)(ко[ей])(?:[  ]?[-—]?[  ]?)" + mst + "([^А-яёЁ]|$)", "gi"), "$1" + "$2-$3" + "$4").r(/([\s]|^)(из)(?:[  ]?[-—]?[  ]?)(за)([\s]|$)/gi, "$1" + "$2-$3" + "$4").r(/([\s]|^)(из)(?:[  ]?[-—]?[  ]?)(под)([\s]|$)/gi, "$1" + "$2-$3" + "$4").r(/([А-яёЁ]{2,}) (ка|кась)([\s,.?!]|$)/g, "$1-$2" + "$3").r(/([^А-яёЁ]|^)(вс[ёе]|так)(?:[  ]?|-[  ]|[  ]-)(таки)([^А-яёЁ]|$)/gi, "$1" + "$2-$3" + "$4").r(/(ГОСТ(?:[  ]Р)?(?:[  ](?:ИСО|ISO))?)[  ]([\d.]+)-([\d]+)/gi, "$1 $2–$3");

            // Расстановка тире в датах
            text = text.r(/([IVXLCDM]{1,3})-([IVXLCDM]{1,3})[  ]?вв?\.?([\s.,?!;)])/g, "$1—$2 вв.$3").r(/([\d]{1,4})-([\d]{1,4})[  ]?гг?.([\s.,?!;)])/g, "$1–$2 гг.$3").r(/([^\d]|^)([0-2][0-9]:[0-5][0-9])-([0-2][0-9]:[0-5][0-9])([^\d]|$)/g, "$1" + "$2–$3" + "$4")/*.r(/(\s|^)([IVXLСDМ]+)-{1,2}([IVXLСDМ]+)(\s|$)/g,"$1"+"$2—$3"+"$4")*/;
            var mo = "(?:[ьяюе]|[её]м)";
            var to = "(?:[ауе]|ом)";
            var month = "(январ" + mo + "|феврал" + mo + "|март" + to + "|апрел" + mo + "|ма(?:[йяюе]|ем)|ию[нл]" + mo + "август" + to + "|сентябр" + mo + "|ноябр" + mo + "|октябр" + mo + "|декабр" + mo + ")";
            text = text.r(r("([\\s]|^)([1-3]?[\\d])-([1-3]?[\\d])[  ]?" + month + "([^А-яёЁ]|$)", "gi"), "$1" + "$2–$3 $4" + "$5").r(r("([^А-яёЁ]|^)" + month + "-" + month + "([^А-яёЁ]|&)", "gi"), "$1" + "$2—$3" + "$4");

            text = text.r(/(\d)--(\d)/g, '$1–$2').r(/([^-]|\s|^)--([^-]|$|\n)/g, '$1—$2').r(/([^-\d]|^)(\d+)-(\d+)([^-\d]|$)/g, "$1" + "$2−$3" + "$4").r(/([^a-z][a-z]|[Α-Ωα-ω+=*/])-(\d)/g, "$1−$2")/*.r(/([A-Za-z\s]|^)-(\d+)([^-\d]|$)/g,"$1"+sym[20]+"$2"+"$3").r(/([^-\d]|^)(\d+)-([A-Za-z])/g,"$1"+"$2"+sym[20]+"$3")*/;
            
            text = text.r(/([^.])\.{2,4}/g, "$1...").r(/(\?!|!\?)\.{3}/g, "?!.").r(/\?\.{3}/g, "?..").r(/!\.{3}/g, "!..").r(/(!+)(\?+)/g, "$2" + "$1").r(/(\d+?)[xх](\d+?)/g, "$1×$2").r(/(\d+?)([  ])[xх]([  ])(\d+?)/g, "$1×$4").r(/([0-9a-zA-ZΑ-Ωα-ωА-яёЁ])\^([0-9]+)/g, function ($0, $1, $2) {
            var l = "0123456789", g = "⁰¹²³⁴⁵⁶⁷⁸⁹";
            var re = $2;
            for (var j = 0; j < 10; ++j) {
                re = re.r(r(l.charAt(j), "g"), g.charAt(j));
            }
            return $1 + re
            }).r(/!=/g, "≠").r(/\+\/[-−]/g, "±").r(/~=/g, "≈").r(/<=/g, "≤").r(/>=/g, "≥").r(/<->/g, "↔").r(/<-([^-]|&)/g, "←$1").r(/([^-]|^)->/g, "$1→").r(/(!+)(\?+)/g, "$2" + "$1").r(/\?{3,}/g, "???").r(/!{3,}/g, "!!!");
            // Дроби
            var t1 = "([^0-9A-Za-zА-яёЁ/]|^)", t2 = "([^0-9A-Za-zА-яёЁ/]|$)";
            text = text.r(r(t1 + "1/2" + t2, "g"), "$1½$2").r(r(t1 + "1/4" + t2, "g"), "$1¼$2").r(r(t1 + "2/4" + t2, "g"), "$1½$2").r(r(t1 + "3/4" + t2, "g"), "$1¾$2").r(r(t1 + "1/3" + t2, "g"), "$1⅓$2").r(r(t1 + "2/3" + t2, "g"), "$1⅔$2").r(r(t1 + "1/5" + t2, "g"), "$1⅕$2").r(r(t1 + "2/5" + t2, "g"), "$1⅖$2").r(r(t1 + "3/5" + t2, "g"), "$1⅗$2").r(r(t1 + "4/5" + t2, "g"), "$1⅘$2").r(r(t1 + "1/6" + t2, "g"), "$1⅙$2").r(r(t1 + "2/6" + t2, "g"), "$1⅓$2").r(r(t1 + "3/6" + t2, "g"), "$1½$2").r(r(t1 + "4/6" + t2, "g"), "$1⅔$2").r(r(t1 + "5/6" + t2, "g"), "$1⅚$2").r(r(t1 + "1/8" + t2, "g"), "$1⅛$2").r(r(t1 + "2/8" + t2, "g"), "$1¼$2").r(r(t1 + "3/8" + t2, "g"), "$1⅜$2").r(r(t1 + "4/8" + t2, "g"), "$1½$2").r(r(t1 + "5/8" + t2, "g"), "$1⅝$2").r(r(t1 + "6/8" + t2, "g"), "$1¾$2").r(r(t1 + "7/8" + t2, "g"), "$1⅞$2").r(/,+/g, ",").r(/:+/g, ":").r(/;+/g, ";").r(/([a-zA-ZА-яёЁ0-9]) (а|но)([\s$,]|\.\.\.)/g, "$1, $2" + "$3").r(/([a-zA-ZА-яёЁ0-9]) однако([\s$,.!?:])/g, "$1, однако" + "$2");
            text = text.r(/([^a-zА-яёЁ]|^)([a-zА-яёЁ]+)[  ](\2)([^a-zA-ZА-яёЁ]|$)/gi, "$1" + "$2" + "$4")


            text = text.r(/(^|\n|\s|—|-|\()"/g, "$1«").r(/"($|\n|\s|—|-|\.|,|!|\?|:|;|\))/g, "»$1").r(/«\)/g, "»)").r(/«( ?)/g, "«").r(/( ?)»/g, "»").r(/>"/g, ">«").r(/"</g, "»<").r(/«""/g, "«««").r(/«"/g, "««").r(/""»/g, "»»»").r(/"»/g, "»»").r(/("{2}|"»)/g, "»»").r(/$"/g, "«").r(/([A-Za-zа-яА-ЯёЁ])'/g, "$1’");
            text = text.r(/[a-zA-ZА-яёЁ]"-/g, "$1»-").r(/-"[a-zA-ZА-яёЁ]/g, "-«$1");
            text = text.r(/(^[^«»]*)"/g, "$1«").r(/"([^«»]*$)/g, "»$1").r(/«([^«»]*)"/g, "«$1»").r(/"([^«»]*)»/g, "«$1»");
            function rl(i, j) {
                var b = "", c, d = "";
                if (i != 0) {
                    b = text.substring(0, i);
                }
                if (j != text.length - 1) {
                    d = text.substring(j + 1, text.length);
                }
                c = text.substring(i, j + 1);
                for (var p = 0; p < 32; ++p) {
                    c = c.r(/«([^«»]*)«([^»]*)»/g, "«$1„$2“");
                        c = c.r(/„([^„“]*)„([^“]*)“/g, "„$1‚$2‘");
                }
                return b + c + d;
            }
            var level = 0;
            for (var i = 0; i < text.length; ++i) {
                if (text.charAt(i) == '«') {
                    ++level;
                    for (var j = i + 1; j < text.length; ++j) {
                        if (text.charAt(j) == '«') {
                            ++level;
                        }
                        if (text.charAt(j) == '»') {
                            --level;
                            if (level <= 0) {
                                text = rl(i, j);
                                i = j;
                                break;
                            }
                        }
                    }
                    level = 0;
                }
            }
            text = text.r(/(\s|^|<p>)([«"„‚]*)(-|–)([\s]|$)/g, "$1" + "$2—$4");
            text = text.r(/([A-Za-zА-яёЁ0-9]) —/g, "$1 —");
            text = text.r(/([.,!?] |\n|^|<p>)— ([A-Za-zА-яёЁ0-9«"„‚])/g, "$1— $2");
            
            text = text.r(/([0-9]+) ((?:[А-яёЁa-zA-Z]+)+)/g, "$1&nbsp;$2");
            text = text.r(/\n/g, "<br />");
            if(type=='alt'){
                this.cardsArr[num]['image']['alt'] = text
            } else {
                this.cardsArr[num][type] = text
            }
            this.$emit("on-change", this.cardsArr)
            return text
       }
    },
    props: {
        label: {
            type: String || undefined,
        },
        cards: {
            type: (Array || undefined),
        },
    },
    mounted() {
        this.initCards()
        let a = this
        function doc_keyUp(e) {

// this would test for whichever key is 40 (down arrow) and the ctrl key at the same time
        if (e.key === 'Backspace' && e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            //...
            let elements = a.$el.querySelectorAll('.inputcards')
            for(let i = 0;i<elements.length;i++){
                if(elements[i] === document.activeElement){
                    let num = Math.floor(i/2)
                    let type = null
                    if(elements[i].classList.contains('title')) type='title'
                    else if(elements[i].classList.contains('desc')) type = 'description'
                    else type = 'alt'
                    let text = a.typo(num, type, elements[i].value)
                    elements[i].value = text
                }
            }
            //...
            
        }
    }
    document.addEventListener('keydown', doc_keyUp, false);
    },
      components: {
    draggable
  }
}
</script>