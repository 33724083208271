<template>
<div class="modalWrapper" @click="$emit('on-change', undefined)">
            <div class="modalContent" @click="$event.stopPropagation()">
                <p>Вы действительно хотите удалить {{type=='page'?'страницу\n':'блок\n'}} «{{object.body.name}}»?</p>
                <div class="buttons">
                    <div class="button" @click="deleteObject">Да</div>
                    <div class="button" @click="$emit('on-change', undefined)">Нет</div>
                </div>
            </div>
        </div>
</template>
<style scoped>
.modalWrapper {
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
}
.modalContent {
    width: fit-content;
    background-color: white;
    margin: 260px auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 60px 40px;
    border-radius: 10px;
}
.buttons {
    display: flex;
  
}
.button {
    padding: 19.5px 131px;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 24px;
}
p{ 
    text-align: center;
    margin-bottom: 40px;
    white-space: pre-line;
    font-size: 24px;
}
.button:first-child {
    background-color: black;
    margin-right: 16px;
}
.button:first-child:hover {
    background-color: #4C4C4C;
}
.button:first-child:active {
    background-color: #333333
}
.button:last-child {
    border: 1px solid black;
    color: black;
}
.button:last-child:hover {
    background-color: #F2F2F2
}
.button:last-child:active {
    background-color: #E5E5E5
}
</style>
<script>
export default {
    data:()=>({

    }),
    props: {
        object: {
            type: Object
        },
        type: {
            type: String
        },
        parent_id: {
            type: Number
        }
    },
    methods: {
        deleteObject(){
            if(!this.$cookies.get('token')){
                this.$router.push({name:'LoginForm'})
            }
            if(this.type=='page'){
                fetch(`${process.env.VUE_APP_MYAPI}/pages/${this.object.id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer '+this.$cookies.get('token')
                    }
                })
                .then(response=>{
                    if(response.ok){
                        this.$emit('on-change', undefined)
                        this.$toast.clear();
                        this.$toast.success("Страница успешно удалена")
                    }
                    else {
                        this.$toast.clear();
                        this.$toast.error("Страница не удалена")
                    }
                    return response.json()
                })
            }
            else {
                fetch(`${process.env.VUE_APP_MYAPI}/blocks/${this.object.id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer '+this.$cookies.get('token')
                    }
                })
                .then(response=>{
                    if(response.ok){
                        this.$emit('on-change', undefined)
                        this.$toast.clear();
                        this.$toast.success("Блок успешно удален")
                    }
                    else {
                        this.$toast.clear();
                        this.$toast.error("Блок не удален")
                    }
                    return response.json()
                })
            }
        }
    }
}
</script>