<template>
    <div v-if="block">
        <div class="project_header">
            <div class="blockname_wrapper">
                <div class="id_wrapper">ID {{block.id}}</div>
                <p v-if="block.body.month">{{block.body.month}}</p>
                <span v-else class="titleinput">{{block.body.name}}</span>
                <div class="edit_icons" v-if="!name_edit">
                    <svg v-if="block.body.name" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="toggleNameEdit">
                            <path d="M15.502 1.94684L14.0521 0.49684C13.3896 -0.165629 12.3118 -0.165598 11.6493 0.49684C11.0256 1.12062 1.49887 10.6481 0.862216 11.2848C0.794404 11.3526 0.748966 11.4427 0.732779 11.5309L0.00784109 15.4459C-0.0202527 15.5977 0.0281223 15.7536 0.137279 15.8627C0.24656 15.972 0.402497 16.0203 0.554091 15.9922L4.46875 15.2672C4.55922 15.2503 4.64815 15.2044 4.71484 15.1377L15.502 4.34975C16.166 3.68575 16.1661 2.61093 15.502 1.94684ZM1.05378 14.9461L1.49231 12.5779L3.42187 14.5076L1.05378 14.9461ZM4.3834 14.1433L1.85656 11.6163L11.0819 2.39025L13.6087 4.91728L4.3834 14.1433ZM14.8391 3.68684L14.2716 4.25437L11.7447 1.72734L12.3122 1.15981C12.6091 0.862871 13.0922 0.86284 13.3892 1.15981L14.8391 2.60981C15.1367 2.90746 15.1367 3.38915 14.8391 3.68684Z" fill="black"/>
                        </svg>
                </div>
                <div class="edit_icons" v-else>
                    <div @click="saveName">
                        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.12673 11.7688C5.97396 11.9171 5.76564 12 5.54914 12C5.33265 12 5.12433 11.9171 4.97156 11.7688L0.35905 7.31284C-0.119683 6.85044 -0.119683 6.10081 0.35905 5.63919L0.936635 5.08131C1.41537 4.61891 2.19066 4.61891 2.66939 5.08131L5.54914 7.86283L13.3306 0.346803C13.8093 -0.115601 14.5854 -0.115601 15.0634 0.346803L15.6409 0.904685C16.1197 1.36709 16.1197 2.11672 15.6409 2.57833L6.12673 11.7688Z" fill="black"/>
                        </svg>

                    </div>
                    <div @click="toggleNameEdit">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.51672 15.8998C1.12445 15.9226 0.738661 15.7922 0.440711 15.536C-0.146904 14.9449 -0.146904 13.9902 0.440711 13.3991L13.3075 0.532244C13.9187 -0.0396496 14.8777 -0.00785834 15.4496 0.603313C15.9668 1.15599 15.9969 2.00547 15.5202 2.59339L2.57756 15.536C2.28346 15.7885 1.90386 15.9186 1.51672 15.8998Z" fill="black"/>
                        <path d="M14.3684 15.8998C13.9708 15.8981 13.5898 15.7403 13.3075 15.4603L0.440589 2.59337C-0.103806 1.95765 -0.0297925 1.00091 0.605933 0.456468C1.17334 -0.0294346 2.01014 -0.0294346 2.57749 0.456468L15.5201 13.3234C16.1312 13.8954 16.1628 14.8545 15.5907 15.4655C15.568 15.4898 15.5444 15.5133 15.5201 15.5361C15.2032 15.8117 14.7861 15.9434 14.3684 15.8998V15.8998Z" fill="black"/>
                    </svg>


                    </div>
                </div>
                
            </div>
            
            <div class="new_project_btn" @click="publish" v-if="!loading">Опубликовать</div>
            <div class="new_project_btn" @click="publish" v-if="loading">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.00016 13.6668C3.32683 13.6668 0.333496 10.6735 0.333496 7.00016C0.333496 5.7535 0.680163 4.54016 1.3335 3.48683C1.52683 3.1735 1.94016 3.08016 2.2535 3.2735C2.56683 3.46683 2.66016 3.88016 2.46683 4.19349C1.94683 5.03349 1.66683 6.00683 1.66683 7.00016C1.66683 9.94016 4.06016 12.3335 7.00016 12.3335C9.94016 12.3335 12.3335 9.94016 12.3335 7.00016C12.3335 4.06016 9.94016 1.66683 7.00016 1.66683C6.6335 1.66683 6.3335 1.36683 6.3335 1.00016C6.3335 0.633496 6.6335 0.333496 7.00016 0.333496C10.6735 0.333496 13.6668 3.32683 13.6668 7.00016C13.6668 10.6735 10.6735 13.6668 7.00016 13.6668Z" fill="black"/>
                </svg>


                Загрузка</div>
        </div>
        <div class="content">
            <div v-if="block.type=='concept'">
                <ShortTextField placeholder="Боковой заголовок" label="Боковой заголовок" name="label" :value="block.body.label" @on-change="block.body.label=$event"></ShortTextField>
                <ShortTextField placeholder="Tag ID" label="Tag ID" name="tag_id" :value="block.body.tag_id" @on-change="block.body.tag_id=$event"></ShortTextField>
                <LongTextField placeholder="Описание блока" label="Описание блока" name="description" :value="block.body.description" @on-change="block.body.description=$event"></LongTextField>
                <SingleImageField :label="'Картинка'" :cards="block.body.image" @on-change="block.body.image=$event"></SingleImageField>
                <CardsField :label="'Блок с карточками'" :cards="block.body.items" @on-change="block.body.items=$event"></CardsField>
            </div>
            <div v-if="block.type=='slider'">
                <ShortTextField placeholder="Боковой заголовок" label="Боковой заголовок" name="label" :value="block.body.label" @on-change="block.body.label=$event"></ShortTextField>
                <ShortTextField placeholder="Tag ID" label="Tag ID" name="tag_id" :value="block.body.tag_id" @on-change="block.body.tag_id=$event"></ShortTextField>
                <ImagesField :label="'Блок с изображениями'" :cards="block.body.slides" @on-change="block.body.slides=$event"></ImagesField>
            </div>
            <div v-if="block.type=='architecture'">
                <ShortTextField placeholder="Боковой заголовок" label="Боковой заголовок" name="label" :value="block.body.label" @on-change="block.body.label=$event"></ShortTextField>
                <ShortTextField placeholder="Tag ID" label="Tag ID" name="tag_id" :value="block.body.tag_id" @on-change="block.body.tag_id=$event"></ShortTextField>
                <LongTextField placeholder="Описание блока" label="Описание блока" name="description" :value="block.body.description" @on-change="block.body.description=$event"></LongTextField>
                <ImagesField :label="'Блок с изображениями'" :cards="block.body.images" @on-change="block.body.images=$event" :locked="true" :amount="2"></ImagesField>
            </div>
            <div v-if="block.type=='carousel'">
                <ShortTextField placeholder="Боковой заголовок" label="Боковой заголовок" name="label" :value="block.body.label" @on-change="block.body.label=$event"></ShortTextField>
                <ShortTextField placeholder="Tag ID" label="Tag ID" name="tag_id" :value="block.body.tag_id" @on-change="block.body.tag_id=$event"></ShortTextField>
                <ImagesWithTitleField :label="'Блок с изображениями'" :cards="block.body.slides" @on-change="block.body.slides=$event"></ImagesWithTitleField>
            </div>
            <div v-if="block.type=='cards'">
                <ShortTextField placeholder="Заголовок" label="Заголовок" name="title" :value="block.body.title" @on-change="block.body.title=$event"></ShortTextField>
                <ShortTextField placeholder="Боковой заголовок" label="Боковой заголовок" name="label" :value="block.body.label" @on-change="block.body.label=$event"></ShortTextField>
                <ShortTextField placeholder="Tag ID" label="Tag ID" name="tag_id" :value="block.body.tag_id" @on-change="block.body.tag_id=$event"></ShortTextField>
                <TitleSubtitleDescField :label="'Блок с карточками'" :cards="block.body.items" @on-change="block.body.items=$event"></TitleSubtitleDescField>
            </div>
            <div v-if="block.type=='benefits'">
                <ShortTextField placeholder="Боковой заголовок" label="Боковой заголовок" name="label" :value="block.body.label" @on-change="block.body.label=$event"></ShortTextField>
                <ShortTextField placeholder="Tag ID" label="Tag ID" name="tag_id" :value="block.body.tag_id" @on-change="block.body.tag_id=$event"></ShortTextField>
                <SwitchField label="isReversed" name="is_reversed" :value="block.body.isReversed" @on-change="block.body.isReversed=$event"></SwitchField>
                <ImagesWithTitleAndDescField :label="'Блок с карточками'" :cards="block.body.benefits" @on-change="block.body.benefits=$event"></ImagesWithTitleAndDescField>
            </div>
            <div v-if="block.type=='person'">
                <ShortTextField placeholder="Боковой заголовок" label="Боковой заголовок" name="label" :value="block.body.label" @on-change="block.body.label=$event"></ShortTextField>
                <ShortTextField placeholder="Tag ID" label="Tag ID" name="tag_id" :value="block.body.tag_id" @on-change="block.body.tag_id=$event"></ShortTextField>
                <LongTextField placeholder="Описание блока" label="Описание блока" name="description" :value="block.body.description" @on-change="block.body.description=$event"></LongTextField>
                <SwitchField label="isReversed" name="is_reversed" :value="block.body.isReversed" @on-change="block.body.isReversed=$event"></SwitchField>
                <SingleImageField :label="'Изображение'" :cards="block.body.image" @on-change="block.body.image=$event"></SingleImageField>
                <ShortTextField placeholder="Должность" label="Должность" name="position" :value="block.body.position" @on-change="block.body.position=$event"></ShortTextField>
                <ShortTextField placeholder="Имя" label="Имя" name="personName" :value="block.body.personName" @on-change="block.body.personName=$event"></ShortTextField>
                <SingleImageField :label="'Подпись'" :cards="block.body.sign" @on-change="block.body.sign=$event"></SingleImageField>
            </div>
            <div v-if="block.type=='video'">
                <ShortTextField placeholder="Боковой заголовок" label="Боковой заголовок" name="label" :value="block.body.label" @on-change="block.body.label=$event"></ShortTextField>
                <ShortTextField placeholder="Tag ID" label="Tag ID" name="tag_id" :value="block.body.tag_id" @on-change="block.body.tag_id=$event"></ShortTextField>
                <SingleImageField :label="'Картинка'" :cards="block.body.video.preview" @on-change="block.body.video.preview=$event"></SingleImageField>
                <ShortTextField placeholder="URL" label="URL" name="url" :value="block.body.video.url" @on-change="block.body.video.url=$event"></ShortTextField>
            </div>
            <div v-if="block.type=='interactive'">
                <ShortTextField placeholder="Боковой заголовок" label="Боковой заголовок" name="label" :value="block.body.label" @on-change="block.body.label=$event"></ShortTextField>
                <ShortTextField placeholder="Tag ID" label="Tag ID" name="tag_id" :value="block.body.tag_id" @on-change="block.body.tag_id=$event"></ShortTextField>
                <ImagesWithTitleAndDescField :label="'Блок с карточками'" :cards="block.body.items" @on-change="block.body.items=$event"></ImagesWithTitleAndDescField>
            </div>
            <div v-if="block.type=='numbers'">
                <ShortTextField placeholder="Боковой заголовок" label="Боковой заголовок" name="label" :value="block.body.label" @on-change="block.body.label=$event"></ShortTextField>
                <ShortTextField placeholder="Tag ID" label="Tag ID" name="tag_id" :value="block.body.tag_id" @on-change="block.body.tag_id=$event"></ShortTextField>
                <LongTextField placeholder="Описание блока" label="Описание блока" name="description" :value="block.body.description" @on-change="block.body.description=$event"></LongTextField>
                <SingleImageField :label="'Картинка'" :cards="block.body.image" @on-change="block.body.image=$event"></SingleImageField>
                <TitleAndDescForBlocksField :label="'Блок с карточками'" :cards="block.body.items" @on-change="block.body.items=$event"></TitleAndDescForBlocksField>
            </div>
            <div v-if="block.type=='title'">
                <ShortTextField placeholder="Заголовок" label="Заголовок" name="title" :value="block.body.title" @on-change="block.body.title=$event"></ShortTextField>
                <ShortTextField placeholder="Боковой заголовок" label="Боковой заголовок" name="label" :value="block.body.label" @on-change="block.body.label=$event"></ShortTextField>
                <ShortTextField placeholder="Tag ID" label="Tag ID" name="tag_id" :value="block.body.tag_id" @on-change="block.body.tag_id=$event"></ShortTextField>
                <LongTextField placeholder="Описание блока" label="Описание блока" name="description" :value="block.body.description" @on-change="block.body.description=$event"></LongTextField>
            </div>
            <div v-if="block.type=='button'">
                <ShortTextField placeholder="Боковой заголовок" label="Боковой заголовок" name="label" :value="block.body.label" @on-change="block.body.label=$event"></ShortTextField>
                <ShortTextField placeholder="Текст кнопки" label="Текст кнопки" name="text" :value="block.body.text" @on-change="block.body.text=$event"></ShortTextField>
                <ShortTextField placeholder="Название блока" label="Название блока" name="title" :value="block.body.title" @on-change="block.body.title=$event"></ShortTextField>
                <LongTextField placeholder="Описание блока" label="Описание блока" name="description" :value="block.body.description" @on-change="block.body.description=$event"></LongTextField>
                <ShortTextField placeholder="Tag ID" label="Tag ID" name="tag_id" :value="block.body.tag_id" @on-change="block.body.tag_id=$event"></ShortTextField>
                <SelectField class="selectfield" placeholder="Тип ссылки" label="Тип ссылки" name="type" :selected="block.body.type" :options="['modal', 'link']" @on-change="block.body.type=$event"></SelectField>
                <ShortTextField placeholder="Ссылка" label="Ссылка" name="url" :value="block.body.url" @on-change="block.body.url=$event"></ShortTextField>
            </div>
            <div v-if="block.type=='map'">
                <ShortTextField placeholder="Боковой заголовок" label="Боковой заголовок" name="label" :value="block.body.label" @on-change="block.body.label=$event"></ShortTextField>
                <ShortTextField placeholder="Tag ID" label="Tag ID" name="tag_id" :value="block.body.tag_id" @on-change="block.body.tag_id=$event"></ShortTextField>
                <ShortTextField placeholder="Масштаб" label="Масштаб" name="scale" :value="block.body.scale" @on-change="block.body.scale=$event"></ShortTextField>
                <MapField :label="'Блок с картой'" :cards="block.body.points" @on-change="block.body.points=$event"></MapField>
            </div>
            <div v-if="block.type=='singlemap'">
                <ShortTextField placeholder="Боковой заголовок" label="Боковой заголовок" name="label" :value="block.body.label" @on-change="block.body.label=$event"></ShortTextField>
                <ShortTextField placeholder="Масштаб" label="Масштаб" name="scale" :value="block.body.scale" @on-change="block.body.scale=$event"></ShortTextField>
                <SingleMapField :label="'Блок с картой'" :cards="block.body.points" @on-change="block.body.points=$event"></SingleMapField>
            </div>
            <div v-if="block.type=='payments'">
                <ShortTextField placeholder="Боковой заголовок" label="Боковой заголовок" name="label" :value="block.body.label" @on-change="block.body.label=$event"></ShortTextField>
                <TitleAndDescForBlocksField :label="'Блок с карточками'" :cards="block.body.items" @on-change="block.body.items=$event"></TitleAndDescForBlocksField>
                <p class="naming">Кнопка</p>
                <ShortTextField placeholder="Текст кнопки" label="Текст кнопки" name="text" :value="block.body.button.text" @on-change="block.body.button.text=$event"></ShortTextField>
                <SelectField class="selectfield" placeholder="Тип ссылки" label="Тип ссылки" name="type" :selected="block.body.button.type" :options="['modal', 'link']" @on-change="block.body.button.type=$event"></SelectField>
                <ShortTextField placeholder="Ссылка" label="Ссылка" name="url" :value="block.body.button.url" @on-change="block.body.button.url=$event"></ShortTextField>
            </div>
            <div v-if="block.type=='feature'">
                <ShortTextField placeholder="Боковой заголовок" label="Боковой заголовок" name="label" :value="block.body.label" @on-change="block.body.label=$event"></ShortTextField>
                <ShortTextField placeholder="Tag ID" label="Tag ID" name="tag_id" :value="block.body.tag_id" @on-change="block.body.tag_id=$event"></ShortTextField>
                <SingleImageField :label="'Картинка'" :cards="block.body.image" @on-change="block.body.image=$event"></SingleImageField>
                <LongTextField placeholder="Текст" label="Текст" name="description" :value="block.body.text" @on-change="block.body.text=$event"></LongTextField>
                <p class="naming">Кнопка</p>
                <ShortTextField placeholder="Текст кнопки" label="Текст кнопки" name="text" :value="block.body.button.text" @on-change="block.body.button.text=$event"></ShortTextField>
                <SelectField class="selectfield" placeholder="Тип ссылки" label="Тип ссылки" name="type" :selected="block.body.button.type" :options="['modal', 'link']" @on-change="block.body.button.type=$event"></SelectField>
                <ShortTextField placeholder="Ссылка" label="Ссылка" name="url" :value="block.body.button.url" @on-change="block.body.button.url=$event"></ShortTextField>
            </div>
            <div v-if="block.type=='text'">
                <ShortTextField placeholder="Боковой заголовок" label="Боковой заголовок" name="label" :value="block.body.label" @on-change="block.body.label=$event"></ShortTextField>
                <ShortTextField placeholder="Заголовок" label="Заголовок" name="title" :value="block.body.title" @on-change="block.body.title=$event"></ShortTextField>
                <ShortTextField placeholder="Tag ID" label="Tag ID" name="tag_id" :value="block.body.tag_id" @on-change="block.body.tag_id=$event"></ShortTextField>
                <LongTextField placeholder="Текст" label="Текст" name="title" :value="block.body.description" @on-change="block.body.description=$event"></LongTextField>
            </div>
            <div v-if="block.type=='event'">
                <DateField label="Дата" :value="block.body.month" @on-change="block.body.month=$event" placeholder="ДД.ММ.ГГГГ"></DateField>
                <LongTextField placeholder="Описание" label="Описание" name="description" :value="block.body.description" @on-change="block.body.description=$event"></LongTextField>
                <ImagesField :label="'Блок с изображениями'" :cards="block.body.images" @on-change="block.body.images=$event"></ImagesField>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .naming {
        font-size: 32px;
        margin-top: 25px;
        margin-bottom: 25px;
    }
.edit_icons {
    display: flex;
    gap: 12px;

}
.edit_icons > div {
    border-radius: 5px;
    border: 1px solid #DEDEDE;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.edit_icons > div:hover {
     border: 1px solid black;
}
    .project_header {
        height: 70px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background-color: white;
        margin-bottom: 20px;
        border-radius: 10px;
        align-items: center;
    }
    .content {
        display: flex;
        flex-direction: column;
        background-color: white;
        min-height: calc(100vh - 97px - 108px);
        border-radius: 10px;
        padding: 30px 20px 28px 20px;
    }
    .blockname_wrapper {
        display: flex;
        align-items: center;
    }

    .blockname_wrapper > .id_wrapper {
        font-size: 16px;
        color: #8A8A8A;
        padding: 10px 8px;
        border: 1px solid #8A8A8A;
        border-radius: 5px;
        margin-right: 20px;
        white-space: nowrap;
    }
    .project_header   p, .project_header span {
        font-size: 32px;
        width: 100% !important;
        margin-right: 20px;
        cursor: default;
    }
    .edit_icons {
        cursor: pointer;
        display: flex;
        
    }
    .project_header span:focus {
        outline: none !important;
        border-bottom: 1px solid black;
    }
    .blockname_wrapper > p {
        margin-right: 20px;
    }
    .blockname_wrapper > svg {
        cursor: pointer;
    }
    .new_project_btn {
        padding: 12px 20px;
        border: 1px solid black;
        border-radius: 80px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .new_project_btn svg {
        margin-right: 10px;
    }
    .new_project_btn:hover {
        background-color: #F2F2F2 !important;
        color: #4C4C4C !important;
        border: 1px solid #4C4C4C;
    }
    .new_project_btn:active {
        background-color: #E5E5E5 !important;
        color: #1A1A1A !important;
        border: 1px solid #1A1A1A;
    }
    .content {
        display: flex;
        flex-wrap: wrap; 
    }
</style>
<script>
import ShortTextField from '../fields/ShortTextField.vue'
import LongTextField from '../fields/LongTextField.vue'
import CardsField from '../fields/CardsField.vue'
import ImagesField from '../fields/ImagesField.vue'
import ImagesWithTitleField from '../fields/ImagesWithTitleField.vue'
import ImagesWithTitleAndDescField from '../fields/ImagesWithTitleAndDescField.vue'
import SingleImageField from '../fields/SingleImageField.vue'
import SingleMapField from '../fields/SingleMapField.vue'
import TitleAndDescForBlocksField from '../fields/TitleAndDescForBlocksField.vue'
import TitleSubtitleDescField from '../fields/TitleSubtitleDescField.vue'
import SelectField from '../fields/SelectField.vue'
import MapField from '../fields/MapField.vue'
import DateField from '../fields/DateField.vue'
import SwitchField from '../fields/SwitchField.vue'

export default {
  name: 'BlockEdit',
  data: () => ({
    block: undefined,
    name_edit: false,
    page_name: undefined,
    block_name: undefined,
    project_name: undefined,
    loading: false
  }),
  methods: {
    toggleNameEdit: function(){
        this.name_edit = !this.name_edit
        let input = document.querySelector('.titleinput')
        if(this.name_edit){
            input.setAttribute("contenteditable", "true")
            input.focus()
        }
        else {
            input.setAttribute("contenteditable", "false")
        }
        input.innerHTML = this.block.body.name
    },
    saveName: function() {
        let input = document.querySelector('.titleinput')
        this.block.body.name = input.innerHTML
        this.toggleNameEdit()
        fetch(`${process.env.VUE_APP_MYAPI}/blocks/${this.$route.params.block_id}`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer '+this.$cookies.get('token'),
            },
            body: JSON.stringify({
                "body":this.block.body
            })
        })
        .then(response=>{
            if(!response.ok) console.log(response)
            this.$parent.$emit('on-bread', 'BlockEditSales', undefined, this.$route.params.sales_id, this.$route.params.block_id)
            return response.json()
        })
    },
    getBlock: function(){
        fetch(`${process.env.VUE_APP_MYAPI}/blocks/${this.$route.params.block_id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+this.$cookies.get('token')
            }
        })
        .then(response=>{return response.json()})
        .then(data=>{this.block = data;})
    },
    publish: function(){
        if(!this.$cookies.get('token')){
            this.$router.push({name:'LoginForm'})
        }
        if(this.loading){
            return false
        }
        
        this.loading = true
        fetch(`${process.env.VUE_APP_MYAPI}/blocks/${this.$route.params.block_id}`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer '+this.$cookies.get('token'),
            },
            body: JSON.stringify({
                "body":this.block.body
            })
        })
        .then(response=>{
            if(!response.ok){
                console.log(response)
                this.$toast.clear();
                this.$toast.error("Изменения не опубликованы")
            }
                
            else {
                this.$router.push({name:'PageBlocksSales', params: {sales_id: this.$route.params.sales_id}})
                this.$toast.clear();
                this.$toast.success("Изменения успешно опубликованы")
            }
            this.loading = false
            return response.json()
        })
    }
  },
  mounted () {
    this.getBlock()
    this.$parent.$emit('on-bread', 'BlockEditSales', undefined, this.$route.params.sales_id, this.$route.params.block_id)
    },
  components: {
    ShortTextField,
    LongTextField,
    CardsField,
    ImagesField,
    ImagesWithTitleField,
    ImagesWithTitleAndDescField,
    SingleImageField,
    TitleAndDescForBlocksField,
    SelectField,
    MapField,
    DateField,
    SwitchField,
    SingleMapField,
    TitleSubtitleDescField
}
}
</script>
