<template>
<div class="mainWrapper">
<HeaderAag :path="path" :params="params"></HeaderAag>
<div class="navWrapper">
    <LeftNav :key="forceReloadLeft" @on-change="reloadRight" :newObj="newobj"></LeftNav>
    <RightNav :key="forceReloadRight" @on-change="(type, newObj)=>{
        newobj = {
            'type': type,
            'data': newObj
        }
    }" @on-bread="(name, project, page, block)=>{
        test(name, project, page, block)
    }"><router-view name="rightNav"></router-view></RightNav>
</div>
</div>
</template>
<style>

    body {
        background: #f7f7f7;
        font-family: 'manroperegular', Arial, Helvetica, sans-serif !important;
    }
    .mainWrapper {
        margin: 0 20px;
    }
    .navWrapper {
        display: flex;
    }
    .ProseMirror {
        min-height: 5rem !important;
        max-height: 5rem !important;
        
    }
    .ProseMirror *{
        font-size: 16px !important;
    }
</style>
<script>
import HeaderAag from './helpers/HeaderAag'
import LeftNav from './LeftNav'
import RightNav from './RightNav'


export default {
  name: 'HomeAAG',

  components: {
   HeaderAag, LeftNav, RightNav
  },
    data(){
        return{
            forceReloadLeft: 0,
            forceReloadRight: 1,
            newobj: undefined,
            path: undefined,
            params: []
        }
    },
    methods: {
        reloadRight(){
            this.forceReloadRight++
        },
        reloadLeft(){
            this.forceReloadLeft++
        },
        async test(name, project, page, block){
            if(!this.$cookies.get('token')){
                this.$router.push({name:'LoginForm'})
            }
            this.params = []
            if(project) this.params.push(project)
            if(page) this.params.push(page)
            if(block) this.params.push(block)
            let project_name
            let page_name
            let block_name
            
            if(project){
                await fetch(`${process.env.VUE_APP_MYAPI}/projects/${project}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer '+this.$cookies.get('token')
                }
            })
            .then(response=>{ return response.json()})
            .then(data=>{if(data) project_name = data.body.name;})
            }
           if(page){
            
            await fetch(`${process.env.VUE_APP_MYAPI}/pages/${page}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer '+this.$cookies.get('token')
                }
            })
            .then(response=>{ return response.json()})
            .then(data=>{if(data) page_name = data.body.name;})
           }
            
            if(block){
                await fetch(`${process.env.VUE_APP_MYAPI}/blocks/${block}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer '+this.$cookies.get('token')
                }
            })
            .then(response=>{ return response.json()})
            .then(data=>{
                if(data){
                    if(data.body.name) block_name = data.body.name
                    else block_name = data.body.month
                } 
            })
            }
            let stack = {
                name: 'Main',
                parent: null,
                url: 'Main',
                title: 'Главная',
                childs: [
                    {
                        name: 'ProjectList',
                        parent: 'Main',
                        url: 'ProjectList',
                        title: 'Проекты',
                        childs: [
                            {
                                name: 'ProjectPages',
                                parent: 'ProjectList',
                                url: `ProjectPages`,
                                title: project_name,
                                childs: [
                                        {
                                            name: 'ProjectSettings',
                                            parent: 'ProjectPages',
                                            url: 'ProjectSettings',
                                            title: 'Настройки проекта',
                                            childs: []   
                                        },
                                        {
                                            name: 'NewPage',
                                            parent: 'ProjectPages',
                                            url: 'NewPage',
                                            title: 'Новая страница',
                                            childs: []   
                                        },
                                        {
                                            name: 'PageBlocks',
                                            parent: 'ProjectPages',
                                            url: `PageBlocks`,
                                            title: page_name,
                                            childs: [
                                                {
                                                    name: 'PageSettings',
                                                    parent: 'PageBlocks',
                                                    url: 'PageSettings',
                                                    title: 'Настройки страницы',
                                                    childs: []   
                                                },
                                                {
                                                    name: 'NewBlock',
                                                    parent: 'PageBlocks',
                                                    url: 'NewBlock',
                                                    title: 'Новый блок',
                                                    childs: []   
                                                },
                                                {
                                                    name: 'BlockEdit',
                                                    parent: 'PageBlocks',
                                                    url: `BlockEdit`,
                                                    title: block_name,
                                                    childs: []   
                                                },
                                            ]   
                                        },
                                ]   
                            },
                            {
                                name: 'NewProject',
                                parent: 'ProjectList',
                                url: 'Новый проект',
                                title: 'Новый проект',
                                childs: []   
                            }
                        ]   
                    },
                    {
                        name: 'SiteSettings',
                        parent: 'Main',
                        url: 'SiteSettings',
                        title: 'Настройки сайта',
                        childs: []   
                    },
                    {
                        name: 'StaticPages',
                        parent: 'Main',
                        url: 'StaticPages',
                        title: 'Страницы',
                        childs: [
                                {
                                    name: 'NewPageStatic',
                                    parent: 'StaticPages',
                                    url: 'NewPageStatic',
                                    title: 'Новая страница',
                                    childs: []   
                                },
                                {
                                    name: 'PageBlocksStatic',
                                    parent: 'StaticPages',
                                    url: `PageBlocksStatic`,
                                    title: page_name,
                                    childs: [
                                        {
                                            name: 'PageSettingsStatic',
                                            parent: 'PageBlocksStatic',
                                            url: 'PageSettingsStatic',
                                            title: 'Настройки страницы',
                                            childs: []   
                                        },
                                        {
                                            name: 'NewBlockStatic',
                                            parent: 'PageBlocksStatic',
                                            url: 'NewBlockStatic',
                                            title: 'Новый блок',
                                            childs: []   
                                        },
                                        {
                                            name: 'BlockEditStatic',
                                            parent: 'PageBlocksStatic',
                                            url: `BlockEditStatic`,
                                            title: block_name,
                                            childs: []   
                                        },
                                    ]   
                                },
                            ]
                    },
                    {
                        name: 'SalesPages',
                        parent: 'Main',
                        url: 'SalesPages',
                        title: 'Условия продаж',
                        childs: [
                                {
                                    name: 'NewPageSales',
                                    parent: 'SalesPages',
                                    url: 'NewPageSales',
                                    title: 'Новая страница',
                                    childs: []   
                                },
                                {
                                    name: 'PageBlocksSales',
                                    parent: 'SalesPages',
                                    url: `PageBlocksSales`,
                                    title: page_name,
                                    childs: [
                                        {
                                            name: 'PageSettingsSales',
                                            parent: 'PageBlocksSales',
                                            url: 'PageSettingsSales',
                                            title: 'Настройки страницы',
                                            childs: []   
                                        },
                                        {
                                            name: 'NewBlockSales',
                                            parent: 'PageBlocksSales',
                                            url: 'NewBlockSales',
                                            title: 'Новый блок',
                                            childs: []   
                                        },
                                        {
                                            name: 'BlockEditSales',
                                            parent: 'PageBlocksStatic',
                                            url: `BlockEditSales`,
                                            title: block_name,
                                            childs: []   
                                        },
                                    ]   
                                },
                            ]
                    }
                ]
            }
            let path = []
            let a
            const find = (obj)=>{
                if(obj.name==name){
                    return obj.name
                }
                let arr = obj.childs
                for(let i=0;i<arr.length;i++){
                    if((a = find(arr[i]))){
                        path.push({
                            title: arr[i].title,
                            url: arr[i].url
                        })
                        return arr[i].parent+'/'+a
                    }
                }   
            }
            find(stack)
            path.reverse()
            path = [{
                url: 'Main',
                title: 'Главная'
            }].concat(path)
            this.path = path
            let i=0
            while(i<path.length){
                if(!path[i].title){
                    path.splice(i,1)
                }
                else {
                    i++
                }
            }
    }
   
    }
    ,
    mounted(){
        window.addEventListener('click', ()=>{
            let selects = document.querySelectorAll('.options')
            let to_close = document.querySelectorAll('.to_close')
            let to_open = document.querySelectorAll('.to_open')
            for(let i=0;i<selects.length;i++){
                if(to_close.length>0 && to_close[i]){
                        to_close[i].style.display = 'none'
                    }
                    if(to_open.length>0 && to_open[i]){
                        to_open[i].style.display = 'block'
                    }
                if(window.getComputedStyle(selects[i]).display=='block'){
                    selects[i].style.display = 'none'
                    
                    
                }
            }
        })
    },

}
</script>