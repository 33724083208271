var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inputblock"},[_c('label',{attrs:{"for":_vm.name}},[_vm._v(_vm._s(_vm.label))]),_c('editor',{attrs:{"api-key":"cza72uhx1sxci6zrvaapidzpf8tza2koiqwox7fvqtrqka1x","init":{
                plugins: 'link code lists',
                statusbar: false,
                menubar: false,
                toolbar: 'h1 h2 h3 | bold italic strikethrough underline | bullist numlist | link blockquote code',
                toolbar_location: 'bottom',
                width: '50%'
            }},on:{"input":_vm.change},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),_c('div',{staticClass:"typo",on:{"click":_vm.typo}},[_c('svg',{attrs:{"width":"12","height":"12","viewBox":"0 0 12 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5.99999 12H8.53843V11.1724C8.53843 11.0582 8.43512 10.9655 8.30765 10.9655H7.61537C7.23302 10.9655 6.92305 10.6876 6.92305 10.3448V1.44827C6.92305 1.33402 7.02637 1.24137 7.15383 1.24137H9.92306C10.8125 1.24137 11.1533 2.06276 11.2629 2.34455C11.295 2.42749 11.3823 2.48302 11.4803 2.48276H11.7692C11.8967 2.48276 12 2.39013 12 2.27585V0.206906C12 0.0926484 11.8967 0 11.7692 0H0.23078C0.103312 0 0 0.092625 0 0.206906V2.27587C0 2.39013 0.103312 2.48278 0.23078 2.48278H0.519699C0.617652 2.48304 0.704966 2.42752 0.737094 2.34457C0.846707 2.06278 1.18754 1.24139 2.07694 1.24139H4.84617C4.97361 1.24139 5.07695 1.33402 5.07695 1.4483V10.3448C5.07695 10.6876 4.76698 10.9655 4.38463 10.9655H3.69232C3.56488 10.9655 3.46154 11.0582 3.46154 11.1724V12H5.99999Z","fill":"#1A1A1A"}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }