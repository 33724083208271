<template>
    <div class="inputblock" v-if="label">
        <label :for="name">{{label}}</label>
        <div class="input notonly">
            <div class="selected_option" @click="$event.stopPropagation(); toggle()">
                {{ 
                    Array.isArray(options) ? 
                    (options.find(option => option.value == selected) || {}).label || selected :
                    selected
                }}
                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg" class="to_open">
                    <path d="M5.1964 4.82992C5.08366 4.92754 4.91634 4.92754 4.8036 4.82992L0.412121 1.02678C0.202149 0.844939 0.33075 0.5 0.608516 0.5L9.39148 0.5C9.66925 0.5 9.79785 0.844938 9.58788 1.02678L5.1964 4.82992Z" fill="black"/>
                </svg>
                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg" class="to_close">
                    <path d="M4.8036 0.170084C4.91634 0.0724553 5.08366 0.0724556 5.1964 0.170084L9.58788 3.97322C9.79785 4.15506 9.66925 4.5 9.39148 4.5H0.608515C0.33075 4.5 0.202148 4.15506 0.41212 3.97322L4.8036 0.170084Z" fill="black"/>
                </svg>


            </div>
            <div class="options">
                <p v-for="(option, index) in options" :key="index" @click="change(option.value ? option.value : option)">{{option.label ? option.label : option}}</p>
            </div>
        </div>
    </div>
    <div class="input only" @click="$event.stopPropagation(); toggle()" v-else>
        <div class="selected_option">
            {{selected}}
            <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg" class="to_open">
                <path d="M5.1964 4.82992C5.08366 4.92754 4.91634 4.92754 4.8036 4.82992L0.412121 1.02678C0.202149 0.844939 0.33075 0.5 0.608516 0.5L9.39148 0.5C9.66925 0.5 9.79785 0.844938 9.58788 1.02678L5.1964 4.82992Z" fill="black"/>
            </svg>
            <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg" class="to_close">
                <path d="M4.8036 0.170084C4.91634 0.0724553 5.08366 0.0724556 5.1964 0.170084L9.58788 3.97322C9.79785 4.15506 9.66925 4.5 9.39148 4.5H0.608515C0.33075 4.5 0.202148 4.15506 0.41212 3.97322L4.8036 0.170084Z" fill="black"/>
            </svg>

        </div>
        <div class="options">
            <p v-for="(option, index) in options" :key="index" @click="change(option.value ? option.value : option)">{{option.label ? option.label : option}}</p>
        </div>
    </div>
    
</template>
<style scoped>
.to_close {
    display: none;
}
.to_open {
    display: block;
}
.invisible_close {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: default !important;
    z-index: 100;
}
    .input {
        position: relative;
        cursor: pointer;
        height: 40px;
    }
    .input.notonly{
        width: 50%;
    }
    .input.only {
        flex: 1;
    }
    .inputblock {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    .inputblock > label {
        margin-right: 20px;
        font-size: 20px;
    }
    .options {
        position: absolute;
        border: 1px solid #DEDEDE;
        border-top: none;
        width: 100%;
        max-height: 450px;
        overflow-y: auto;
        background-color: white;
        left: 0;
        top: 43px;
        z-index: 101;
        display: none;
        
    }
    .selected_option {
        border: 1px solid #DEDEDE;
        padding: 12px 14px;
        cursor: pointer;
        border-radius: 4px;
        width: 100%;
        min-height: 40px;
        display: flex;
        align-items: center;
    }
    .selected_option > svg {
        margin-left: auto;
        
    }
    .options > p {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-left: 14px;
        height: 40px;
        font-size: 16px;
        margin-right: 0 !important;
    }
    .options > p:hover {
        background-color: #F2F2F2;
    }
</style>
<script>
export default { 
  model: {
    prop: "value",
    event: "on-change"
  },
  data: ()=>({
    opened: 'none',
  }),
    methods: {
        close: function(){
            this.opened = false
        },
        toggle: function(){
            let selects = document.querySelectorAll('.options')
            for(let i=0;i<selects.length;i++){
                if(selects[i]!=this.$el.querySelector('.options')){
                    selects[i].style.display = 'none'
                }
            }
            let to_close = document.querySelectorAll('.to_close')
            for(let i=0;i<to_close.length;i++){
                if(to_close[i]!=this.$el.querySelector('.to_close')){
                    to_close[i].style.display = 'none'
                }
            }
            let to_open = document.querySelectorAll('.to_open')
            for(let i=0;i<to_open.length;i++){
                if(to_open[i]!=this.$el.querySelector('.to_open')){
                    to_open[i].style.display = 'block'
                }
            }
            let opened = window.getComputedStyle(this.$el.querySelector('.options')).display
            
            if(opened=='none'){
                this.$el.querySelector('.options').style.display = 'block'
                this.$el.querySelector('.to_open').style.display = 'none'
                this.$el.querySelector('.to_close').style.display = 'block'
            }
            else {
                this.$el.querySelector('.options').style.display = 'none'
                this.$el.querySelector('.to_open').style.display = 'block'
                this.$el.querySelector('.to_close').style.display = 'none'
            }
            this.opened = window.getComputedStyle(this.$el.querySelector('.options')).display
        },
        change: function(option){
            event.stopPropagation();
            this.$emit("on-change", option)
            this.toggle()
        },
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
        },
        options: {
            type: Array,
            required: true,
        },
        selected: {
            type: String,
            required: true,
        },
    },
   

}
</script>