<template>
    <div class="leftNav">
        <ul v-if="projects" class="mainnav">
            <li class="mainnav_main">
                <div class="nav-content" @click="myRedirect('Main')">
                        <div class="icon">
                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.7936 6.37627L6.78108 0.612993C6.62408 0.462336 6.37592 0.462336 6.21892 0.612993L0.200447 6.38222C0.0730972 6.50957 0 6.68502 0 6.86434C0 7.23757 0.303894 7.54146 0.677116 7.54146H1.625V12.6872C1.625 13.1357 1.989 13.4997 2.4375 13.4997H4.73962C4.96387 13.4997 5.14587 13.3177 5.14587 13.0935V9.57271C5.14587 9.49842 5.20647 9.43723 5.28125 9.43723H7.71875C7.79294 9.43723 7.85413 9.49842 7.85413 9.57271V13.0935C7.85413 13.3177 8.03613 13.4997 8.26038 13.4997H10.5625C11.011 13.4997 11.375 13.1357 11.375 12.6872V7.54146H12.3229C12.6961 7.54146 13 7.23757 13 6.86434C13 6.68502 12.9269 6.50957 12.7936 6.37627Z" fill="black"/>
                            </svg>
                        </div>
                        <div class="text">
                            Главная страница
                        </div>
                    
                </div>
            </li>
            <li class="mainnav_projects" >
                <div :class="'nav-content'" @click="nav_projects=!nav_projects">
                    <div class="icon">
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2483 1.67679H3.54293C3.33521 1.67679 3.16682 1.50839 3.16682 1.30067V1.11248C3.16682 0.900695 2.99513 0.729004 2.78335 0.729004H0.766949C0.343383 0.729004 0 1.07236 0 1.49595V7.94931L0.984064 3.16173C1.02002 2.98684 1.17394 2.86133 1.35248 2.86133H11.0153V2.44373C11.0153 2.02014 10.6719 1.67679 10.2483 1.67679Z" fill="black"/>
                            <path d="M12.2326 3.61377H1.65877L0.178831 10.8137C0.130284 11.0499 0.31071 11.2713 0.551896 11.2713H11.5551C11.946 11.2713 12.2744 10.9772 12.3174 10.5886L12.9949 4.46507C13.0451 4.01085 12.6895 3.61377 12.2326 3.61377Z" fill="black"/>
                        </svg>
                    </div>
                    <div class="text" @click="$event.stopPropagation();myRedirect('ProjectList')">
                        Проекты
                    </div>
                    <div class="after" @click="$event.stopPropagation();myRedirect('NewProject')">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.5938 9.375H10.625V6.40625C10.625 6.32031 10.5547 6.25 10.4688 6.25H9.53125C9.44531 6.25 9.375 6.32031 9.375 6.40625V9.375H6.40625C6.32031 9.375 6.25 9.44531 6.25 9.53125V10.4688C6.25 10.5547 6.32031 10.625 6.40625 10.625H9.375V13.5938C9.375 13.6797 9.44531 13.75 9.53125 13.75H10.4688C10.5547 13.75 10.625 13.6797 10.625 13.5938V10.625H13.5938C13.6797 10.625 13.75 10.5547 13.75 10.4688V9.53125C13.75 9.44531 13.6797 9.375 13.5938 9.375Z" fill="black"/>
                            <path d="M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM10 17.2656C5.98828 17.2656 2.73438 14.0117 2.73438 10C2.73438 5.98828 5.98828 2.73438 10 2.73438C14.0117 2.73438 17.2656 5.98828 17.2656 10C17.2656 14.0117 14.0117 17.2656 10 17.2656Z" fill="black"/>
                        </svg>
                    </div>
                </div>

                <ul class="wrap-nav nav_projects" v-if="nav_projects">
                    <li v-for="(project, index) in projects" :key="project.id">
                        <div class="nav-content" @click="nav_project_list[index]=!nav_project_list[index]; updateListener()">
                            <div class="icon" v-if="!nav_project_list[index]" :key="test">
                                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.1964 4.82992C5.08366 4.92754 4.91634 4.92754 4.8036 4.82992L0.412121 1.02678C0.202149 0.844939 0.33075 0.5 0.608516 0.5L9.39148 0.5C9.66925 0.5 9.79785 0.844938 9.58788 1.02678L5.1964 4.82992Z" fill="black"/>
                                </svg>
                            </div>
                            <div class="icon" v-if="nav_project_list[index]">
                                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.8036 0.170084C4.91634 0.0724553 5.08366 0.0724556 5.1964 0.170084L9.58788 3.97322C9.79785 4.15506 9.66925 4.5 9.39148 4.5H0.608515C0.33075 4.5 0.202148 4.15506 0.41212 3.97322L4.8036 0.170084Z" fill="black"/>
                                </svg>
                            </div>
                            <div class="text" @click="$event.stopPropagation();myRedirect('ProjectPages', {project_id: project.id})">
                                {{project.body.name}}
                            </div>
                        </div>
                        <ul class="wrap-nav nav_pages" v-if="nav_project_list[index]">
                            <li v-for="page in project.pages" :key="page.id">
                                <div class="nav-content" @click="myRedirect('PageBlocks', {project_id: project.id, page_id: page.id})" v-if="page['type']!='feature' && page['type']!='local_features' && page['type']!='flat' && page['type']!='project_features'">
                                    <div class="text">
                                        {{page.body.name}}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>

            </li>
            <!--
            <li class="mainnav_zayavki">
                <div class="nav-content">
                    <div class="icon">
                        <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1875 2.15625V7.84375C12.1875 8.16698 12.0591 8.47698 11.8305 8.70554C11.602 8.9341 11.292 9.0625 10.9688 9.0625H2.03125C1.70802 9.0625 1.39802 8.9341 1.16946 8.70554C0.940904 8.47698 0.8125 8.16698 0.8125 7.84375V2.15625C0.813818 2.01732 0.839958 1.87974 0.889688 1.75L5.82562 5.04063C6.02548 5.17339 6.26007 5.24421 6.5 5.24421C6.73993 5.24421 6.97452 5.17339 7.17438 5.04063L12.1103 1.75C12.16 1.87974 12.1862 2.01732 12.1875 2.15625ZM6.72344 4.36219L11.5984 1.11219C11.4084 0.997724 11.1906 0.937324 10.9688 0.9375H2.03125C1.81036 0.939287 1.59411 1.00107 1.40562 1.11625L6.28062 4.36625C6.34692 4.40848 6.42404 4.43058 6.50263 4.42986C6.58123 4.42914 6.65793 4.40563 6.72344 4.36219Z" fill="black"/>
                        </svg>
                    </div>
                    <div class="text">
                        Заявки
                    </div>
                </div>
            </li>
            -->
            <li class="mainnav_settings">
                <div class="nav-content" @click="myRedirect('SiteSettings')">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                            <path d="M12.2866 5.58842L11.2683 5.45896C11.1843 5.20058 11.0809 4.95142 10.9601 4.71471L11.589 3.90492C11.8435 3.57721 11.8138 3.11463 11.5229 2.83296L10.6698 1.97983C10.3854 1.68625 9.92279 1.657 9.59454 1.91104L8.78583 2.53992C8.54912 2.41913 8.29996 2.31567 8.04104 2.23171L7.91158 1.215C7.86283 0.807125 7.51671 0.5 7.10667 0.5H5.89333C5.48329 0.5 5.13717 0.807125 5.08842 1.21338L4.95896 2.23171C4.70004 2.31567 4.45088 2.41858 4.21417 2.53992L3.40492 1.91104C3.07775 1.657 2.61517 1.68625 2.33296 1.97713L1.47983 2.82971C1.18625 3.11463 1.15646 3.57721 1.41104 3.90546L2.03992 4.71471C1.91858 4.95142 1.81567 5.20058 1.73171 5.45896L0.715 5.58842C0.307125 5.63717 0 5.98329 0 6.39333V7.60667C0 8.01671 0.307125 8.36283 0.713375 8.41158L1.73171 8.54104C1.81567 8.79942 1.91913 9.04858 2.03992 9.28529L1.41104 10.0951C1.15646 10.4228 1.18625 10.8854 1.47713 11.167L2.33025 12.0202C2.61517 12.3132 3.07721 12.3425 3.40546 12.0884L4.21471 11.4595C4.45142 11.5809 4.70058 11.6843 4.95896 11.7678L5.08842 12.7839C5.13717 13.1929 5.48329 13.5 5.89333 13.5H7.10667C7.51671 13.5 7.86283 13.1929 7.91158 12.7866L8.04104 11.7683C8.29942 11.6843 8.54858 11.5809 8.78529 11.4601L9.59508 12.089C9.92279 12.3435 10.3854 12.3138 10.667 12.0229L11.5202 11.1698C11.8138 10.8848 11.8435 10.4228 11.589 10.0945L10.9601 9.28529C11.0814 9.04858 11.1849 8.79942 11.2683 8.54104L12.2845 8.41158C12.6923 8.36283 12.9995 8.01671 12.9995 7.60667V6.39333C13 5.98329 12.6929 5.63717 12.2866 5.58842V5.58842ZM6.5 9.70833C5.00663 9.70833 3.79167 8.49338 3.79167 7C3.79167 5.50663 5.00663 4.29167 6.5 4.29167C7.99338 4.29167 9.20833 5.50663 9.20833 7C9.20833 8.49338 7.99338 9.70833 6.5 9.70833Z" fill="black"/>
                        </svg>
                    </div>
                    <div class="text">
                        Настройки
                    </div>
                </div>
            </li>
            <li class="mainnav_settings">
                <div class="nav-content" @click="myRedirect('StaticPages')">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                            <path d="M12.2866 5.58842L11.2683 5.45896C11.1843 5.20058 11.0809 4.95142 10.9601 4.71471L11.589 3.90492C11.8435 3.57721 11.8138 3.11463 11.5229 2.83296L10.6698 1.97983C10.3854 1.68625 9.92279 1.657 9.59454 1.91104L8.78583 2.53992C8.54912 2.41913 8.29996 2.31567 8.04104 2.23171L7.91158 1.215C7.86283 0.807125 7.51671 0.5 7.10667 0.5H5.89333C5.48329 0.5 5.13717 0.807125 5.08842 1.21338L4.95896 2.23171C4.70004 2.31567 4.45088 2.41858 4.21417 2.53992L3.40492 1.91104C3.07775 1.657 2.61517 1.68625 2.33296 1.97713L1.47983 2.82971C1.18625 3.11463 1.15646 3.57721 1.41104 3.90546L2.03992 4.71471C1.91858 4.95142 1.81567 5.20058 1.73171 5.45896L0.715 5.58842C0.307125 5.63717 0 5.98329 0 6.39333V7.60667C0 8.01671 0.307125 8.36283 0.713375 8.41158L1.73171 8.54104C1.81567 8.79942 1.91913 9.04858 2.03992 9.28529L1.41104 10.0951C1.15646 10.4228 1.18625 10.8854 1.47713 11.167L2.33025 12.0202C2.61517 12.3132 3.07721 12.3425 3.40546 12.0884L4.21471 11.4595C4.45142 11.5809 4.70058 11.6843 4.95896 11.7678L5.08842 12.7839C5.13717 13.1929 5.48329 13.5 5.89333 13.5H7.10667C7.51671 13.5 7.86283 13.1929 7.91158 12.7866L8.04104 11.7683C8.29942 11.6843 8.54858 11.5809 8.78529 11.4601L9.59508 12.089C9.92279 12.3435 10.3854 12.3138 10.667 12.0229L11.5202 11.1698C11.8138 10.8848 11.8435 10.4228 11.589 10.0945L10.9601 9.28529C11.0814 9.04858 11.1849 8.79942 11.2683 8.54104L12.2845 8.41158C12.6923 8.36283 12.9995 8.01671 12.9995 7.60667V6.39333C13 5.98329 12.6929 5.63717 12.2866 5.58842V5.58842ZM6.5 9.70833C5.00663 9.70833 3.79167 8.49338 3.79167 7C3.79167 5.50663 5.00663 4.29167 6.5 4.29167C7.99338 4.29167 9.20833 5.50663 9.20833 7C9.20833 8.49338 7.99338 9.70833 6.5 9.70833Z" fill="black"/>
                        </svg>
                    </div>
                    <div class="text">
                        Страницы
                    </div>
                </div>
            </li>
            <li class="mainnav_settings">
                <div class="nav-content" @click="myRedirect('SalesPages')">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                            <path d="M12.2866 5.58842L11.2683 5.45896C11.1843 5.20058 11.0809 4.95142 10.9601 4.71471L11.589 3.90492C11.8435 3.57721 11.8138 3.11463 11.5229 2.83296L10.6698 1.97983C10.3854 1.68625 9.92279 1.657 9.59454 1.91104L8.78583 2.53992C8.54912 2.41913 8.29996 2.31567 8.04104 2.23171L7.91158 1.215C7.86283 0.807125 7.51671 0.5 7.10667 0.5H5.89333C5.48329 0.5 5.13717 0.807125 5.08842 1.21338L4.95896 2.23171C4.70004 2.31567 4.45088 2.41858 4.21417 2.53992L3.40492 1.91104C3.07775 1.657 2.61517 1.68625 2.33296 1.97713L1.47983 2.82971C1.18625 3.11463 1.15646 3.57721 1.41104 3.90546L2.03992 4.71471C1.91858 4.95142 1.81567 5.20058 1.73171 5.45896L0.715 5.58842C0.307125 5.63717 0 5.98329 0 6.39333V7.60667C0 8.01671 0.307125 8.36283 0.713375 8.41158L1.73171 8.54104C1.81567 8.79942 1.91913 9.04858 2.03992 9.28529L1.41104 10.0951C1.15646 10.4228 1.18625 10.8854 1.47713 11.167L2.33025 12.0202C2.61517 12.3132 3.07721 12.3425 3.40546 12.0884L4.21471 11.4595C4.45142 11.5809 4.70058 11.6843 4.95896 11.7678L5.08842 12.7839C5.13717 13.1929 5.48329 13.5 5.89333 13.5H7.10667C7.51671 13.5 7.86283 13.1929 7.91158 12.7866L8.04104 11.7683C8.29942 11.6843 8.54858 11.5809 8.78529 11.4601L9.59508 12.089C9.92279 12.3435 10.3854 12.3138 10.667 12.0229L11.5202 11.1698C11.8138 10.8848 11.8435 10.4228 11.589 10.0945L10.9601 9.28529C11.0814 9.04858 11.1849 8.79942 11.2683 8.54104L12.2845 8.41158C12.6923 8.36283 12.9995 8.01671 12.9995 7.60667V6.39333C13 5.98329 12.6929 5.63717 12.2866 5.58842V5.58842ZM6.5 9.70833C5.00663 9.70833 3.79167 8.49338 3.79167 7C3.79167 5.50663 5.00663 4.29167 6.5 4.29167C7.99338 4.29167 9.20833 5.50663 9.20833 7C9.20833 8.49338 7.99338 9.70833 6.5 9.70833Z" fill="black"/>
                        </svg>
                    </div>
                    <div class="text">
                        Условия продаж
                    </div>
                </div>
            </li>
        </ul>
    </div>

</template>
<style>
    .leftNav {
        background-color: white;
        border-radius: 10px 10px 0px 0px;
        width: 217px;
        min-height: calc(100vh - 88px);
        margin-right: 20px;
        padding: 20px 10px;
        font-size: 14px;
    }
    .leftNav li {
        list-style: none;
    }
    .nav-content{
        border-radius: 4px;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }
    .nav-content:hover .icon {
        opacity: 0.5;
    }
    .nav-content:hover{
        background-color: #F7F7F7;
    }
    .nav-content{
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;
        padding: 11px 6px;
    }

    li > .after {
        margin-left: auto;
    }
    li > .icon {
        opacity: 0.5;
    }
    .mainnav {
        position:relative;
    }
    .wrap-nav .nav-content {
        padding-left: 28px !important;
    }
    .wrap-nav .wrap-nav .nav-content {
        padding-left: 56px !important;
    }
</style>
<script>
export default {
    name: 'LeftNav',
    data: ()=>({
        projects: undefined,
        nav_projects: false,
        nav_project_list: [],
        test: 0,
        myhost: false,
    }),
    props: {
        newObj: {
            type: Object || undefined,
            required: false
        }
    },
    watch: {
        newObj(){
            this.getProjects()
        }
    },
    methods: {
        getProjects: function(){
            let prev_tester = false
            for(let i =0;i<this.nav_project_list.length;i++){
                if(this.nav_project_list[i]){
                    prev_tester = true
                    break
                }
            }
            fetch(`${process.env.VUE_APP_MYAPI}/projects`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer '+this.$cookies.get('token')
                }
            })
            .then(response=>{return response.json()})
            .then(data=>{this.projects = data;
            for(let i=0;i<this.projects.length;i++){
                this.projects[i].pages.sort(function(a,b) {
                    return a.order- b.order
                });
            }
            if(!prev_tester){
                this.nav_project_list = []
                for(let i=0;i<this.projects.length;i++){
                    this.nav_project_list.push(false)
                }
            }
            })
        },
        updateListener: function(){
            this.test++
        },
        myRedirect: function(name, params={}){
            this.$router.push({name:name, params: params})
            this.$emit('on-change')
        }
    },
    mounted () {
    this.myhost = process.env.VUE_APP_MYHOST
    this.getProjects()
  }
}
</script>