<template>
    <div>
        <div class="project_header">
            <p>Новая страница</p>
            <div class="new_project_btn" @click="newPage">Создать страницу</div>
        </div>
        <div class="content">
            <ShortTextField placeholder="Название страницы" label="Название страницы" name="title" :value="title" @on-change="title=$event"></ShortTextField>
            <!--<ShortTextField placeholder="Slug" label="Slug" name="slug" :value="slug" @on-change="slug=$event"></ShortTextField>-->
            <SelectField placeholder="Тип страницы" label="Тип страницы" name="type" :selected="type" :options="options" @on-change="type=$event"></SelectField>
        </div>
    </div>
</template>
<style scoped>
    .project_header {
        height: 70px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background-color: white;
        margin-bottom: 20px;
        border-radius: 10px;
        align-items: center;
    }
    .project_header  > p {
        font-size: 32px;
    }
    .new_project_btn {
        padding: 12px 20px;
        border: 1px solid black;
        border-radius: 80px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .new_project_btn svg {
        margin-right: 10px;
    }
    .new_project_btn:hover {
        background-color: #F2F2F2 !important;
        color: #4C4C4C !important;
        border: 1px solid #4C4C4C;
    }
    .new_project_btn:active {
        background-color: #E5E5E5 !important;
        color: #1A1A1A !important;
        border: 1px solid #1A1A1A;
    }
    .content {
        display: flex;
        flex-direction: column;
        background-color: white;
        min-height: calc(100vh - 97px - 108px);
        border-radius: 10px;
        padding: 30px 20px 28px 20px;
    }

</style>
<script>
import ShortTextField from '../fields/ShortTextField.vue'
import SelectField from '../fields/SelectField.vue'
export default {
  name: 'NewPage',
  data: () => ({
    title: "",
    slug: "",
    order: 0,
    //options: ['about','construction-progress'],
    options: [
        {value: 'about', label: 'О проекте'},
        {value: 'construction-progress', label: 'Ход строительства'},
    ],
    type: 'about',
  }),
  methods: {
    newPage: function(){
        if(!this.$cookies.get('token')){
            this.$router.push({name:'LoginForm'})
        }
        if(this.title.trim()=="") {
            this.$toast.clear();
            this.$toast.error("Заполните Название страницы")
            return
        }
        let bodyByTitle = null
        if(this.type == 'about'){
            bodyByTitle = {
                "body": {
                    "name": this.title,
                    "slug": this.type
                },
                "type": this.type,
                "blocks": [],
                "active": false,
                "order": this.order
            }
        }
        else if(this.type == 'construction-progress' || this.type == 'progress'){
            bodyByTitle = {
                "type": this.type,
                "body": {
                    "stream": "",
                    "items": [],
                    "name": this.title,
                    "slug": this.type
                },
                "active": false,
                "order": this.order
            }
        }
        fetch(`${process.env.VUE_APP_MYAPI}/pages`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer '+this.$cookies.get('token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bodyByTitle)
        })
        .then(response=>{
            if(!response.ok){
                console.log(response)
            } 
            return response.json()
        })
        .then(data=>{
            let new_page = data
            fetch(`${process.env.VUE_APP_MYAPI}/projects/${this.$route.params.project_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer '+this.$cookies.get('token'),
                }
            })
            .then(response=>{
                if(!response.ok){
                    console.log(response)
                } 
                return response.json()
            })
            .then(data=>{
                let current_pages = data.pages
                current_pages.push(new_page)
                fetch(`${process.env.VUE_APP_MYAPI}/projects/${this.$route.params.project_id}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': 'Bearer '+this.$cookies.get('token'),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "pages": current_pages
                    })
                })
                .then(response=>{
                    if(!response.ok){
                        console.log(response)
                        this.$toast.clear();
                        this.$toast.error("Страница не создана")
                    } 
                    else {
                        this.$toast.clear();
                        this.$toast.success("Страница успешно создана")
                        this.$router.push({name:'PageBlocks', params: {project_id: this.$route.params.project_id, page_id: new_page.id}})
                        this.$parent.$emit('on-change', 'page', this.newPage)
                    }
                    return response.json()
                })
            })      
        })
    },
    getOrder: function(){
        fetch(`${process.env.VUE_APP_MYAPI}/projects/${this.$route.params.project_id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+this.$cookies.get('token'),
            }
        })
        .then(response=>{
            if(!response.ok){
                console.log(response.json())
            }
            return response.json()
        })
        .then(data=>{
            this.order = data.pages.length+1
        })
    }
  },
  components: {
    ShortTextField, SelectField
  },
  mounted(){
    this.getOrder()
    this.$parent.$emit('on-bread', 'NewPage', this.$route.params.project_id, this.$route.params.page_id, this.$route.params.block_id)
  }
}
</script>