<template>
    <div v-if="page">
        <div class="project_header">
            <div class="blockname_wrapper">
                <p>Настройки {{page.body.name}}</p>
            </div>
            
            <div class="new_project_btn" @click="publish" v-if="!loading">Опубликовать</div>
            <div class="new_project_btn" @click="publish" v-if="loading">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.00016 13.6668C3.32683 13.6668 0.333496 10.6735 0.333496 7.00016C0.333496 5.7535 0.680163 4.54016 1.3335 3.48683C1.52683 3.1735 1.94016 3.08016 2.2535 3.2735C2.56683 3.46683 2.66016 3.88016 2.46683 4.19349C1.94683 5.03349 1.66683 6.00683 1.66683 7.00016C1.66683 9.94016 4.06016 12.3335 7.00016 12.3335C9.94016 12.3335 12.3335 9.94016 12.3335 7.00016C12.3335 4.06016 9.94016 1.66683 7.00016 1.66683C6.6335 1.66683 6.3335 1.36683 6.3335 1.00016C6.3335 0.633496 6.6335 0.333496 7.00016 0.333496C10.6735 0.333496 13.6668 3.32683 13.6668 7.00016C13.6668 10.6735 10.6735 13.6668 7.00016 13.6668Z" fill="black"/>
                </svg>


                Загрузка</div>
        </div>
        <div class="content">
            <ShortTextField placeholder="Slug" label="Slug" name="slug" :value="page.body.slug" @on-change="page.body.slug=$event"></ShortTextField>
            <p>Ссылки</p>
            <LinksField :value="page.body.links" @on-change="page.body.links=$event"></LinksField>
        </div>
    </div>
</template>
<style scoped>  
.phones {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.delete {
    border-radius: 5px;
    border: 1px solid #DEDEDE;
    padding: 12px;
    justify-self: center;
    cursor: pointer;
    margin-bottom: 20px;
    margin-left: 20px;
}
.addone {
    display: flex;
    font-size: 20px;
    margin-left: auto;
    cursor: pointer;
}
.addone > svg {
    margin-right: 8px;
}
    .project_header {
        height: 70px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background-color: white;
        margin-bottom: 20px;
        border-radius: 10px;
        align-items: center;
    }
    .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
        min-height: calc(100vh - 97px - 108px);
        border-radius: 10px;
        padding: 30px 20px 28px 20px;
    }
    .content > p {
        font-size:  32px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .blockname_wrapper {
        display: flex;
        align-items: center;
    }

    .blockname_wrapper > div {
        font-size: 16px;
        color: #8A8A8A;
        padding: 10px 8px;
        border: 1px solid #8A8A8A;
        border-radius: 5px;
        margin-right: 20px;
    }
    .project_header   p {
        font-size: 32px;
    }
    .blockname_wrapper > p {
        margin-right: 20px;
    }
    .blockname_wrapper > svg {
        cursor: pointer;
    }
    .new_project_btn {
        padding: 12px 20px;
        border: 1px solid black;
        border-radius: 80px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .new_project_btn svg {
        margin-right: 10px;
    }
    .new_project_btn:hover {
        background-color: #F2F2F2 !important;
        color: #4C4C4C !important;
        border: 1px solid #4C4C4C;
    }
    .new_project_btn:active {
        background-color: #E5E5E5 !important;
        color: #1A1A1A !important;
        border: 1px solid #1A1A1A;
    }
    .content {
        display: flex;
        flex-wrap: wrap; 
    }
</style>
<script>

import LinksField from '../fields/LinksField.vue';
import ShortTextField from '../fields/ShortTextField.vue'

export default {
  name: 'PageSettings',
  data: () => ({
    page: undefined,
    loading: false
  }),
  methods: {
    getSettings: function(){
        fetch(`${process.env.VUE_APP_MYAPI}/pages/${this.$route.params.sales_id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+this.$cookies.get('token')
            }
        })
        .then(response=>{return response.json()})
        .then(data=>{this.page = data;})
    },
    publish: function(){
        if(!this.$cookies.get('token')){
            this.$router.push({name:'LoginForm'})
        }
        if(this.loading){
            return false
        }
        this.loading = true
        fetch(`${process.env.VUE_APP_MYAPI}/pages/${this.$route.params.sales_id}`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer '+this.$cookies.get('token'),
            },
            body: JSON.stringify({
                "body":this.page.body
            })
        })
        .then(response=>{
            if(!response.ok){
                this.$toast.clear();
                this.$toast.error("Изменения не опубликованы")
                console.log(response)
            } 
            else {
                this.$toast.clear();
                this.$toast.success("Изменения успешно опубликованы")
                this.$router.push({name:'PageBlocksSales', params: {sales_id:this.$route.params.sales_id}})
            }
            this.loading = false
            return response.json()
        })
    }
  },
  mounted () {
    this.getSettings()
    this.$parent.$emit('on-bread', 'PageSettingsSales', undefined, this.$route.params.sales_id, undefined)
  },
  components: {
    ShortTextField,
    LinksField
}
}
</script>