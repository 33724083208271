<template>
<div class="wrapperDate" v-if="date1">
    <p>{{label}}</p> 
    <div class="inputwrapper">
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" @click="(e)=>{e.preventDefault(); e.stopPropagation(); toggleOpen()}">
<path d="M19.507 1.81264C19.194 1.4999 18.778 1.32494 18.3357 1.31994C18.3293 1.31986 18.3228 1.31982 18.3164 1.31982C17.8811 1.31982 17.4688 1.48502 17.1534 1.78643L7.76855 10.7601C8.77309 11.0162 9.63117 11.6608 10.1921 12.6037C10.3631 12.8911 10.4991 13.1953 10.6005 13.5092L19.5337 4.1665C19.8345 3.85178 20.0001 3.43877 20.0001 3.00357C20.0001 2.55408 19.8252 2.13131 19.507 1.81264Z" fill="black"/>
<path d="M6.89797 11.8103C6.85074 11.8083 6.80352 11.8074 6.75633 11.8074C5.96441 11.8074 5.17895 12.0828 4.5382 12.5437C3.76586 13.0994 3.45789 13.8975 2.89078 14.6246C2.17969 15.5363 1.08574 15.9821 0 16.2861C0.632305 17.9572 2.98125 18.6414 4.52156 18.6783C4.57687 18.6796 4.63246 18.6803 4.68848 18.6803C6.43246 18.6803 8.48664 18.0321 9.32734 16.3161C10.2503 14.4324 9.11266 11.903 6.89797 11.8103Z" fill="black"/>
</svg>
    <span class="span">#</span>
    <input type="text" class="dateinput" :placeholder="placeholder" :value="date1.slice(1,date1.length)" disabled>
    </div>
    <div class="date options" @click="$event.preventDefault(); $event.stopPropagation(); $emit('on-change', date1)">
        <v-app>
            <v-color-picker
  dot-size="25"
  swatches-max-height="200"
  mode='hexa'
  v-model="date1"
></v-color-picker>
        </v-app>
        
    </div>
   
</div>
  
</template>
<script>

  export default {
    data () {
      return {
        date1: null,
      }
    },
    methods: {
        toggleOpen: function(){
            let date = document.querySelector('.date')
            date.style.display = window.getComputedStyle(date).display
            if(date.style.display=='none') date.style.display = 'block'
            else date.style.display = 'none'
        },
    },
    props: {
        label: {
            type: String || undefined,
        },
        value: {
            type: String || undefined,
        },
        placeholder: {
            type: String || undefined,
        }
    },
    mounted(){
        if(this.value){
            this.date1=this.value
        }
        else{
            this.date1='#000000FF'
        }
    }
  }
</script>
<style>
.date.options {
    z-index: 20;
}
.date {
    position: absolute;
    top: 50px;
    left: 53%;
    display: none;
}
.inputwrapper {
    width: 50%;
    display: flex;
    align-items: center;
}
.inputwrapper svg {
    cursor: pointer;
    margin-right: 10px;
}
.dateinput {
    border: 1px solid #DEDEDE;
    padding: 12px 14px;
    background-color: #F7F7F7;
    width: 100%;
}
.span {
    border: 1px solid #DEDEDE;
    text-align: center;
    cursor: default;
    padding: 12px 14px;
    border-radius: 5px 0px 0px 5px;
}
.dateinput:focus {
    background-color: white;
}
.dateinput::placeholder {
    font-size: 16px;
    color: #8A8A8A;
}
p {
    margin-right: 20px;
    font-size: 20px;
}
    .v-picker__title.primary {
        color:white !important;
        border-bottom: 1px solid #DEDEDE;
        background-color: black !important;
    }
    .v-picker {
        border: 1px solid #DEDEDE;
    }
    .v-btn--active {
        color: white !important;
        opacity: 1 !important;
        background-color: black;
    }
    .wrapperDate {
        margin-bottom: 20px;
    }
    .wrapperDate {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-top: 20px;
    }
</style>