import { render, staticRenderFns } from "./SalesPages.vue?vue&type=template&id=0bb215e0&scoped=true"
import script from "./SalesPages.vue?vue&type=script&lang=js"
export * from "./SalesPages.vue?vue&type=script&lang=js"
import style0 from "./SalesPages.vue?vue&type=style&index=0&id=0bb215e0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bb215e0",
  null
  
)

export default component.exports