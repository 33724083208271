<template>
    <div v-if="project">
        <div class="project_header">
            <div class="blockname_wrapper">
                <p>Настройки {{project.body.name}}</p>
            </div>
            
            <div class="new_project_btn" @click="publish" v-if="!loading">Опубликовать</div>
            <div class="new_project_btn" @click="publish" v-if="loading">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.00016 13.6668C3.32683 13.6668 0.333496 10.6735 0.333496 7.00016C0.333496 5.7535 0.680163 4.54016 1.3335 3.48683C1.52683 3.1735 1.94016 3.08016 2.2535 3.2735C2.56683 3.46683 2.66016 3.88016 2.46683 4.19349C1.94683 5.03349 1.66683 6.00683 1.66683 7.00016C1.66683 9.94016 4.06016 12.3335 7.00016 12.3335C9.94016 12.3335 12.3335 9.94016 12.3335 7.00016C12.3335 4.06016 9.94016 1.66683 7.00016 1.66683C6.6335 1.66683 6.3335 1.36683 6.3335 1.00016C6.3335 0.633496 6.6335 0.333496 7.00016 0.333496C10.6735 0.333496 13.6668 3.32683 13.6668 7.00016C13.6668 10.6735 10.6735 13.6668 7.00016 13.6668Z" fill="black"/>
                </svg>


                Загрузка</div>
        </div>
        <div class="content">
            <p>CRM данные</p>
            <CRMField placeholder="CRM ID" label="CRM ID" name="crm_id" :value="project.body.crm_id" @on-change="project.body.crm_id=$event" :body="project.body" @on-change-crm="project.body=$event"></CRMField>
             <ShortTextField placeholder="Название" label="Название" name="name" :value="project.body.name" @on-change="project.body.name=$event" :active="false"></ShortTextField>
             <ShortTextField placeholder="Описание" label="Описание" name="description" :value="project.body.description" @on-change="project.body.description=$event" :active="false"></ShortTextField>
            <p>Ручные данные</p>
            <ShortTextField placeholder="Дата сдачи" label="Дата сдачи" name="deadline" :value="project.body.deadline" @on-change="project.body.deadline=$event" :active="true"></ShortTextField>
            <ShortTextField placeholder="Адрес" label="Адрес" name="address" :value="project.body.address" @on-change="project.body.address=$event"></ShortTextField>
            <SingleImageField :label="'Логотип'" :cards="project.body.image" @on-change="project.body.image=$event"></SingleImageField>
            <SingleImageField :label="'Логотип (миниатюра)'" :cards="project.body.small_logo" @on-change="project.body.small_logo=$event"></SingleImageField>
            <ShortTextField placeholder="Ширина логотипа(%)" label="Ширина логотипа(%)" name="widthlogo" :value="project.body.image.size" @on-change="project.body.image.size=$event"></ShortTextField>
            <SingleImageField :label="'Рендер'" :cards="project.body.background" @on-change="project.body.background=$event"></SingleImageField>
            <SingleImageField :label="'Мобильный рендер'" :cards="project.body.background_mobile" @on-change="project.body.background_mobile=$event"></SingleImageField>
            
             <ShortTextField placeholder="Статус" label="Статус" name="status" :value="project.body.status" @on-change="project.body.status=$event"></ShortTextField>
             <ShortTextField placeholder="Slug" label="Slug" name="slug" :value="project.body.slug" @on-change="project.body.slug=$event"></ShortTextField>
             <SingleMapField :label="'Блок с картой'" :cards="project.body.latlng" @on-change="project.body.latlng=$event"></SingleMapField>
             <ColorField label="Цвет" placeholder="Цвет" :value="project.body.color" @on-change="project.body.color=$event"></ColorField>
             <p>Метатеги</p>
             <div class="metas">
                <ShortTextField placeholder="Название" label="Название" name="title" :value="project.body.metatags.title" @on-change="project.body.metatags.title=$event"></ShortTextField>
                <ShortTextField placeholder="Описание" label="Описание" name="desc" :value="project.body.metatags.description" @on-change="project.body.metatags.description=$event"></ShortTextField>
                <ShortTextField placeholder="Ключевые слова" label="Ключевые слова" name="keywords" :value="project.body.metatags.keywords" @on-change="project.body.metatags.keywords=$event"></ShortTextField>
                <TitleAndDescField :label="'Стандартные'" :cards="project.body.metatags.defaults" @on-change="project.body.metatags.defaults=$event"></TitleAndDescField>
                <TitleAndDescField :label="'OG'" :cards="project.body.metatags.og" @on-change="project.body.metatags.og=$event"></TitleAndDescField>
            </div>
        </div>
    </div>
</template>
<style scoped>  
.phones {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.delete {
    border-radius: 5px;
    border: 1px solid #DEDEDE;
    padding: 12px;
    justify-self: center;
    cursor: pointer;
    margin-bottom: 20px;
    margin-left: 20px;
}
.addone {
    display: flex;
    font-size: 20px;
    margin-left: auto;
    cursor: pointer;
}
.addone > svg {
    margin-right: 8px;
}
    .project_header {
        height: 70px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background-color: white;
        margin-bottom: 20px;
        border-radius: 10px;
        align-items: center;
    }
    .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
        min-height: calc(100vh - 97px - 108px);
        border-radius: 10px;
        padding: 30px 20px 28px 20px;
    }
    .content > p {
        font-size:  32px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .blockname_wrapper {
        display: flex;
        align-items: center;
    }

    .blockname_wrapper > div {
        font-size: 16px;
        color: #8A8A8A;
        padding: 10px 8px;
        border: 1px solid #8A8A8A;
        border-radius: 5px;
        margin-right: 20px;
    }
    .project_header   p {
        font-size: 32px;
    }
    .blockname_wrapper > p {
        margin-right: 20px;
    }
    .blockname_wrapper > svg {
        cursor: pointer;
    }
    .new_project_btn {
        padding: 12px 20px;
        border: 1px solid black;
        border-radius: 80px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .new_project_btn svg {
        margin-right: 10px;
    }
    .new_project_btn:hover {
        background-color: #F2F2F2 !important;
        color: #4C4C4C !important;
        border: 1px solid #4C4C4C;
    }
    .new_project_btn:active {
        background-color: #E5E5E5 !important;
        color: #1A1A1A !important;
        border: 1px solid #1A1A1A;
    }
    .content {
        display: flex;
        flex-wrap: wrap; 
    }
</style>
<script>

import ShortTextField from '../fields/ShortTextField.vue'
import SingleImageField from '../fields/SingleImageField.vue';
import SingleMapField from '../fields/SingleMapField.vue';
import TitleAndDescField from '../fields/TitleAndDescField.vue';
import ColorField from '../fields/ColorField.vue';
import CRMField from '../fields/CRMField.vue';


export default {
  name: 'SiteSettings',
  data: () => ({
    project: undefined,
    loading: false
  }),
  methods: {
    getSettings: function(){
        fetch(`${process.env.VUE_APP_MYAPI}/projects/${this.$route.params.project_id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+this.$cookies.get('token')
            }
        })
        .then(response=>{return response.json()})
        .then(data=>{
            this.project = data;
        })
    },
    publish: function(){
        if(!this.$cookies.get('token')){
            this.$router.push({name:'LoginForm'})
        }
        if(this.loading){
            return false
        }
        this.loading = true
        fetch(`${process.env.VUE_APP_MYAPI}/projects/${this.$route.params.project_id}`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer '+this.$cookies.get('token'),
            },
            body: JSON.stringify({
                "body":this.project.body
            })
        })
        .then(response=>{
            if(!response.ok) {
                console.log(response)
                this.$toast.clear();
                this.$toast.error("Изменения не опубликованы")
            }
            else {
                this.$toast.clear();
                this.$toast.success("Изменения успешно опубликованы")
                this.$router.push({name:'ProjectPages', params:{project_id:this.$route.params.project_id}})
            }
            this.loading = false
            return response.json()
        })
    }
  },
  mounted () {
    this.getSettings()
    this.$parent.$emit('on-bread', 'ProjectSettings', this.$route.params.project_id, this.$route.params.page_id, this.$route.params.block_id)
  },
  components: {
    ShortTextField,
    SingleImageField,
    SingleMapField,
    TitleAndDescField,
    ColorField,
    CRMField,
}
}
</script>