<template>
    <div>
        <div class="project_header">
            <p>Проекты</p>
           <div><router-link :to="{name: 'NewProject'}"><div class="new_project_btn">Новый проект</div></router-link></div>
        </div>
        <div class="content">
            <div class="project_card" v-for="project in projects" :key="project.id">
                <router-link :to="{name: 'ProjectPages', params: {project_id: project.id}}">
                    <div class="project_card_content">
                        <p>{{project.body.name}}</p>
                        <p><router-link :to="{name: 'ProjectSettings', params: {project_id: project.id.toString()}}"  class="project_settings">Настройки</router-link></p>
                    </div>
                </router-link>
                
            </div>
        </div>
    </div>
</template>
<style scoped>
    .project_header {
        height: 70px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background-color: white;
        margin-bottom: 20px;
        border-radius: 10px;
        align-items: center;
    }
    .project_header  > p {
        font-size: 32px;
    }
    .new_project_btn {
        padding: 12px 20px;
        border: 1px solid black;
        border-radius: 80px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .new_project_btn svg {
        margin-right: 10px;
    }
    .new_project_btn:hover {
        background-color: #F2F2F2 !important;
        color: #4C4C4C !important;
        border: 1px solid #4C4C4C;
    }
    .new_project_btn:active {
        background-color: #E5E5E5 !important;
        color: #1A1A1A !important;
        border: 1px solid #1A1A1A;
    }
    .content {
        display: flex;
        flex-wrap: wrap; 
    }
    .project_card {
        
        width: 50%;
        height: 252px;
        padding-right: 10px;
        padding-bottom: 20px;
        cursor: pointer;
    }
     .project_card:nth-child(2n){
        padding-right: 0;
        padding-left: 10px;
     }
    .project_card_content {
        background-color: white;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        padding: 24px 20px 40px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .project_card_content > p:first-child {
        font-size: 24px;
    }
    .project_card_content > p:last-child {
        font-size: 16px;
        line-height: 1.5em;
        width: fit-content
    }
    .project_card_content > p:last-child:hover {
        text-decoration: underline;
    }
</style>
<script>
export default {
  name: 'ProjectList',
  data: () => ({
    projects: undefined,
    myhost: process.env.VUE_APP_MYHOST
  }),
  methods: {
    getProjects: function(){
        fetch(`${process.env.VUE_APP_MYAPI}/projects?_sort=id:ASC`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+this.$cookies.get('token')
            }
        })
        .then(response=>{return response.json()})
        .then(data=>{this.projects = data})
    }
  },
  mounted () {
    this.getProjects()
    this.$parent.$emit('on-bread', 'ProjectList', this.$route.params.project_id, this.$route.params.page_id, this.$route.params.block_id)
  }
}
</script>