/* eslint-disable */
<template>
    <div class="inputblock">
        <label :for="name">{{label}}</label>
        <input type="text" :name="name" :placeholder="placeholder" class="inputshort input" :value="value" @input="change" @focus="focus=true" @blur="focus=false" :disabled="!active">
        <div class="typo" @click="typo">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.99999 12H8.53843V11.1724C8.53843 11.0582 8.43512 10.9655 8.30765 10.9655H7.61537C7.23302 10.9655 6.92305 10.6876 6.92305 10.3448V1.44827C6.92305 1.33402 7.02637 1.24137 7.15383 1.24137H9.92306C10.8125 1.24137 11.1533 2.06276 11.2629 2.34455C11.295 2.42749 11.3823 2.48302 11.4803 2.48276H11.7692C11.8967 2.48276 12 2.39013 12 2.27585V0.206906C12 0.0926484 11.8967 0 11.7692 0H0.23078C0.103312 0 0 0.092625 0 0.206906V2.27587C0 2.39013 0.103312 2.48278 0.23078 2.48278H0.519699C0.617652 2.48304 0.704966 2.42752 0.737094 2.34457C0.846707 2.06278 1.18754 1.24139 2.07694 1.24139H4.84617C4.97361 1.24139 5.07695 1.33402 5.07695 1.4483V10.3448C5.07695 10.6876 4.76698 10.9655 4.38463 10.9655H3.69232C3.56488 10.9655 3.46154 11.0582 3.46154 11.1724V12H5.99999Z" fill="#1A1A1A"/>
            </svg>
        </div>
    </div>
</template>
<style scoped>
    .typo {
        position:absolute;
        right: 8px;
        z-index: 1;
        cursor: pointer;
        background-color: white;
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #DEDEDE;
        border-radius: 4px;
        width: 24px;
        height: 24px;
    }
    .typo:hover {
        background-color: #F2F2F2;
        border: 1px solid #1A1A1A;
        border-radius: 4px;
    }
    .typo:active {
        background: #E5E5E5;
        border: 1px solid #1A1A1A;
        border-radius: 4px;
    }
    .inputshort {
        border: 1px solid #DEDEDE;
        padding: 12px 14px;
        width: 50%;
        border-radius: 4px;
    }
    .inputshort::after {
        content:'a';
        width:100px;
    }
    .inputshort:disabled {
        background-color: #F7F7F7;
    }
    .inputshort::placeholder {
        color: #8A8A8A;
        font-size: 16px;
    }
    .inputblock {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        width: 100%;
        position: relative;
    }
    .inputblock > label {
        margin-right: 20px;
        font-size: 20px;
    }
</style>
<script>

export default { 
  model: {
    prop: "value",
    event: "on-change"
  },
  data(){
        return{
            text: this.value,
            focus: false
        }
    },
    methods: {
        change: function(event){
            this.text = event.target.value
            this.$emit("on-change", event.target.value)
        },
        typo: function(){
            let text = this.text
            if (!String.r) {
                String.prototype.r = String.prototype.replace
            }
            var r = function (text, regex) {
            return new RegExp(text, regex)
            };
            text = text.r(/([\d]) ?(€|£|л\.|[скм]?м[/^¹²³.\s]|г[аг]?[\s.]|ц\.|т[\s.]|р\.|руб\.|уе|тыс\.|млн|млрд)/g, "$1 $2").r(/([\d]) г\.[  ]?г\./g, "$1 гг.").r(/([IVXLCDM]) ?(вв?\.)/g, "$1 $2").r(/([IVXLCDM]) в\.[  ]?в\./g, "$1 вв.");
            //text = text.r(/([^\d]|^)([\d]+(?:[.,][\d]+)?) ?\$/g, "$1$$$2").r(/(,|;|:|!|\?|\))([^\d\s=?!:,.'’‘‚"«»“”[])/gi, "$1 $2").r(/(\[color=[^\n]*\])( | )( | )( | )( | )/g, "$2" + "$3" + "$4" + "$5" + "$1").r(/ {2,}/g, " ").r(/\.([^\s\dA-Za-z\n=?:;,.'’‘"»„“”[]+)/gi, ". $1").r(/([А-яёЁ])\.([^\sA-Za-z\n=?:;,.'’‘"»„“”[]+)/g, "$1. $2").r(/ (\.|,|!|\?|;|:)/g, "$1").r(/(—|–|-)\.{2,4} /g, "$1 ...").r(/(\n|^|&)( *)\.{2,4} /g, "$1" + "$2...").r(/(['‘‚"«„“(])\.{2,4} /g, "$1...").r(/(\s|^)\.{2,4}[  ]/g, "$1...").r(/\( /g, "(").r(/ \)/g, ")").r(/([^\s])\(/g, "$1 (").r(/([^\d])(,|\.)([\d])/g, "$1" + "$2 $3").r(/(!|\?|\))([^\s=?!:,.'’‘‚"«»“”[]+)/gi, "$1 $2").r(/ %/g, "%").r(/P\. ?P\. ?S\./g, "P. P. S.").r(/P\. ?S\./g, "P. S.").r(/и др\./g, "и др.").r(/([\s]|^)(гл?|ул|д|илл)\. ([A-Za-zА-яёЁ0-9])/g, "$1" + "$2. $3").r(/(\s|^)([тнсюзв])\. ?([еочнпдшдэ])\./g, "$1" + "$2. $3.").r(/(\s|^)т\.? ?([нпдч])\./g, "$1т. $2.").r(/ н\. э\./g, " н. э").r(/([№§]) ?([\dIVXLCDM])/g, "$1 $2").r(/(\d)([lpd]pi)([\s,.!?]|$)/g, "$1 $2" + "$3").r(/ГОСТ /gi, "ГОСТ ").r(/ГОСТ Р /gi, "ГОСТ Р ").r(/([\s]|^)до[  ]нэ\./g, "$1до н. э.");
            text = text.r(/ {2,}/g, " ");

            // Простановка неразрывного пробела после предлогов, союзов
            for (var p = 0; p < 2; ++p) {
                text = text.r(/([  \n\t\v]|^)([иаксуов]|же|до|из|н[аое]|по|о[тб]|за|как|что|над|под|пр[ои]|или|для|без|если|едва|л?ибо|зато|пока|дабы|ежели|когда|перед|чтобы|через|пусть|будто|однако|словно) ([А-яёЁ])/gi, "$1" + "$2&nbsp;$3");
            }
            text = text.r(/\(c\)/g, "©").r(/\(r\)/g, "®").r(/\(tm\)/g, "™");
            // Расстановка дефисов
            var mst = "(где|зачем|как|какая|каки[емх]|како[ейм]|какого|какому|кем|когда|кого|кому?|кто|куда|откуда|почему|чего|чему?|что|ч[её]м)";
            text = text.r(r("([^А-яёЁ]|^)" + mst + "(?:[  ]?|-[  ]|[  ]-)(то|либо|нибудь)([^А-яёЁ]|$)", "gi"), "$1" + "$2-$3" + "$4").r(r("([^А-яёЁ]|^)" + mst + "(?:[  ]?|-[  ]|[  ]-)(то|либо|нибудь)([^А-яёЁ]|$)", "gi"), "$1" + "$2-$3" + "$4").r(r("([^А-яёЁ]|^)(кое|кой)(?:[  ]?[-—]?[  ]?)" + mst + "([^А-яёЁ]|$)", "gi"), "$1" + "$2-$3" + "$4").r(r("([^А-яёЁ]|^)(ко[ей])(?:[  ]?[-—]?[  ]?)" + mst + "([^А-яёЁ]|$)", "gi"), "$1" + "$2-$3" + "$4").r(/([\s]|^)(из)(?:[  ]?[-—]?[  ]?)(за)([\s]|$)/gi, "$1" + "$2-$3" + "$4").r(/([\s]|^)(из)(?:[  ]?[-—]?[  ]?)(под)([\s]|$)/gi, "$1" + "$2-$3" + "$4").r(/([А-яёЁ]{2,}) (ка|кась)([\s,.?!]|$)/g, "$1-$2" + "$3").r(/([^А-яёЁ]|^)(вс[ёе]|так)(?:[  ]?|-[  ]|[  ]-)(таки)([^А-яёЁ]|$)/gi, "$1" + "$2-$3" + "$4").r(/(ГОСТ(?:[  ]Р)?(?:[  ](?:ИСО|ISO))?)[  ]([\d.]+)-([\d]+)/gi, "$1 $2–$3");

            // Расстановка тире в датах
            text = text.r(/([IVXLCDM]{1,3})-([IVXLCDM]{1,3})[  ]?вв?\.?([\s.,?!;)])/g, "$1—$2 вв.$3").r(/([\d]{1,4})-([\d]{1,4})[  ]?гг?.([\s.,?!;)])/g, "$1–$2 гг.$3").r(/([^\d]|^)([0-2][0-9]:[0-5][0-9])-([0-2][0-9]:[0-5][0-9])([^\d]|$)/g, "$1" + "$2–$3" + "$4")/*.r(/(\s|^)([IVXLСDМ]+)-{1,2}([IVXLСDМ]+)(\s|$)/g,"$1"+"$2—$3"+"$4")*/;
            var mo = "(?:[ьяюе]|[её]м)";
            var to = "(?:[ауе]|ом)";
            var month = "(январ" + mo + "|феврал" + mo + "|март" + to + "|апрел" + mo + "|ма(?:[йяюе]|ем)|ию[нл]" + mo + "август" + to + "|сентябр" + mo + "|ноябр" + mo + "|октябр" + mo + "|декабр" + mo + ")";
            text = text.r(r("([\\s]|^)([1-3]?[\\d])-([1-3]?[\\d])[  ]?" + month + "([^А-яёЁ]|$)", "gi"), "$1" + "$2–$3 $4" + "$5").r(r("([^А-яёЁ]|^)" + month + "-" + month + "([^А-яёЁ]|&)", "gi"), "$1" + "$2—$3" + "$4");

            text = text.r(/(\d)--(\d)/g, '$1–$2').r(/([^-]|\s|^)--([^-]|$|\n)/g, '$1—$2').r(/([^-\d]|^)(\d+)-(\d+)([^-\d]|$)/g, "$1" + "$2−$3" + "$4").r(/([^a-z][a-z]|[Α-Ωα-ω+=*/])-(\d)/g, "$1−$2")/*.r(/([A-Za-z\s]|^)-(\d+)([^-\d]|$)/g,"$1"+sym[20]+"$2"+"$3").r(/([^-\d]|^)(\d+)-([A-Za-z])/g,"$1"+"$2"+sym[20]+"$3")*/;
            
            text = text.r(/([^.])\.{2,4}/g, "$1...").r(/(\?!|!\?)\.{3}/g, "?!.").r(/\?\.{3}/g, "?..").r(/!\.{3}/g, "!..").r(/(!+)(\?+)/g, "$2" + "$1").r(/(\d+?)[xх](\d+?)/g, "$1×$2").r(/(\d+?)([  ])[xх]([  ])(\d+?)/g, "$1×$4").r(/([0-9a-zA-ZΑ-Ωα-ωА-яёЁ])\^([0-9]+)/g, function ($0, $1, $2) {
            var l = "0123456789", g = "⁰¹²³⁴⁵⁶⁷⁸⁹";
            var re = $2;
            for (var j = 0; j < 10; ++j) {
                re = re.r(r(l.charAt(j), "g"), g.charAt(j));
            }
            return $1 + re
            }).r(/!=/g, "≠").r(/\+\/[-−]/g, "±").r(/~=/g, "≈").r(/<=/g, "≤").r(/>=/g, "≥").r(/<->/g, "↔").r(/<-([^-]|&)/g, "←$1").r(/([^-]|^)->/g, "$1→").r(/(!+)(\?+)/g, "$2" + "$1").r(/\?{3,}/g, "???").r(/!{3,}/g, "!!!");
            // Дроби
            var t1 = "([^0-9A-Za-zА-яёЁ/]|^)", t2 = "([^0-9A-Za-zА-яёЁ/]|$)";
            text = text.r(r(t1 + "1/2" + t2, "g"), "$1½$2").r(r(t1 + "1/4" + t2, "g"), "$1¼$2").r(r(t1 + "2/4" + t2, "g"), "$1½$2").r(r(t1 + "3/4" + t2, "g"), "$1¾$2").r(r(t1 + "1/3" + t2, "g"), "$1⅓$2").r(r(t1 + "2/3" + t2, "g"), "$1⅔$2").r(r(t1 + "1/5" + t2, "g"), "$1⅕$2").r(r(t1 + "2/5" + t2, "g"), "$1⅖$2").r(r(t1 + "3/5" + t2, "g"), "$1⅗$2").r(r(t1 + "4/5" + t2, "g"), "$1⅘$2").r(r(t1 + "1/6" + t2, "g"), "$1⅙$2").r(r(t1 + "2/6" + t2, "g"), "$1⅓$2").r(r(t1 + "3/6" + t2, "g"), "$1½$2").r(r(t1 + "4/6" + t2, "g"), "$1⅔$2").r(r(t1 + "5/6" + t2, "g"), "$1⅚$2").r(r(t1 + "1/8" + t2, "g"), "$1⅛$2").r(r(t1 + "2/8" + t2, "g"), "$1¼$2").r(r(t1 + "3/8" + t2, "g"), "$1⅜$2").r(r(t1 + "4/8" + t2, "g"), "$1½$2").r(r(t1 + "5/8" + t2, "g"), "$1⅝$2").r(r(t1 + "6/8" + t2, "g"), "$1¾$2").r(r(t1 + "7/8" + t2, "g"), "$1⅞$2").r(/,+/g, ",").r(/:+/g, ":").r(/;+/g, ";").r(/([a-zA-ZА-яёЁ0-9]) (а|но)([\s$,]|\.\.\.)/g, "$1, $2" + "$3").r(/([a-zA-ZА-яёЁ0-9]) однако([\s$,.!?:])/g, "$1, однако" + "$2");
            text = text.r(/([^a-zА-яёЁ]|^)([a-zА-яёЁ]+)[  ](\2)([^a-zA-ZА-яёЁ]|$)/gi, "$1" + "$2" + "$4")


            text = text.r(/(^|\n|\s|—|-|\()"/g, "$1«").r(/"($|\n|\s|—|-|\.|,|!|\?|:|;|\))/g, "»$1").r(/«\)/g, "»)").r(/«( ?)/g, "«").r(/( ?)»/g, "»").r(/>"/g, ">«").r(/"</g, "»<").r(/«""/g, "«««").r(/«"/g, "««").r(/""»/g, "»»»").r(/"»/g, "»»").r(/("{2}|"»)/g, "»»").r(/$"/g, "«").r(/([A-Za-zа-яА-ЯёЁ])'/g, "$1’");
            text = text.r(/[a-zA-ZА-яёЁ]"-/g, "$1»-").r(/-"[a-zA-ZА-яёЁ]/g, "-«$1");
            text = text.r(/(^[^«»]*)"/g, "$1«").r(/"([^«»]*$)/g, "»$1").r(/«([^«»]*)"/g, "«$1»").r(/"([^«»]*)»/g, "«$1»");
            function rl(i, j) {
                var b = "", c, d = "";
                if (i != 0) {
                    b = text.substring(0, i);
                }
                if (j != text.length - 1) {
                    d = text.substring(j + 1, text.length);
                }
                c = text.substring(i, j + 1);
                for (var p = 0; p < 32; ++p) {
                    c = c.r(/«([^«»]*)«([^»]*)»/g, "«$1„$2“");
                        c = c.r(/„([^„“]*)„([^“]*)“/g, "„$1‚$2‘");
                }
                return b + c + d;
            }
            var level = 0;
            for (var i = 0; i < text.length; ++i) {
                if (text.charAt(i) == '«') {
                    ++level;
                    for (var j = i + 1; j < text.length; ++j) {
                        if (text.charAt(j) == '«') {
                            ++level;
                        }
                        if (text.charAt(j) == '»') {
                            --level;
                            if (level <= 0) {
                                text = rl(i, j);
                                i = j;
                                break;
                            }
                        }
                    }
                    level = 0;
                }
            }
            text = text.r(/(\s|^|<p>)([«"„‚]*)(-|–)([\s]|$)/g, "$1" + "$2—$4");
            text = text.r(/([A-Za-zА-яёЁ0-9]) —/g, "$1 —");
            text = text.r(/([.,!?] |\n|^|<p>)— ([A-Za-zА-яёЁ0-9«"„‚])/g, "$1— $2");
            
            text = text.r(/([0-9]+) ((?:[А-яёЁa-zA-Z]+)+)/g, "$1&nbsp;$2");
            text = text.r(/\n/g, "<br />");
            this.$el.querySelector('input').value = text
            this.text = text
            this.$emit("on-change", this.text)
       }
    },
    props: {
        name: {
            type: String || undefined,
        },
        placeholder: {
            type: String || undefined,
        },
        label: {
            type: String || undefined,
        },
        value: {
            type: String || undefined,
        },
        active: {
            type: Boolean || undefined,
            default: true
        }
    },
    mounted(){
        let a = this
        function doc_keyUp(e) {

    // this would test for whichever key is 40 (down arrow) and the ctrl key at the same time
            if (e.key === 'Backspace' && e.shiftKey) {
                e.preventDefault();
                e.stopPropagation();
                if(a.focus){
                    a.typo()
                }
                
            }
        }
        document.addEventListener('keydown', doc_keyUp, false);
        this.$el.querySelector('.typo').addEventListener('mousedown',(e)=>{
            e.preventDefault()
        })
    }

}
</script>