<template>
    <div v-if="page">
        <div class="project_header">
            <p>Новый блок</p>
            <div class="new_project_btn" @click="newBlock">Создать блок</div>
        </div>
        <div class="content">
            <ShortTextField placeholder="Название блока" label="Название блока" name="title" :value="title" @on-change="title=$event"></ShortTextField>
            <p class="naming">Тип блока</p>
            <div class="block_sel_wrapper">
                <div class="block_sel" v-for="(option, index) in options" :key="option" @click="type=option; activeStyle(index)">
                    <div class="img_sel_wrapper">
                        <img :src="imgs[index]">
                    </div>
                <div class="p_sel_wrapper">
                    <p>{{option}}</p>
                </div>
                
            </div>
            </div>
            
        </div>
        <div class="content" v-if="page.type=='progress'">
            <DateField label="Дата" :value="title" @on-change="title=$event" placeholder="ДД.ММ.ГГГГ"></DateField>
        </div>
        
        <div class="content" v-if="page.type=='project_features' || page.type=='feature'">
            <ShortTextField placeholder="Название блока" label="Название блока" name="title" :value="title" @on-change="title=$event"></ShortTextField>
            <SelectField placeholder="Тип блока" label="Тип блока" name="type" :selected="feature_type" :options="feature_options" @on-change="feature_type=$event"></SelectField>
        </div>
    </div>
</template>
<style scoped>
    .naming {
        font-size: 20px;
        margin-bottom: 20px;
    }
    .p_sel_wrapper {
        border-top: 1px solid #DEDEDE;
        padding: 16px;
        font-size: 16px;
        height: 72px;
    }
    .block_sel .img_sel_wrapper {
        width: 100%;
        height: 194px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .block_sel {
        width: 266px;
        background: #FFFFFF;
        border: 1px solid #DEDEDE;
        border-radius: 4px;
        cursor: pointer;
    }
    .block_sel:hover, .block_sel.active {
        border: 1px solid #4C4C4C;
    }
    .block_sel:hover .p_sel_wrapper, .block_sel.active .p_sel_wrapper {
        border-top: 1px solid #4C4C4C;
    }
    .block_sel_wrapper {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

    }
    .project_header {
        height: 70px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background-color: white;
        margin-bottom: 20px;
        border-radius: 10px;
        align-items: center;
    }
    .project_header  > p {
        font-size: 32px;
    }
    .new_project_btn {
        padding: 12px 20px;
        border: 1px solid black;
        border-radius: 80px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .new_project_btn svg {
        margin-right: 10px;
    }
    .new_project_btn:hover {
        background-color: #F2F2F2 !important;
        color: #4C4C4C !important;
        border: 1px solid #4C4C4C;
    }
    .new_project_btn:active {
        background-color: #E5E5E5 !important;
        color: #1A1A1A !important;
        border: 1px solid #1A1A1A;
    }
    .content {
        display: flex;
        flex-direction: column;
        background-color: white;
        min-height: calc(100vh - 97px - 108px);
        border-radius: 10px;
        padding: 30px 20px 28px 20px;
    }

</style>
<script>
import ShortTextField from '../fields/ShortTextField.vue'
import SelectField from '../fields/SelectField.vue'
import DateField from '../fields/DateField.vue'
export default {
  name: 'NewBlockSales',
  data: () => ({
    title: "",
    options: ['concept', 'slider', 'architecture', 'carousel', 'benefits', 'person', 'video','interactive','numbers', 'title','button', 'map', 'text', 'singlemap','cards'],
    type: 'concept',
    order: 0,
    page: null,
    feature_options: ['carousel', 'text', 'singlemap', 'payments', 'feature', 'cards', 'button'],
    feature_type: 'carousel',
    imgs: []
  }),
  methods: {
    activeStyle: function(index){
        let blocks = this.$el.querySelectorAll('.block_sel')
        for(let i=0;i<blocks.length;i++){
            blocks[i].classList.remove('active')
        }
        blocks[index].classList.add('active')
    },
    newBlock: function(){
        if(!this.$cookies.get('token')){
            this.$router.push({name:'LoginForm'})
        }
        let type
        if(this.page.type=='project_features' || this.page.type=='feature') {
            type = this.feature_type
        }
        else {
            type = this.type
        }
        const bodyByTitle = {
            'concept': JSON.stringify({
                "body": {
                    "name": this.title.trim() !== "" ? this.title.trim() : "Блок concept",
                    "label": "",
                    "description": "",
                    "items": [],
                    "image": {
                        "url": "",
                        "alt":""
                    }
                },
                "type": type,
                "order": this.order
            }),
            'slider': JSON.stringify({
                "body": {
                    "name": this.title.trim() !== "" ? this.title.trim() : "Блок slider",
                    "label": "",
                    "slides": []
                },
                "type": type,
                "order": this.order
            }),
            'architecture': JSON.stringify({
                "body": {
                    "name": this.title.trim() !== "" ? this.title.trim() : "Блок architecture",
                    "label": "",
                    "description": "",
                    "images": []
                },
                "type": type,
                "order": this.order
            }),
            'carousel': JSON.stringify({
                "body": {
                    "name": this.title.trim() !== "" ? this.title.trim() : "Блок carousel",
                    "label": "",
                    "slides": []
                },
                "type": type,
                "order": this.order
            }),
            'benefits': JSON.stringify({
                "body": {
                    "name": this.title.trim() !== "" ? this.title.trim() : "Блок benefits",
                    "label": "",
                    "benefits": [],
                    "isReversed": false
                },
                "type": type,
                "order": this.order,
            }),
            'person': JSON.stringify({
                "body": {
                    "name": this.title.trim() !== "" ? this.title.trim() : "Блок person",
                    "description": "",
                    "image": {},
                    "position": "",
                    "personName": "",
                    "sign": {},
                    "label": "",
                },
                "type": type,
                "order": this.order
            }),
            'video': JSON.stringify({
                "body": {
                    "name": this.title.trim() !== "" ? this.title.trim() : "Блок video",
                    "video": {},
                    "label": "",
                },
                "type": type,
                "order": this.order
            }),
            'interactive': JSON.stringify({
                "body": {
                    "name": this.title.trim() !== "" ? this.title.trim() : "Блок interactive",
                    "items": [],
                    "label": "",
                },
                "type": type,
                "order": this.order
            }),
            'numbers': JSON.stringify({
                "body": {
                    "name": this.title.trim() !== "" ? this.title.trim() : "Блок numbers",
                    "description": "",
                    "items": [],    
                    "label": "",
                    "image": {
                        "url": "",
                        "alt":""
                    }
                },
                "type": type,
                "order": this.order
            }),
            'title': JSON.stringify({
                "body": {
                    "name": this.title.trim() !== "" ? this.title.trim() : "Блок title",
                    "title": "",  
                    "label": "",
                },
                "type": type,
                "order": this.order
            }),
            'cards': JSON.stringify({
                "body": {
                    "name": this.title.trim() !== "" ? this.title.trim() : "Блок cards",
                    "title": "",  
                    "label": "",
                },
                "type": type,
                "order": this.order
            }),
            'button': JSON.stringify({
                "body": {
                    "name": this.title.trim() !== "" ? this.title.trim() : "Блок button",
                    "text": "",
                    "type": "link",
                    "url": "",
                    "title": "",
                    "label": "",
                },
                "type": type,
                "order": this.order
            }),
            'map': JSON.stringify({
                "body": {
                    "name": this.title.trim() !== "" ? this.title.trim() : "Блок map",
                    "label": "",
                    "scale": "1",
                    "points": [],
                },
                "type": type,
                "order": this.order
            }),
            'singlemap': JSON.stringify({
                "body": {
                    "name": this.title.trim() !== "" ? this.title.trim() : "Блок singlemap",
                    "label": "",
                    "scale": "1",
                    "points": [],
                },
                "type": type,
                "order": this.order
            }),
            'text': JSON.stringify({
                "body": {
                    "name": this.title.trim() !== "" ? this.title.trim() : "Блок text",
                    "description": "",  
                    "title": "",
                    "label": "",
                },
                "type": type,
                "order": this.order
            }),
            'event': JSON.stringify({
                "body": {
                    "month": this.title,
                    "description": "",
                    "images": []
                },
                "type": type,
                "order": this.order
            }),
            'payments': JSON.stringify({
                "body": {
                    "name": this.title.trim() !== "" ? this.title.trim() : "Блок payments",
                    "label": "",
                    "button": {
                        "text": "",
                        "type": "",
                        "url": ""
                    },
                    "items": []
                },
                "type": type,
                "order": this.order
            }),
            'feature': JSON.stringify({
                "body": {
                    "name": this.title.trim() !== "" ? this.title.trim() : "Блок feature",
                    "label": "",
                    "image": {
                        "url": "",
                        "alt": ""
                    },
                    "text": "",
                    "button": {
                        "text": "",
                        "type": "",
                        "url": ""
                    },
                },
                "type": type,
                "order": this.order
            }),
        }
        
        /*if(this.title.trim()==""){
            this.$toast.clear();
            this.$toast.error("Заполните Боковой заголовок")
            return
        }*/ 
        
        fetch(`${process.env.VUE_APP_MYAPI}/blocks`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer '+this.$cookies.get('token'),
            'Content-Type': 'application/json'
        },
        body: bodyByTitle[type]
        })
        .then(response=>{
            if(!response.ok){
                console.log(response.json())
            } 
            return response.json()
        })
        .then(data=>{
            let new_block = data
            fetch(`${process.env.VUE_APP_MYAPI}/pages/${this.$route.params.sales_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer '+this.$cookies.get('token'),
                }
            })
            .then(response=>{
                if(!response.ok){
                    console.log(response.json())
                } 
                return response.json()
            })
            .then(data=>{
                let current_blocks = data.blocks
                current_blocks.push(new_block)
                fetch(`${process.env.VUE_APP_MYAPI}/pages/${this.$route.params.sales_id}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': 'Bearer '+this.$cookies.get('token'),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "blocks": current_blocks,
                        "active": true
                    })
                })
                .then(response=>{
                    if(!response.ok){
                        this.$toast.clear();
                        this.$toast.error("Блок не создан")
                        console.log(response.json())
                    } 
                    else {
                        this.$toast.clear();
                        this.$toast.success("Блок успешно создан")
                        this.$router.push({name:'BlockEditSales', params: {project_id: this.$route.params.project_id, sales_id: this.$route.params.sales_id, block_id: new_block.id}})
                    }
                    return response.json()
                })
            })      
        })
    },
    getOrder: function(){
        fetch(`${process.env.VUE_APP_MYAPI}/pages/${this.$route.params.sales_id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+this.$cookies.get('token'),
            }
        })
        .then(response=>{
            if(!response.ok){
                console.log(response.json())
            }
            return response.json()
        })
        .then(data=>{
            this.page = data
            this.order = data.blocks.length+1
            this.options = this.feature_options
            this.type = this.feature_type
            for(let i=0;i<this.options.length;i++){
                this.imgs.push(require(`../../assets/svg/${this.options[i]}.svg`))
            }
        })
    }
  },
  components: {
    ShortTextField,
    SelectField,
    DateField
},
  async mounted(){
    
    await this.getOrder()
    
    this.$parent.$emit('on-bread', 'NewBlockSales', this.$route.params.project_id, this.$route.params.sales_id, this.$route.params.block_id)
  }
}
</script>
