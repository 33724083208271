<template>

<div class="path" v-if="path">
  <div class="back" v-if="path.length>1">
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 4.5C11.2761 4.5 11.5 4.27614 11.5 4C11.5 3.72386 11.2761 3.5 11 3.5V4.5ZM0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM11 3.5L1 3.5V4.5L11 4.5V3.5Z" fill="black"/>
</svg>

    <p @click="push(path[path.length-2].url)">Назад</p>
  </div>
  <div class="spans">
    <div class="spans_el"  v-for="res in path" :key="res.url">
      <span class="main_span" @click="push(res.url)">
        {{res.title}}
      </span>
      <span>
        /
      </span>
    </div>
    
  </div>
</div>

</template>
<style scoped>
.spans {
  display: flex;
  position: absolute;
  left: 250px;
}
.spans_el {
  display: flex;
}
    .path {
        background-color: white;
        height: 68px;
        border-radius: 0px 0px 10px 10px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        padding: 27px 13px;
    }
    span {
      font-size: 14px;
      color: #505050;
    }
    span{
      margin-right: 0.5rem;
      text-align: center;
      display: block;
    }
    .main_span {
            cursor: pointer;

    }
    .main_span:hover {
      text-decoration: underline;
    }
    .back {
      margin-right: 167px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .back > svg {
      margin-right: 5px;
    }
</style>
<script>
export default {
  name: 'HeaderAAG',
    data: ()=>({
        res: []
    }),
    props: {
      path: {
            type: Array || undefined,
        },
        params: {
          type: Array || undefined,
        }
    },
    methods: {
      push: function(url){
        this.$router.push({name: url, params: {
          project_id: this.params[0],
          page_id: this.params[1],
          block_id: this.params[2]
        }})
      }
    }
}
</script>