<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
  <p>Вход</p>
    <input type="text" class="login_text" @input="login=$event.target.value" :value="login" placeholder="Логин">
    <input type="password" class="login_text" @input="pass=$event.target.value" :value="pass" placeholder="Пароль">

    <div class="login_btn" @click="validate()">Войти</div>
  </v-form>
</template>

<script>
  export default {
    data: () => ({
      valid: true,
      login: '',
      loginRules: [
        v => !!v || 'Обязательное поле',
      ],
      pass: '',
      passRules: [
        v => !!v || 'Обязательное поле',
      ],
    }),

    methods: {
      validate () {
        if(this.$refs.form.validate()){
            fetch(`${process.env.VUE_APP_MYAPI}/auth/local/`, {
                method: 'POST',
                 headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    identifier: this.login,
                    password: this.pass
                })
            })
            .then(response=>{
              if(!response.ok){
                this.$toast.clear();
                this.$toast.error("Неверные данные")
              }
              else {
                this.$toast.clear();
                this.$toast.success("Успешный вход. Переадрессация...")
              }
                return response.json()
            })
            .then(data=>{
                if(data.jwt){
                    this.$cookies.set('token', data.jwt)
                    window.location.href = process.env.VUE_APP_MYHOST
                }
            })
        } 
      },
    },
    beforeCreate () {
        if (this.$cookies.get('token')) {
          window.location.href = process.env.VUE_APP_MYHOST
        }
    }
  }
</script>
<style scoped>
p {
    margin-bottom: 0.5em;
    font-size: 32px;
}
.login_btn {
    margin-top: 1em;
    width: 100%;
    background-color: black !important;
    color: white !important;
    font-size: 16px !important;
    text-transform: none !important;
    padding-top: 15.5px !important;
    padding-bottom: 15.5px !important;
    box-shadow: none;
    height: unset !important;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;
}
.login_btn:hover {
  background-color: #4C4C4C !important;
}
.login_text {
  border: 1px solid #DEDEDE;
  width: 100%;
  margin-bottom: 10px;
  padding: 12px 14px;
  border-radius: 4px;
}
form {
  width: 400px;
  background-color: white;
  margin: 160px auto;
  padding: 40px 28px;
}
</style>