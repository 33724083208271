<template>
    <div class="rightNav">
        <slot @on-change="(type, newObj)=>{$emit('on-change', type, newObj)}"></slot>
    </div>
</template>
<style>
    .rightNav {
        flex: 1;
    }
   
</style>
<script>
export default {
  name: 'RightNav',
}

</script>