<template>
<div class="wrapperDate" v-if="date1">
    <p>{{label}}</p> 
    <div class="inputwrapper">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" @click="(e)=>{e.preventDefault(); e.stopPropagation(); toggleOpen()}">
<path d="M17.5 2.5H16.6667V0.833282C16.6667 0.373383 16.2933 0 15.8333 0H15C14.5399 0 14.1667 0.373383 14.1667 0.833282V2.5H5.83328V0.833282C5.83328 0.373383 5.46005 0 5 0H4.16672C3.70667 0 3.33328 0.373383 3.33328 0.833282V2.5H2.5C1.12167 2.5 0 3.62167 0 5V17.5C0 18.8783 1.12167 20 2.5 20H17.5C18.8783 20 20 18.8783 20 17.5V5C20 3.62167 18.8783 2.5 17.5 2.5ZM18.3333 17.5C18.3333 17.9591 17.9591 18.3333 17.5 18.3333H2.5C2.04086 18.3333 1.66672 17.9591 1.66672 17.5V8.3667H18.3333V17.5Z" fill="black"/>
</svg>

    <input type="text" class="dateinput" :placeholder="placeholder" :value="change()" disabled>
    </div>
    <div class="date options">
         <v-date-picker
      v-model="date1"
      show-adjacent-months
      :show-current="value"
      color="green lighten-1"
      header-color="primary"
      @input ="change"
    ></v-date-picker>
    </div>
   
</div>
  
</template>
<script>
  export default {
    data () {
      return {
        date1: null,
      }
    },
    methods: {
        toggleOpen: function(){
            let date = document.querySelector('.date')
            date.style.display = window.getComputedStyle(date).display
            if(date.style.display=='none') date.style.display = 'block'
            else date.style.display = 'none'
        },
        change: function(){
            let tmp = this.date1
            let arr = tmp.split('-')
            let res = arr[2]+"."+arr[1]+"."+arr[0]
            this.$emit("on-change", res)
            return res
        },
        getValue: function(){
            if(this.value){
                let tmp = this.value
                let arr = tmp.split(".")
                let res = arr[2]+"-"+arr[1]+"-"+arr[0]
                this.date1 = res
            }
            else {
                this.date1 = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            }
        }
    },
    props: {
        label: {
            type: String || undefined,
        },
        value: {
            type: String || undefined,
        },
        placeholder: {
            type: String || undefined,
        }
    },
    mounted(){
        this.getValue()
    }
  }
</script>
<style>
.date {
    position: absolute;
    top: 50px;
    left: 53%;
    display: none;
}
.inputwrapper {
    width: 50%;
    display: flex;
    align-items: center;
}
.inputwrapper svg {
    cursor: pointer;
    margin-right: 10px
}
.dateinput {
    border: 1px solid #DEDEDE;
    padding: 12px 14px;
    background-color: #F7F7F7;
    width: 100%;
}
.dateinput:focus {
    background-color: white;
}
.dateinput::placeholder {
    font-size: 16px;
    color: #8A8A8A;
}
p {
    margin-right: 20px;
    font-size: 20px;
}
    .v-picker__title.primary {
        color:white !important;
        border-bottom: 1px solid #DEDEDE;
        background-color: black !important;
    }
    .v-picker {
        border: 1px solid #DEDEDE;
    }
    .v-btn--active {
        color: white !important;
        opacity: 1 !important;
        background-color: black;
    }
    .wrapperDate {
        margin-bottom: 20px;
    }
    .wrapperDate {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
    }
</style>