import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
import VueRouter from 'vue-router'
import VueHead from 'vue-head'
import LoginForm from './components/LoginForm'
import MainMenu from './components/MainMenu'
import HomeAag from './components/HomeAag'
import ProjectList from './components/default/ProjectList'
import ProjectPages from './components/default/ProjectPages'
import PageBlocks from './components/default/PageBlocks'
import BlockEdit from './components/default/BlockEdit'
import NewProject from './components/default/NewProject'
import NewPage from './components/default/NewPage'
import NewBlock from './components/default/NewBlock'
import SiteSettings from './components/default/SiteSettings'
import ProjectSettings from './components/default/ProjectSettings'
import PageSettings from './components/default/PageSettings'
import StaticPages from './components/static/StaticPages'
import PageBlocksStatic from './components/static/PageBlocksStatic'
import NewPageStatic from './components/static/NewPageStatic'
import PageSettingsStatic from './components/static/PageSettingsStatic'
import NewBlockStatic from './components/static/NewBlockStatic'
import BlockEditStatic from './components/static/BlockEditStatic'
import SalesPages from './components/sales/SalesPages'
import PageBlocksSales from './components/sales/PageBlocksSales'
import NewPageSales from './components/sales/NewPageSales'
import PageSettingsSales from './components/sales/PageSettingsSales'
import NewBlockSales from './components/sales/NewBlockSales'
import BlockEditSales from './components/sales/BlockEditSales'
import Toast from "vue-toastification";

import "vue-toastification/dist/index.css";
// Vuetify's CSS styles 
import 'vuetify/dist/vuetify.min.css'



// use Vuetify's plugin
Vue.use(Vuetify)
// use this package's plugin

Vue.config.productionTip = false
Vue.use(VueCookies, { expire: '7d'})
Vue.use(VueHead)
Vue.use(Toast, {
  position: "top-right",
  transition: "Vue-Toastification__bounce",
  maxToasts: 1,
  newestOnTop: true,
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
})
  let routes = [
    {
      path: '/login',
      name: 'LoginForm',
      components: {
        homeView: LoginForm
      },
      meta: {
        reload: true
      }
    },
    { path: '/', name: 'MainLog', components: {
      homeView: HomeAag,
    },
    children: [
      {
        path: '/',
        name: 'Main',
        components: {
          rightNav: MainMenu
        },
        meta: {
          reload: true,
        },
      },
      {
      path: '/projects',
      name: 'ProjectList',
      components: {
        rightNav: ProjectList
      },
      meta: {
        reload: true,
      },
    },
    {
      path: '/projects/new',
      name: 'NewProject',
      components: {
        rightNav: NewProject
      },
      meta: {
        reload: true,
      },
    },
      {
        path: '/projects/:project_id',
        name: 'ProjectPages',
        components: {
          rightNav: ProjectPages
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/projects/:project_id/settings',
        name: 'ProjectSettings',
        components: {
          rightNav: ProjectSettings
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/projects/:project_id/pages/new',
        name: 'NewPage',
        components: {
          rightNav: NewPage
        },
        meta: {
          reload: true,
        },
      },
      
      {
        path: '/projects/:project_id/pages/:page_id',
        name: 'PageBlocks',
        components: {
          rightNav: PageBlocks
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/projects/:project_id/pages/:page_id/settings',
        name: 'PageSettings',
        components: {
          rightNav: PageSettings
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/projects/:project_id/pages/:page_id/blocks/new',
        name: 'NewBlock',
        components: {
          rightNav: NewBlock
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/projects/:project_id/pages/:page_id/blocks/:block_id',
        name: 'BlockEdit',
        components: {
          rightNav: BlockEdit
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/settings',
        name: 'SiteSettings',
        components: {
          rightNav: SiteSettings
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/static',
        name: 'StaticPages',
        components: {
          rightNav: StaticPages
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/static/new',
        name: 'NewPageStatic',
        components: {
          rightNav: NewPageStatic
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/static/:static_id',
        name: 'PageBlocksStatic',
        components: {
          rightNav: PageBlocksStatic
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/static/:static_id/settings',
        name: 'PageSettingsStatic',
        components: {
          rightNav: PageSettingsStatic
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/static/:static_id/blocks/new',
        name: 'NewBlockStatic',
        components: {
          rightNav: NewBlockStatic
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/static/:static_id/blocks/:block_id',
        name: 'BlockEditStatic',
        components: {
          rightNav: BlockEditStatic
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/sales',
        name: 'SalesPages',
        components: {
          rightNav: SalesPages
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/sales/new',
        name: 'NewPageSales',
        components: {
          rightNav: NewPageSales
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/sales/:sales_id',
        name: 'PageBlocksSales',
        components: {
          rightNav: PageBlocksSales
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/sales/:sales_id/settings',
        name: 'PageSettingsSales',
        components: {
          rightNav: PageSettingsSales
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/sales/:sales_id/blocks/new',
        name: 'NewBlockSales',
        components: {
          rightNav: NewBlockSales
        },
        meta: {
          reload: true,
        },
      },
      {
        path: '/sales/:sales_id/blocks/:block_id',
        name: 'BlockEditSales',
        components: {
          rightNav: BlockEditSales
        },
        meta: {
          reload: true,
        },
      },
    ]
  },
  ]
const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
})
Vue.use(VueRouter)
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
