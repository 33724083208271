/* eslint-disable */
<template>
    <v-app>
    <div class="inputblock">
        
        <label :for="name">{{label}}</label>
        
            <v-switch
            v-model="switch1"
            inset
            @change="change"
            color="black"
        ></v-switch>
        
        
    </div>
</v-app>
</template>
<style scoped>
    .inputblock {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        width: 100%;
    }
    .inputblock > label {
        margin-right: 20px;
        font-size: 20px;
    }
    .v-input--selection-controls__ripple.primary--text {
    color: black imo !important;
}
</style>
<script>

export default { 
  model: {
    prop: "value",
    event: "on-change"
  },
  data(){
        return{
            switch1: true,
        }
    },
    methods: {
        change: function(){
            this.$emit("on-change", this.switch1)
        },
    },
    props: {
        name: {
            type: String || undefined,
        },
        label: {
            type: String || undefined,
        },
        value: {
            type: Boolean || undefined,
        },
    },
    mounted(){
        this.switch1 = this.value
    }

}
</script>