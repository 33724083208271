var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainWrapper"},[_c('HeaderAag',{attrs:{"path":_vm.path,"params":_vm.params}}),_c('div',{staticClass:"navWrapper"},[_c('LeftNav',{key:_vm.forceReloadLeft,attrs:{"newObj":_vm.newobj},on:{"on-change":_vm.reloadRight}}),_c('RightNav',{key:_vm.forceReloadRight,on:{"on-change":(type, newObj)=>{
        _vm.newobj = {
            'type': type,
            'data': newObj
        }
    },"on-bread":(name, project, page, block)=>{
        _vm.test(name, project, page, block)
    }}},[_c('router-view',{attrs:{"name":"rightNav"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }